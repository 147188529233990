// @flow
import type {Account, ReducedFrontendProject} from "../../../Hooks/Api/useAccountList";
import {Button} from "react-bootstrap";
import {TiShoppingCart} from "react-icons/ti";
import {Link} from "react-router-dom";
import ViewTrafficFactor from "./ViewTrafficFactor";
import './ProjectsListStyle.css';

type Props = {
  account: Account,
};

export default function AccountsListRow(props: Props) {
  const {account} = props;
  const {identifier, name, partner, market, viewTrafficFactor, projects} = account;

  return (
    <tr>
      <td>{name}</td>
      <td><code>{identifier}</code></td>
      <td><Projects projects={projects}/></td>
      <td><ViewTrafficFactor initialFactor={viewTrafficFactor} identifier={identifier}/></td>
      <td>{partner}</td>
      <td>{market}</td>
      <td>
        <Link to={`/partner-merchants/list?account=${identifier}`}>
          <Button
            size={'sm'}
            variant={'outline-secondary'}
            className={'color-purple'}
            title={'Zeige alle Merchants zu diesem Account'}
          ><TiShoppingCart/></Button>
        </Link>
      </td>
    </tr>
  );
}

type ProjectsProps = {
  projects: Array<ReducedFrontendProject>,
};

function Projects(props: ProjectsProps) {
  let projects = props.projects;

  return (
    <ul className={'accounts-projects-list'}>
      {projects.map((project) => (
        <li key={project.projectId} className={'accounts-projects-list'}>
          <span className={'project-name'}>{project.name}</span>
          <br/>
          <span className={'project-identifier'}>(<code>{project.projectId}</code>)</span>
        </li>
      ))}
    </ul>
  );
}
