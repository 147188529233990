import type {ApiResponseStatus, UseApiResponseType} from "../../Response";
import {ResponseStatus} from "../../Response";
import {startPartnerMerchantUpdateRequest} from "./startPartnerMerchantUpdateRequest";

export const handlePartnerMerchantUpdateRequest = async (
    updateMethod: () => UseApiResponseType<any>,
    apiStatus: ApiResponseStatus,
    setApiStatus: (status: UseApiResponseType) => void,
    apiKey: string,
    notificationPusher: Function,
    partnerMerchantId: number,
    data?: any
) => {
    const requestIsNotPending = apiStatus !== ResponseStatus.pending;
    if (requestIsNotPending) {
        return await startPartnerMerchantUpdateRequest(updateMethod, setApiStatus, apiKey, notificationPusher, partnerMerchantId, data)
    }
    return false;
};