// @flow
import {Col, Form, Row} from "react-bootstrap";
import {useCallback, useMemo} from "react";
import type {Account} from "../../../Hooks/Api/useAccountList";
import TimedTextInput from "../../../GenericComponents/Input/TimedTextInput";
import {AiFillEdit} from "react-icons/ai";
import Pagination from "../../../GenericComponents/Pagination";
import ViewTrafficStatusSelect from "../../Merchants/Common/ViewTrafficStatusSelect";

type Props = {
  accountMap: Map<Account>,
  defaultDomainFilter: ?string,
  defaultMarketFilter: ?string,
  defaultViewTrafficStatus: ?string,

  onDomainFilterChange: Function,
  onMarketFilterChange: Function,
  onViewTrafficStatusChange: Function,

  page: number,
  maxPages: number,
  onPageChange: Function,
};

export default function YieldAdsMerchantFilterControls(props: Props) {
  const {
    accountMap,
    defaultDomainFilter,
    defaultMarketFilter,
    defaultViewTrafficStatus,

    onDomainFilterChange,
    onMarketFilterChange,
    onViewTrafficStatusChange,

    page,
    maxPages,
    onPageChange,
  } = props;

  const onMarketChange = useCallback((event) => {
    const newValue = event.target.value;

    onMarketFilterChange(newValue);
  }, [onMarketFilterChange]);

  const marketOptions = useMemo(() => {
    const options = new Map();
    options.set('any', <option key={'any'} value={'any'}>Alle Märkte</option>);

    for (const account of accountMap.values()) {
      const {market} = account;

      if (!options.has(market)) {
        options.set(market, <option key={market} value={market}>{market.toUpperCase()}</option>);
      }
    }

    return Array.from(options.values(), (value) => value);
  }, [accountMap]);

  return (
    <Row className={'mb-3'}>
      {/* Wenn die Controls umbrechen sorgt der Margin für Abstand */}
      <Col md={'auto'} className={'mb-2'}>
        <TimedTextInput
          name={'domain'}
          size={'sm'}
          placeholder={'Suchen'}
          defaultValue={defaultDomainFilter}
          onChange={onDomainFilterChange}
          focusOnLoad={true}
        />
      </Col>
      <Col md={"auto"} className={'mb-2'}>
        <Form.Select
          name={'market'}
          size={'sm'}
          value={defaultMarketFilter || 'any'}
          onChange={onMarketChange}
        >
          {marketOptions}
        </Form.Select>
      </Col>
      <ViewTrafficStatusSelect value={defaultViewTrafficStatus} onChange={onViewTrafficStatusChange}/>
      <Col>
        <Pagination page={page} maxPage={maxPages} onChange={onPageChange}/>
      </Col>
      <Col>
        {/*<em>*/}
        {/*  Zellen von markierten Spalten (<AiFillEdit/>) können per Doppelklick bearbeitet werden*/}
        {/*</em>*/}
        <em>
          (<AiFillEdit/> = Bearbeiten durch Doppelklick)
        </em>
      </Col>
    </Row>
  );
}
