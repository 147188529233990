import {AppEnv} from "../../Context/AppEnvContext";

export default function generateMonthList(appEnv: AppEnv) {
    const currentDate  = new Date();
    const currentYear  = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay   = currentDate.getDate();

    let year  = currentYear;
    let month = currentMonth;

    // Payment ist erst ab dem 3. Tag des Monats möglich, da der Cron, welcher die ADV Commissions als "paid" markiert, am 2. läuft.
    if (currentDay < 3) {
        month = currentMonth - 1;

        if (month === 0) {
            year--;
            month = 12;
        }
    }

    let startDate = new Date('2023-11-01');

    if (appEnv.value === AppEnv.testing) {
        startDate = new Date('2022-11-01');
    }

    const endDate = new Date(`${year}-${month}-${currentDay}`);

    const monthList = [];

    while (startDate <= endDate) {
        const year     = startDate.getFullYear();
        const month    = startDate.toLocaleString('en-EN', { month: 'long' });
        const firstDay = new Date(year, startDate.getMonth(), 1);
        const lastDay  = new Date(year, startDate.getMonth() + 1, 0);

        const name = `${month} ${year}`;

        // name und value weil react-select-search das braucht
        monthList.push({
            name,
            value: name,
            from: formatDate(firstDay),
            to: formatDate(lastDay),
        });

        startDate = new Date(year, startDate.getMonth() + 1, 1);
    }

    return monthList.reverse();
}

function formatDate(date) {
    const year  = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day   = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}
