// @flow
import {Col, Row} from "react-bootstrap";
import TimedTextInput from "../../../GenericComponents/Input/TimedTextInput";
import Pagination from "../../../GenericComponents/Pagination";
import UploadCsvButton from "./UploadCsvButton";

type Props = {
  defaultDomainFilter: ?string,

  refresh: Function,
  onDomainFilterChange: Function,

  page: number,
  maxPages: number,
  onPageChange: Function,
};

export default function YieldAdsMerchantFingerprintWhitelistFilterControl(props: Props) {
  const {
    defaultDomainFilter,
    refresh,
    onDomainFilterChange,
    page,
    maxPages,
    onPageChange,
  } = props;

  return (
    <Row className={'mb-3'}>
      {/* Wenn die Controls umbrechen sorgt der Margin für Abstand */}
      <Col md={'auto'} className={'mb-2'}>
        <TimedTextInput
          name={'domain'}
          size={'sm'}
          placeholder={'Suchen'}
          defaultValue={defaultDomainFilter}
          onChange={onDomainFilterChange}
          focusOnLoad={true}
        />
      </Col>
      <Col md={"auto"} className={'mb-2'}>
      </Col>
      <Col md={"auto"} className={'mb-2'}>
        <UploadCsvButton
          refresh={refresh}
        />
      </Col>
      <Col md={"auto"} className={'mb-2'}>
        <Pagination page={page} maxPage={maxPages} onChange={onPageChange}/>
      </Col>
    </Row>
  );
}
