// @flow

import type {Publisher} from "./Publisher";
import {TiArrowRightThick} from "react-icons/ti";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {usePageNotificationSystem} from "../../Context/PageNotification";
import NotificationToast from "../../GenericComponents/NotificationToast";
import AllowedAccountsOverviewButton from "./AllowedAccountsOverviewButton";

type Props = {
  publisher: Publisher,
};

const apiKeyStyle = {
  maxWidth: "20vw", // Damit der API-Key nicht den wertvollen Platz wegnimmt
  minWidth: "20vw",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export default function PublisherRow(props: Props) {
  const {publisher} = props;
  const {permissions, partnerPriority} = publisher;

  const {pushNotification} = usePageNotificationSystem();

  const copyApiKey = async () => {
    await navigator.clipboard.writeText(publisher.apiKey);
    pushNotification(<NotificationToast
      id={'api-key-to-clipboard'}
      title={'Clipboard Copy'}
      variant={'success'}
      duration={3750}
    >
      <>Successfully copied Api-Key for publisher <b>{publisher.name}</b> to clipboard</>
    </NotificationToast>);
  };

  return (
    <tr>
      <td><code>{publisher.id}</code></td>
      <td><Link to={`/publisher/${publisher.id}/details`}>{publisher.name}</Link></td>
      <td style={apiKeyStyle}>
        <code className={'cursor-pointer'} title={'Click to copy'} onClick={copyApiKey}>
          {publisher.apiKey}
        </code>
      </td>
      <td>
        <ul className={"mb-0"}>
          {permissions.map((permission: string) => (<li key={permission}>{permission}</li>))}
        </ul>
      </td>
      <td>
        <ul className={"mb-0"}>
          {Object.entries(partnerPriority).map(([prio, partner]) => (<li key={prio}>{prio} -> {partner}</li>))}
        </ul>
      </td>
      <td>
        <AllowedAccountsOverviewButton publisher={publisher}/>
      </td>
      {/* Der redirectHost kann null sein. Wenn das der Fall ist, dann nimmt die API einen Default-Wert */}
      <td title={"Zum Ändern an Team Core wenden"}>
        {publisher.redirectApiHost !== null
          ? <code>{publisher.redirectApiHost}</code>
          : <span title={"Es ist kein Wert angegeben, die API nutzt einen Default-Wert"}
                  className={'cursor-help'}>
            <i>default</i> (<code>api.yieldads.net</code>)
          </span>
        }
      </td>
      <td>
        <Link to={`/merchant-blacklist/list?publisher_id=${publisher.id}`}>
          <Button
            size={'sm'}
            variant={'outline-secondary'}
            className={'color-purple'}
            title={'Zeige alle blockierten Merchants zu diesem Publisher'}
          >
            <TiArrowRightThick/>
          </Button>
        </Link>
      </td>
    </tr>
  );
}
