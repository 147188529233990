// @flow
import type {UseApiResponseType} from "../../../Helper/Response";
import type {Project, ProjectList} from "../../../Hooks/Api/useProjectList";
import {ResponseStatus} from "../../../Helper/Response";
import {Card, Col, Row, Spinner} from "react-bootstrap";
import {TiShoppingCart} from "react-icons/ti";
import {Link} from "react-router-dom";
import './AccountsCardListStyle.css';

type Props = {
  projectList: UseApiResponseType<ProjectList>,
};

export default function ProjectsListCards(props: Props) {
  const {projectList} = props;

  switch (projectList.status) {
    case ResponseStatus.pending:
      const pendingCards = [0, 1, 2, 3];

      return (
        <Row>
          {pendingCards.map((index) => (
            <Col key={index} style={{minWidth: '30em'}} className={'mb-4'}>
              <Card>
                <Card.Header>
                  <Card.Title className={'mb-0'}>
                    <Row>
                      <Col>▒░░░░░░░&nbsp;▒░░░░░&nbsp;–&nbsp;▒▒</Col>
                    </Row>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Spinner size={'sm'} animation={'grow'}/>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      );
    case ResponseStatus.ready:
      if (projectList.response.statusCode !== 200) {
        return null;
      }

      const urlStyle = {overflowX: 'scroll', whiteSpace: 'nowrap', maxWidth: '32em'};
      return (
        <Row>
          {projectList.response.parsedBody.map((project: Project) => {
            const {projectId, name, redirectUrl, hasSearchEngine, accounts} = project;

            return (
              <Col key={projectId} md={'auto'} className={'mb-4'}>
                <Card>
                  <Card.Header>
                    <Card.Title className={'mb-0'}>
                      <Row>
                        <Col>{name}</Col>
                      </Row>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    Project&nbsp;ID: <code>{projectId}</code>
                    <br/>
                    Accounts:
                    <ul className={'projects-accounts-card-list'}>
                      {accounts.map((account) => (
                        <li key={account.identifier}>
                          <span className={'account-name'}>{account.name}</span>{' '}
                          <span className={'account-identifier'}>(<code>{account.identifier}</code>)</span>
                          <Link to={`/partner-merchants/list?account=${account.identifier}`}>
                            <TiShoppingCart className={'color-purple'} title={'Zeige alle Merchants zu diesem Account'}/>
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <div className={'scroll-thin'} style={urlStyle}>
                      <code>{redirectUrl}</code>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )
          })}
        </Row>
      );
    case ResponseStatus.error:
      return null;

    default:
      return null;
  }
}
