// @flow
import type {YieldAdsMerchant} from "../../../Hooks/Api/useYieldAdsMerchantList";
import {dateStringToLocale} from "../../../Helper/DateHelper";
import AddToWhitelistButton from "./AddToWhitelistButton";
import FlagBadge from "../../../GenericComponents/FlagBadge";
import DeleteFromWhitelistButton from "./DeleteFromWhitelistButton";

type Props = {
  merchant: YieldAdsMerchant,
  stripeClass: string,
  refresh: () => void,
};

export default function YieldAdsMerchantFingerprintWhitelistRow(props: Props) {
  const {merchant, stripeClass, refresh} = props;
  const {
    yieldadsMerchantId,
    market,
    domain,
    whitelistStatus,
    whitelistCreatedDate,
  } = merchant;

  return (
    <tr className={stripeClass}>
      <td>{domain}</td>
      <td>{market}</td>
      <td>
        <FlagBadge
          value={whitelistStatus}
          textTrue={'Whitelisted'}
          textFalse={'Disallowed'}
        />
      </td>
      <td>{whitelistCreatedDate !== null ? dateStringToLocale(whitelistCreatedDate) : null}</td>
      <td>
        <AddToWhitelistButton
          yieldAdsMerchantId={yieldadsMerchantId}
          whitelistStatus={whitelistStatus}
          refresh={refresh}
        />
        <DeleteFromWhitelistButton
          yieldAdsMerchantId={yieldadsMerchantId}
          whitelistStatus={whitelistStatus}
          domain={domain}
          refresh={refresh}
        />
      </td>
    </tr>
  );
}
