export const partnerAbbreviationMapping = {
  ydk: 'Yieldkit',
  adv: 'Advancedstore',
  tad: 'Takeads',
  ift: 'InfinityTraffic',
  adn: 'Adnamics',
  cmp: 'Compado',
  wbm: 'Webme',
};

export function partnerAbbreviationToFullName(abbreviation: string): string {
  const lower = abbreviation.toLowerCase();
  return partnerAbbreviationMapping[lower] || abbreviation;
}
