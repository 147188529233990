// @flow

import {apiHost} from "./ApiData";
import {sleep} from "../Debug";
import NotificationToast from "../../GenericComponents/NotificationToast";
import ApiErrorsList from "../../GenericComponents/ApiErrorsList";
import {httpCodeToName} from "../StatusCode";
import {Env} from "../Env";
import {AppEnv} from "../../Context/AppEnvContext";

export type ApiResponse = {
  parsedBody: any,
  statusCode: number,
}

export type ApiErrors = {
  [string]: string,
};

// Rückgabewert wird als Message im Error-Toast genutzt
export type OnApiErrorCallback = (reason: string, parsedBody: ApiErrors, statusCode: ?number) => ?React.ReactNode;

function handleApiError(id: string, reason: string, parsedBody: ApiErrors, statusCode: ?number, pushNotification: ?Function, onError: ?OnApiErrorCallback = null) {
  let message;

  try {
    message = onError && onError(reason, parsedBody, statusCode);
  } catch (error) {
    message = `Fehler beim erstellen der Nachricht: ${error.message}`
  }

  pushNotification && pushNotification(
    <NotificationToast
      id={id}
      title={'API Fehler'}
      variant={'danger'}
    >
      <>
        <div className={'mb-1'}>
          {message}
        </div>
        <div>
          {reason}
        </div>
        <hr className={'my-1'}/>
        {statusCode !== null &&
          <div className={'mb-1'}>Status-Code: {statusCode} <small>({httpCodeToName(statusCode)})</small></div>
        }
        <ApiErrorsList responseBody={parsedBody}/>
      </>
    </NotificationToast>
  );
}

export async function fetchApi(
  method: string,
  route: string,
  apiKey: string,
  abortController: ?AbortController = null,
  body: ?Object = null,
  query: ?URLSearchParams = null,
  pushNotification: ?Function = null,
  onError: ?OnApiErrorCallback = null,
  onSuccess: Function = null,
): ApiResponse {
  try {
    const headers = {
      'API-Key': apiKey,
    };

    if (method.toUpperCase() !== 'GET') {
      headers['Content-Type'] = 'application/json';
    }

    const options = {
      headers: new Headers(headers),
      method: method,
      body: method.toUpperCase() === 'GET' ? undefined : JSON.stringify(body),
    };

    if (abortController !== null) {
      options.signal = abortController.signal;
    }

    if (route.charAt(0) === '/') {
      route = route.substring(1);
    }

    const response = await fetch(`${apiHost}/${route}${query ? `?${query.toString()}` : ''}`, options);

    if (Env.AppEnv === AppEnv.testing) {
      await sleep(1500);
    }

    const result = {
      // 203 No Content hat keinen Content
      parsedBody: response.status === 204 ? null : await response.json(),
      statusCode: response.status,
    };

    if (response.status < 200 || response.status >= 300) {
      const statusCodeName = httpCodeToName(response.status);
      const statusCodeNameShow = statusCodeName === null ? '' : ` (${statusCodeName})`;

      handleApiError(
        `api-error-${route}?${query ? query.toString() : ''}`,
        `Unexpected Status Code ${response.status}${statusCodeNameShow}`,
        result.parsedBody || {},
        response.status,
        pushNotification,
        onError,
      );
      return result;
    }

    if (onSuccess !== null) {
      onSuccess();
    }

    return result;
  } catch (error) {
    if (abortController?.signal.aborted) {
      throw error;
    }

    handleApiError(
      `api-error-${route}?${query ? query.toString() : ''}`,
      `Could not fetch API: ${error.message}`,
      {},
      null,
      pushNotification,
      onError,
    );

    // Übergeordnete Stellen haben ihr eigenes Error-Handling
    throw error;
  }
}

export async function fetchApiGet(
  route: string,
  apiKey: string,
  query: ?URLSearchParams = null,
  abortController: ?AbortController = null,
  pushNotification: ?Function,
  onError: ?OnApiErrorCallback = null,
  onSuccess: ?Function = null,
): ApiResponse {
  return fetchApi(
    'GET',
    route,
    apiKey,
    abortController,
    null,
    query,
    pushNotification,
    onError,
    onSuccess,
  );
}

export async function fetchApiDelete(
  route: string,
  apiKey: string,
  body: Object,
  query: ?URLSearchParams = null,
  abortController: ?AbortController = null,
  pushNotification,
  onError: Function,
  onSuccess: Function,
): ApiResponse {
  return fetchApi(
    'DELETE',
    route,
    apiKey,
    abortController,
    body,
    query,
    pushNotification,
    onError,
    onSuccess,
  );
}

export async function fetchApiPost(
  route: string,
  apiKey: string,
  body: Object,
  query: ?URLSearchParams = null,
  abortController: ?AbortController = null,
  pushNotification: ?Function = null,
  onError: ?OnApiErrorCallback = null,
  onSuccess: ?Function = null
): ApiResponse {
  return fetchApi(
    'POST',
    route,
    apiKey,
    abortController,
    body,
    query,
    pushNotification,
    onError,
    onSuccess,
  );
}

export async function fetchApiPatch(
  route: string,
  apiKey: string,
  body: Object,
  query: ?URLSearchParams = null,
  abortController: ?AbortController = null,
  pushNotification: ?Function = null,
  onError: ?OnApiErrorCallback = null,
  onSuccess: ?Function = null,
): ApiResponse {
  return fetchApi(
    'PATCH',
    route,
    apiKey,
    abortController,
    body,
    query,
    pushNotification,
    onError,
    onSuccess,
  );
}

export async function fetchApiWithBody(): ApiResponse {
  console.error('Deprecated, use %cfetchApi{Get,Post,Patch}', 'font-family: monospace; font-size: larger');
  throw Error('Deprecated');
}
