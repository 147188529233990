// @flow
import * as React from "react";
import {PageNotificationContext} from "../PageNotification";
import type {PageNotification, PageNotificationContextType, PageNotifications} from "../PageNotification";
import {useCallback, useMemo, useState} from "react";
import {ToastContainer} from "react-bootstrap";

type Props = {
  children: React.Node,
};

type NotificationsProps = {
  notifications: PageNotifications,
}

function Notifications(props: NotificationsProps) {
  const {notifications} = props;

  return (
    // TODO: Scrollen klappt nicht, obwohl die ScrollBar da ist
    <ToastContainer position={'bottom-end'} className={'p-3'} /*style={{maxHeight: '100vh', overflowY: 'auto'}}*/>
      {Object.values(notifications).map((notification: PageNotification) => (
        <React.Fragment key={notification.id}>
          {notification.notification}
        </React.Fragment>
      ))}
    </ToastContainer>
  );
}

export default function PageNotificationContextWrapper(props: Props) {
  const [notifications, setNotifications] = useState<PageNotifications>({});

  const pushNotification = useCallback((
    notification: React.Node,
  ) => {
    if (!notification.props.hasOwnProperty('id')) {
      throw new Error('Missing prop: id');
    }

    const id = notification.props.id;
    setNotifications((old) => ({
      ...old,
      [id]: {id, notification},
    }));
  }, [setNotifications]);

  const clearAll = useCallback(() => {
    setNotifications({});
  }, [setNotifications]);

  const clear = useCallback((id: string) => {
    setNotifications((old) => {
      const newNotifications = {...old};
      delete newNotifications[id];
      return newNotifications;
    });
  }, [setNotifications]);

  const contextValue: PageNotificationContextType = useMemo(() => {
    return {notifications, pushNotification, clearAll, clear};
  }, [notifications, pushNotification, clearAll, clear]);

  return (
    <PageNotificationContext.Provider value={contextValue}>
      {props.children}
      <Notifications notifications={notifications}/>
    </PageNotificationContext.Provider>
  );
}
