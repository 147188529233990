// @flow

import "./App.css";
import {BrowserRouter} from "react-router-dom";
import Layout from "./Layout";
import ThemeContextWrapper from "./Context/WrapperComponent/ThemeContextWrapper";
import TabTitleContextWrapper from "./Context/WrapperComponent/TabTitleContextWrapper";
import PageNotificationContextWrapper from "./Context/WrapperComponent/PageNotificationContextWrapper";
import AppEnvContextWrapper from "./Context/WrapperComponent/AppEnvContextWrapper";

function App() {
  return (
    <AppEnvContextWrapper>
      <ThemeContextWrapper>
        <TabTitleContextWrapper>
          <PageNotificationContextWrapper>
            <BrowserRouter>
              <Layout/>
            </BrowserRouter>
          </PageNotificationContextWrapper>
        </TabTitleContextWrapper>
      </ThemeContextWrapper>
    </AppEnvContextWrapper>
  );
}

export default App;
