// @flow
import {Card, Spinner} from "react-bootstrap";
import {ResponseStatus} from "../../../Helper/Response";
import ErrorBox from "../../../GenericComponents/ErrorBox";
import {useEffect, useMemo, useRef, useState} from "react";
import PageAlertContextWrapper from "../../../Context/WrapperComponent/PageAlertContextWrapper";
import {useAccountIdMapping} from "../../../Hooks/Api/useAccountList";
import MerchantFilterControls from "./MerchantFilterControls";
import {useTabTitle} from "../../../Context/TabTitle";
import {useSearchParams} from "react-router-dom";
import type {SortingType} from "../../../Helper/Sorting";
import LoadedMerchantsListRows from "./LoadedMerchantsListRows";
import type {ApiErrors} from "../../../Helper/Api/fetchApi";
import type {MerchantList as MerchantListType} from "../../../Hooks/Api/useMerchantList";
import IgnoredMerchantListTable from "./IgnoredMerchantListTable";
import {useIgnoredMerchantList} from "../../../Hooks/Api/useIgnoredMerchantList";

type Props = {};

const pageSize = 50;
export default function IgnoredMerchantList(props: Props) {
  useTabTitle('Ignored Partner Merchants');

  const [query, setQuery] = useSearchParams();
  const [error, setError] = useState<?string>(null);

  // Query ist die single-source-of-truth für die Filter
  const filter = useMemo(() => ({
    domain:  query.get('domain')  || null,
    market:  query.get('market')  || null,
    account: query.get('account') || null,
    viewTrafficStatus: query.get('viewTrafficStatus') || null,
  }), [query]);

  const [page, setPage] = useState<number>(1);

  const [sorting, setSorting] = useState<SortingType>(() => ({
    field: null,
    direction: 'asc',
  }: SortingType));

  // Hinweis: Beim Bearbeiten eines Merchants (Prio/Status) landen die neuen Werte nicht in der Liste hier,
  //          weswegen die beim Filtern nicht berücksichtigt werden.
  //          Wenn das ein Problem ist/wird, dann muss man die Änderungen hochreichen von der Liste hierhin und dann aktualisieren.
  // noinspection JSUnusedLocalSymbols
  const merchantList = useIgnoredMerchantList(
    page,
    pageSize,
    sorting.field,
    sorting.direction,
    filter.domain,
    filter.market,
    filter.account,
    filter.viewTrafficStatus,
    null,
    (reason: string, parsedBody: ApiErrors, statusCode: ?number) => {
      setError(reason);
    }
  );

  const lastKnownMerchantCount = useRef(0);
  useEffect(() => {
    lastKnownMerchantCount.current = 0;
  }, [filter]);

  const accountMap = useAccountIdMapping(() => 'Es gab einen Fehler beim Laden der Accounts');

  const updateFilter = (key: string, value: any) => {
    const ignoreInQuery = value === '' || value === 'any';
    ignoreInQuery ? query.delete(key) : query.set(key, value);

    setQuery(query);
    setPage(1);
  }

  // Wenn Daten da sind und kein API-Fehler
  const merchants = merchantList.status === ResponseStatus.ready && merchantList.response.statusCode === 200
    ? merchantList.response.parsedBody
    : [];
  // const filteredMerchants  = useFilteredMerchants(merchants, filter);
  // const sortedMerchants    = useSortedMerchants(filteredMerchants, sorting);
  // const paginatedMerchants = usePaginatedMerchants(sortedMerchants, page, 50);
  const paginatedMerchants: MerchantListType = merchants;

  const tableRows = useMemo(() => {
    switch (merchantList.status) {
      case ResponseStatus.pending:
        return (
          <tr>
            <td rowSpan={2} colSpan={2} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
            <td rowSpan={2} colSpan={1} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
            <td rowSpan={2} colSpan={2} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
            <td rowSpan={2} colSpan={2} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
          </tr>
        );
      case ResponseStatus.ready:
        if (merchantList.response.statusCode !== 200) {
          return null;
        }

        lastKnownMerchantCount.current = paginatedMerchants.filteredCount;
        return <LoadedMerchantsListRows
          merchantList={paginatedMerchants}
          accountMap={accountMap}
          showWhitelistStatus={false}
          showRetryEvaluationButton={false}
          isIgnoredList={true}
        />
      case ResponseStatus.error:
        return null;

      default:
        return null;
    }
  }, [merchantList, accountMap, paginatedMerchants]);

  return (
    <Card>
      <Card.Body>
        <h4 className={'mb-4'}>Ignored Partner Merchants</h4>

        {error !== null && (
          <ErrorBox title={'Error during API fetch'}>
            {error}
          </ErrorBox>
        )}

        <PageAlertContextWrapper>
          <MerchantFilterControls
            accountMap={accountMap}
            defaultDomainFilter={filter.domain}
            defaultMarketFilter={filter.market}
            defaultAccountFilter={filter.account}
            defaultViewTrafficStatus={filter.viewTrafficStatus}

            onDomainFilterChange={(newValue) => updateFilter('domain', newValue)}
            onMarketFilterChange={(newValue) => updateFilter('market', newValue)}
            onAccountFilterChange={(newValue) => updateFilter('account', newValue)}
            onViewTrafficStatusChange={(newValue) => updateFilter('viewTrafficStatus', newValue)}

            page={page}
            maxPages={Math.ceil(lastKnownMerchantCount.current / pageSize)}
            onPageChange={setPage}

            showLegend={false}
          />

          <IgnoredMerchantListTable
            sorting={sorting}
            setSorting={setSorting}
          >
            {tableRows}
          </IgnoredMerchantListTable>
        </PageAlertContextWrapper>
      </Card.Body>
    </Card>
  );
}
