// @flow
import type {YieldAdsMerchant, YieldAdsMerchantList} from "../../../Hooks/Api/useYieldAdsMerchantList";
import YieldAdsMerchantFingerprintWhitelistRow from "./YieldAdsMerchantFingerprintWhitelistRow";

type Props = {
  merchantList: YieldAdsMerchantList,
  refresh: () => void,
};

export default function LoadedYieldAdsMerchantsFingerprintWhitelistRows(props: Props) {
  const {
    merchantList,
    refresh,
  } = props;
  let rows = [];
  let index = 0;
  for (const merchant: YieldAdsMerchant of merchantList.merchants) {
    const {yieldadsMerchantId} = merchant;
    index++;

    const stripeClass = index % 2 === 0 ? 'striped-even' : 'striped-odd';

    rows.push(
      <YieldAdsMerchantFingerprintWhitelistRow
        key={yieldadsMerchantId}
        merchant={merchant}
        stripeClass={stripeClass}
        refresh={refresh}
      />
    );
  }

  return rows;
}
