// @flow
import {Card, Spinner} from "react-bootstrap";
import {ResponseStatus} from "../../../Helper/Response";
import ErrorBox from "../../../GenericComponents/ErrorBox";
import {useEffect, useMemo, useRef, useState} from "react";
import PageAlertContextWrapper from "../../../Context/WrapperComponent/PageAlertContextWrapper";
import {useTabTitle} from "../../../Context/TabTitle";
import {useSearchParams} from "react-router-dom";
import type {SortingType} from "../../../Helper/Sorting";
import type {ApiErrors} from "../../../Helper/Api/fetchApi";
import {useYieldAdsMerchantList} from "../../../Hooks/Api/useYieldAdsMerchantList";
import YieldAdsMerchantFingerprintWhitelistListTable from "./YieldAdsMerchantFingerprintWhitelistListTable";
import YieldAdsMerchantFingerprintWhitelistFilterControl from "./YieldAdsMerchantFingerprintWhitelistFilterControl";
import LoadedYieldAdsMerchantsFingerprintWhitelistRows from "./LoadedYieldAdsMerchantsFingerprintWhitelistRows";

const pageSize = 50;
export default function YieldAdsMerchantWhitelist() {
  useTabTitle('Merchants Whitelist (Yieldads)');

  const [query, setQuery] = useSearchParams();
  const [error, setError] = useState<?string>(null);

  // Query ist die single-source-of-truth für die Filter
  const filter = useMemo(() => ({
    domain: query.get('domain') || null,
  }), [query]);

  const page = useMemo(() => {
    const rawPage = query.get('page') || '1';
    const parsedPage = parseInt(rawPage);

    if (isNaN(parsedPage)) {
      return 1;
    }

    return parsedPage;
  }, [query]);

  const [sorting, setSorting] = useState<SortingType>(() => ({
    field: 'whitelistCreatedDate',
    direction: 'desc',
  }: SortingType));

  // noinspection JSUnusedLocalSymbols
  const merchantList = useYieldAdsMerchantList(
    page,
    pageSize,
    sorting.field,
    sorting.direction,
    filter.domain,
    filter.market,
    filter.viewTrafficStatus,
    (reason: string, parsedBody: ApiErrors, statusCode: ?number) => {
      setError(reason);
    }
  );

  const lastKnownMerchantCount = useRef(0);
  useEffect(() => {
    lastKnownMerchantCount.current = 0;
  }, [filter.domain]);

  const updateFilter = (key: string, value: any) => {
    const ignoreInQuery = value === '' || value === 'any';
    ignoreInQuery ? query.delete(key) : query.set(key, value);
    query.delete('page');

    setQuery(query);
  }

  const tableRows = useMemo(() => {
    // Wenn Daten da sind und kein API-Fehler
    const merchants = merchantList.status === ResponseStatus.ready && merchantList.response.statusCode === 200
      ? merchantList.response.parsedBody
      : [];

    switch (merchantList.status) {
      case ResponseStatus.pending:
        return (
          <tr className={'striped-odd'}>
            <td rowSpan={2} colSpan={3} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
            <td rowSpan={2} colSpan={2} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
          </tr>
        );
      case ResponseStatus.ready:
        if (merchantList.response.statusCode !== 200) {
          return null;
        }

        lastKnownMerchantCount.current = merchants.filteredCount;
        return <LoadedYieldAdsMerchantsFingerprintWhitelistRows
          merchantList={merchants}
          refresh={merchantList.refresh}
        />
      case ResponseStatus.error:
      default:
        return null;
    }
  }, [merchantList]);

  return (
    <Card>
      <Card.Body>
        <h4 className={'mb-4'}>Yieldads Merchants Whitelist</h4>

        {error !== null && (
          <ErrorBox title={'Error during API fetch'}>
            {error}
          </ErrorBox>
        )}

        <PageAlertContextWrapper>
          <YieldAdsMerchantFingerprintWhitelistFilterControl
            defaultDomainFilter={filter.domain}
            refresh={merchantList.refresh}
            onDomainFilterChange={(newValue) => updateFilter('domain', newValue)}
            page={page}
            maxPages={Math.ceil(lastKnownMerchantCount.current / pageSize)}
            onPageChange={(newPage) => {
              query.set('page', newPage);
              setQuery(query);
            }}
          />

          <YieldAdsMerchantFingerprintWhitelistListTable
            sorting={sorting}
            setSorting={setSorting}
          >
            {tableRows}
          </YieldAdsMerchantFingerprintWhitelistListTable>
        </PageAlertContextWrapper>
      </Card.Body>
    </Card>
  );
}
