import {isEvaluating, viewTrafficStatusCode} from "../../ViewTrafficStatus";
import {handlePartnerMerchantUpdateRequest} from "./handlePartnerMerchantUpdateRequest";
import {queueMerchantForEvaluation} from "./queueMerchantForEvaluation";
import type {ApiResponseStatus, UseApiResponseType} from "../../Response";
import type {PartnerMerchant} from "../../../Hooks/Api/useMerchantList";

export const handlePartnerMerchantEvaluationClick = async (
    apiStatus: ApiResponseStatus,
    setApiStatus: (status: UseApiResponseType) => void,
    apiKey: string,
    notificationPusher: Function,
    merchantState: PartnerMerchant,
    setMerchantState: (merchant: PartnerMerchant) => void,
) => {
    if (!isEvaluating(merchantState.viewTrafficStatus)) {
        const isOk = await handlePartnerMerchantUpdateRequest(
            queueMerchantForEvaluation,
            apiStatus,
            setApiStatus,
            apiKey,
            notificationPusher,
            merchantState.partnerMerchantId,
        );

        if (isOk) {
            return setMerchantState({
                ...merchantState,
                viewTrafficStatus: viewTrafficStatusCode.waitingForEvaluation
            });
        }
    }
};
