// @flow
import {Button} from "react-bootstrap";
import {usePageNotificationSystem} from "../../../Context/PageNotification";
import {useState} from "react";
import NotificationToast from "../../../GenericComponents/NotificationToast";

type Props = {};

// Eher als Negativbeispiel gedacht, da Änderungen am State die bestehende Notification nicht aktualisieren.
// Siehe ClickCounterUseEffectNotification für einen korrekten Ansatz.
export default function ClickCounterOnClickNotification(props: Props) {
  const notificationSystem = usePageNotificationSystem();

  const [clicks, setClicks] = useState<number>(0);

  return (
      <Button
        onClick={() => {
          setClicks((old) => old + 1);

          // Counter ist eine Iteration im Rückstand
          const visibleClicks = clicks + 1;
          notificationSystem.pushNotification(
            <NotificationToast id={'debug-click-counter-onClick'} title={'Click Counter'}>
              <div className={'mb-2'}>
                Resetting will reset the state, but will not cause a rerender.
                Use <code>useEffect</code> to handle updating notifications.
              </div>
              <div className={'mb-2'}>You clicked {visibleClicks} time{visibleClicks === 1 ? '' : 's'}</div>
              <Button size={'sm'} onClick={() => setClicks(0)}>Reset</Button>
            </NotificationToast>
          );
        }}
      >Click Counter onClick Toast</Button>
  );
}
