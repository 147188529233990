import {handlePartnerMerchantUpdateRequest} from "./handlePartnerMerchantUpdateRequest";
import {updateMerchantsStatus} from "./updateMerchantsStatus";
import type {ApiResponseStatus, UseApiResponseType} from "../../Response";
import type {PartnerMerchant} from "../../../Hooks/Api/useMerchantList";

export const handlePartnerMerchantYieldadsStatusClick = async (
    apiStatus: ApiResponseStatus,
    setApiStatus: (status: UseApiResponseType) => void,
    apiKey: string,
    notificationPusher: Function,
    merchantState: PartnerMerchant,
    setMerchantState: (merchant: PartnerMerchant) => void,
) => {
    const newStatus = !merchantState.yieldadsStatus;

    const isOk = await handlePartnerMerchantUpdateRequest(
        updateMerchantsStatus,
        apiStatus,
        setApiStatus,
        apiKey,
        notificationPusher,
        merchantState.partnerMerchantId,
        newStatus)

    if (isOk) {
        setMerchantState({ ...merchantState, yieldadsStatus: newStatus});
    }
}
