import {hashCode} from "./StringHelper";

export function stringToHue(input: string): number {
  const hash = hashCode(input);
  // Hash kann negativ sein
  return ((hash % 360) + 360) % 360;
}

export function stringToHuedColor(input: string, saturation: number = 0.9, value: number = 0.9): ColorRgb {
  const hue = stringToHue(input);
  return hsvToRgb(hue, saturation, value);
}

/// h: [0,360)
/// s: [0,1]
/// v: [0,1]
export function hsvToRgb(h, s, v): ColorRgb {
  let r, g, b;
  let i;
  let f, p, q, t;

  // Grayscale
  if (s === 0) {
    return {
      red: Math.round(v * 255),
      green: Math.round(v * 255),
      blue: Math.round(v * 255),
    };
  }

  h /= 60; // Sektor 0 bis 5
  i = Math.floor(h);
  f = h - i; // Faktor innerhalb des Sektors
  p = v * (1 - s);
  q = v * (1 - s * f);
  t = v * (1 - s * (1 - f));

  switch (i) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    default:
      r = v;
      g = p;
      b = q;
      break;
  }

  return {
    red: Math.round(r * 255),
    green: Math.round(g * 255),
    blue: Math.round(b * 255),
  };
}

export type ColorRgb = {
  red: number,
  green: number,
  blue: number,
}
