// @flow
import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {updatePublisherPostbackUrl} from "../../../Helper/Api/Publisher/UpdatePublisherPostbackUrl";
import {useApiKey} from "../../../Context/ApiKey";

type Props = {
  publisherId: string,
  postbackStatus: string | null,
};

export default function ResetButton(props: Props) {
  const [showResetModal, setShowResetModal] = useState(false); // State for controlling the pop-up

  const apiKey = useApiKey();

  const handleReset = () => {
    updatePublisherPostbackUrl(props.publisherId, apiKey.value, {
      postbackUrl: null,
      postbackStatus: props.postbackStatus,
    }).then(() => {
      window.location.reload();
    })
    setShowResetModal(false); // Close the pop-up
  };

  return (
    <>
      <Button onClick={() => setShowResetModal(true)} variant="danger">
        Reset URL
      </Button>

      <Modal show={showResetModal} onHide={() => setShowResetModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Reset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to reset the URL? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowResetModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleReset}>
            Reset
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
