// @flow

import {createContext, useContext} from "react";
import NotificationToast from "../GenericComponents/NotificationToast";

export type PageNotification = {
  id: string,
  notification: React.ReactNode,
}

export type PageNotifications = {
  [string]: PageNotification,
};

export type PageNotificationContextType = {
  notifications: PageNotifications,
  pushNotification: Function,
  clearAll: Function,
  clear: Function,
}

export const PageNotificationContext = createContext(null);

export function usePageNotificationSystem(): PageNotificationContextType {
  return useContext(PageNotificationContext);
}

export const createNotification = (cssId: string, title: string, message: string): NotificationToast => (
  <NotificationToast id={cssId} title={title}>
    {message}
  </NotificationToast>
)
