// @flow
import {useCallback, useEffect, useRef} from "react";
import {Form} from "react-bootstrap";

type Props = {
  name?: string,
  size?: 'sm' | 'lg',
  placeholder?: string,
  defaultValue?: string,
  onChange: Function,
  focusOnLoad?: boolean,
  inputRef?: React.Ref,
};

export default function TimedTextInput(props: Props) {
  const {
    name = undefined,
    size = undefined,
    placeholder = undefined,
    defaultValue = undefined,
    onChange,
    focusOnLoad = false,
    inputRef = undefined,
  } = props;

  const timerRef = useRef(null);

  const updateValue = useCallback((event) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      timerRef.current = null;
      onChange(event.target.value);
    }, 350);

  }, [timerRef, onChange]);

  useEffect(() => {
    return () => {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
    }
  }, [timerRef]);

  const fallbackRef = useRef();
  const ref = inputRef || fallbackRef;

  useEffect(() => {
    if (focusOnLoad && ref.current !== undefined) {
      ref.current.focus();
    }
  }, []);

  // Prematurely confirm with "Enter"
  useEffect(() => {
    const onSubmit = (e) => {
      if (e.code !== 'Enter') {
        return;
      }

      timerRef.current = null;
      onChange(e.target.value);
    }

    ref.current.addEventListener('keypress', onSubmit);
    return () => ref.current !== null && ref.current.removeEventListener('keypress', onSubmit);
  }, [ref, timerRef, onChange]);

  return (
    <Form.Control
      type={'text'}
      name={name}
      size={size}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={updateValue}
      ref={ref}
    />
  );
}
