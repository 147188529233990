// @flow

import {renderEmpty, renderPending} from "./MerchantBlacklistPending";
import {Merchant} from "../../../Hooks/Api/useMerchantBlacklist";
import MerchantBlacklistListRow from "./MerchantBlacklistListRow";
import {fetchApiDelete} from "../../../Helper/Api/fetchApi";
import {useState} from "react";
import {usePageNotificationSystem} from "../../../Context/PageNotification";
import {useApiKey} from "../../../Context/ApiKey";
import {useApiGetState} from "../../../Hooks/Api/useApi";
import type {MerchantList} from "../../../Hooks/Api/useMerchantList";

type Props = {
  selectedPublisherId: string,
  reloadMerchantList: boolean,
  addError: Function,
  removeError: Function,
  setReloadMerchantList: Function,
};

export default function MerchantBlacklistListElements(props: Props) {
  const {
    selectedPublisherId,
    reloadMerchantList,
    addError,
    removeError,
    setReloadMerchantList,
  } = props;

  const {pushNotification} = usePageNotificationSystem();
  const apiKey = useApiKey().value;

  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState<string | false>(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState<string>(0);
  const [blacklistIsLoading, setBlacklistIsLoading] = useState<string>(false); // wenn es gebraucht wird kann es
  const [merchantList, setMerchantList] = useState<MerchantList>([]);

  useApiGetState('/merchant-blacklist', {publisherId: selectedPublisherId, t: reloadMerchantList}, setMerchantList, null, () => {
    setBlacklistIsLoading(false);
  }, () => {
    setBlacklistIsLoading(true);
  }, () => {
    setBlacklistIsLoading(false);
    return selectedPublisherId === null || selectedPublisherId.length !== 16; // nur anfragen, wenn publisherId set
  });

  function deleteFromBlacklist(merchantId: number, merchantName: string) {
    setDeleteButtonDisabled(merchantId);

    if (!window.confirm('Eintrag für ' + merchantName + ' wirklich entfernen?')) {
      setDeleteButtonDisabled(false);
      return;
    }

    setDeleteIsLoading(merchantId);

    const response = fetchApiDelete(`/merchant-blacklist/${merchantId}`, apiKey, {}, null, new AbortController(), pushNotification, () => {
      addError('Delete', response.message);
    }, () => {
      removeError('Delete');
      setDeleteIsLoading(false);
      setDeleteButtonDisabled(false);
      setReloadMerchantList((old: number) => old + 1);
    });
  }

  return (
    <tbody>
    {renderPending(blacklistIsLoading && selectedPublisherId)}
    {renderEmpty(merchantList, blacklistIsLoading, selectedPublisherId)}
    {
      Array.isArray(merchantList) && merchantList.map((merchant: Merchant) => <MerchantBlacklistListRow
        key={merchant.id + '_' + merchant.market + '_' + merchant.identifier}
        deleteIsLoading={deleteIsLoading}
        merchantId={merchant.id}
        merchantName={merchant.merchantName}
        market={merchant.market}
        yieldadsMerchantId={merchant.yieldadsMerchantId}
        accountId={merchant.identifier}
        accountName={merchant.accountName}
        deleteCallback={deleteFromBlacklist}
        deleteButtonDisabled={deleteButtonDisabled}
      />)
    }
    </tbody>
  );
}
