// @flow
import {useMemo, useState} from "react";
import type {Publisher} from "./Publisher";
import {
  Button, Col, Modal,
  Row, Spinner,
  Table
} from "react-bootstrap";
import {Link} from "react-router-dom";
import {TiPen} from "react-icons/ti";
import {useTheme} from "../../Context/Theme";
import {usePublisherAllowedAccountsList} from "../../Hooks/Api/usePublisherAllowedAccountsList";
import {ButtonVariant} from "react-bootstrap/types";
import type {AllowedAccounts} from "../../Hooks/Api/usePublisherAllowedAccountsList";
import {ResponseStatus} from "../../Helper/Response";
import MarketRow from "./MarketRow";
import PartnerAbbreviation from "../../GenericComponents/PartnerAbbreviation";

type Props = {
    publisher: Publisher,
};

export default function AllowedAccountsOverviewButton(props: Props) {
    const {publisher} = props;

  const hasAllowed = publisher.allowedAccounts.length > 0;
  if (!hasAllowed) {
    return <EditButton publisher={publisher} variant={'secondary'}/>;
  }

  return <ModalHandler publisher={publisher}/>;
}

type ModalHandlerProps = {
  publisher: Publisher,
};

function ModalHandler(props: ModalHandlerProps) {
  const {
    publisher,
  } = props;

  const [show, setShow] = useState<boolean>(false);
  const doShow = () => setShow(true);
  const doHide = () => setShow(false);

  return (
    <>
      <Button variant="secondary" size={'sm'} onClick={doShow}>
        Show {publisher.allowedAccounts.length}
      </Button>
      <Modal
        show={show}
        onHide={doHide}
        size={'lg'}
        centered
      >
        <Modal.Header style={{ position: 'sticky', top: 0, zIndex: 1000 , backgroundColor: "inherit"}}>
          <h4>Allowed Accounts ({publisher.name})</h4>
          <div>
            <EditButton publisher={publisher} variant={'primary'}/>
            <Button size={'sm'} variant="secondary" className={'ms-2'} onClick={doHide}>Close</Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ModalContent publisher={publisher}/>
        </Modal.Body>
      </Modal>
      </>
  );
}

function PendingIndicator() {
  return <Row className={'fade-in-delayed-short'}>
    <Col className={'d-flex justify-content-center'}><Spinner animation={'grow'}/></Col>
    <Col className={'d-flex justify-content-center'}><Spinner animation={'grow'}/></Col>
    <Col className={'d-flex justify-content-center'}><Spinner animation={'grow'}/></Col>
  </Row>
}

type ModalContentProps = {
  publisher: Publisher,
}

function ModalContent(props: ModalContentProps) {
  const {
    publisher,
  } = props;

  const apiData = usePublisherAllowedAccountsList(publisher.id);

  switch (apiData.status) {
    case ResponseStatus.pending:
      return <PendingIndicator/>;
    case ResponseStatus.ready:
      if (apiData.response.statusCode !== 200) {
        return null;
      }

      return <ModalContentLoaded publisher={publisher} accountsData={apiData.response.parsedBody}/>;
    case ResponseStatus.error:
      return null;

    default:
      return null;
  }
}

type ModalContentLoadedProps = {
  accountsData: AllowedAccounts,
}

function ModalContentLoaded(props: ModalContentLoadedProps) {
  const {
    accountsData,
  } = props;

  const {theme} = useTheme();

  const partners = useMemo(() => {
    return collectPartners(accountsData);
  }, [accountsData])

  return (
    <Table striped variant={theme.type} hover>
      <thead>
      <tr>
        <th></th>
        {partners.map(partner => (
          <th key={partner}>
            <PartnerAbbreviation partner={partner.toUpperCase()}/>
          </th>
        ))}
      </tr>
      </thead>
      <tbody>
        <MarketRow accountsData={accountsData} partners={partners}/>
      </tbody>
    </Table>
  );
}

type EditButtonProps = {
  publisher: Publisher,
  variant?: ButtonVariant,
}

function EditButton(props: EditButtonProps) {
  const {
    publisher,
    variant = undefined,
  } = props;

  return (
    <Link to={`/publisher/${publisher.id}/allowed-accounts/edit`}>
      <Button
        size={'sm'}
        variant={variant}
        title={'Editor für Allowed Accounts'}
        onClick={() => {}}
      ><TiPen/> Edit</Button>
    </Link>
  );
}

function collectPartners(allowedAccounts: AllowedAccounts): Array<string> {
  const partnerKeys = new Set();
  Object.values(allowedAccounts).forEach(partnerData => {
    Object.keys(partnerData).forEach(partnerKey => partnerKeys.add(partnerKey));
  });

  return Array.from(partnerKeys).sort();
}
