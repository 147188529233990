// @flow
import {Table} from "react-bootstrap";
import {useTheme} from "../../../Context/Theme";
import HeaderCell from "../../../GenericComponents/SortableTableHeaderCell";
import type {SortingType} from "../../../Helper/Sorting";

export type Filter = {
  domain: ?string,
  market: ?string,
  account: ?string,
};

type Props = {
  children: React.ReactNode,
  sorting: SortingType,
  setSorting: (newSorting: SortingType) => void,
  striped?: boolean,
  hover?: boolean,
  trClassName?: string,
};

export default function IgnoredMerchantListTable(props: Props) {
  const {
    children,
    sorting,
    setSorting,
    striped = true,
    hover = true,
    trClassName = undefined,
  } = props;

  const {theme} = useTheme();

  return (
    <Table striped={striped} variant={theme.type} hover={hover}>
      <thead>
      <tr className={trClassName}>
        <HeaderCell
          iconPosition={'r'}
          value={'domain'}
          sorting={sorting}
          setSorting={setSorting}
        >Domain</HeaderCell>

        <HeaderCell
          iconPosition={'r'}
          value={'market'}
          sorting={sorting}
          setSorting={setSorting}
        >Market</HeaderCell>

        <HeaderCell
          iconPosition={'r'}
          value={'identifier'}
          sorting={sorting}
          setSorting={setSorting}
        >Account</HeaderCell>

        <HeaderCell
          iconPosition={'l'}
          value={'priority'}
          sorting={sorting}
          setSorting={setSorting}
          className={'text-end text-nowrap'}
        >Priority</HeaderCell>

        <HeaderCell
          iconPosition={'r'}
          value={'partnerStatus'}
          sorting={sorting}
          setSorting={setSorting}
          className={'text-center'}
        >Partner Status</HeaderCell>

        <HeaderCell
          iconPosition={'r'}
          value={'yieldadsStatus'}
          sorting={sorting}
          setSorting={setSorting}
          className={'text-center'}
        >Yieldads Status</HeaderCell>

        <HeaderCell
          iconPosition={'r'}
          value={'viewTrafficStatus'}
          sorting={sorting}
          setSorting={setSorting}
          className={'text-center'}
          title={'View Traffic Status'}
        >VT Status</HeaderCell>
      </tr>
      </thead>
      <tbody>
      {children}
      </tbody>
    </Table>
  );
}
