// @flow

import {createResponse, ResponseStatus, UseApiResponseType} from "../Response";
import {fetchApiGet} from "./fetchApi";

export async function verifyApiKey(abortController: AbortController, apiKey: string): UseApiResponseType<boolean> {
  try {
    const response = await fetchApiGet('/auth-verify', apiKey, null, abortController);

    return createResponse(
      response,
      ResponseStatus.ready,
      null,
    );
  } catch (error) {
    return createResponse(
      null,
      ResponseStatus.error,
      error.message,
    );
  }
}
