// @flow
import React from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {TiHomeOutline} from "react-icons/ti";

type Props = {
  icon?: any,
  iconColor?: string,
  to: string,
  children: string|any,
};

export default function NavigationLink(props: Props) {
  const {
    icon: Icon = TiHomeOutline,
    iconColor = 'color-blue',
    to = '/',
    children = 'Link',
  } = props;

  const navigate = useNavigate();

  return (
    <div className={'navigation-link navigation-item cursor-pointer'} onClick={() => navigate(to)}>
      <NavLink to={to} className={({isActive}) => 'navigation-link' + (isActive ? ' navigation-link-active' : '')}>
        <Icon className={`navigation-icon ${iconColor}`}/> {children}
      </NavLink>
    </div>
  );
}
