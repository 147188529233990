// @flow
import {Pagination as BootPagination} from "react-bootstrap";
import {useMemo} from "react";

type Props = {
  page: number,
  maxPage: number,
  onChange: Function,
  range?: number,
};

export default function Pagination(props: Props) {
  const {
    page,
    maxPage,
    onChange,
    range = 3,
  } = props;

  const pageItems = useMemo(() => {
    // Wenn es durch Filter keine Einträge gibt, dann ist die page 0
    if (maxPage <= 1) {
      return [<BootPagination.Item key={1} active={page === 1} onClick={() => (onChange(1))}>«&nbsp;1&nbsp;»</BootPagination.Item>];
    }

    const items = [];

    items.push(<BootPagination.Item key={1} active={page === 1} onClick={() => (onChange(1))}>«&nbsp;1</BootPagination.Item>);

    const from = Math.max(page - range, 2);
    const to   = Math.min(page + range, maxPage - 1);

    for (let i = from; i <= to; i++) {
      items.push(<BootPagination.Item key={i} active={i === page} onClick={() => (onChange(i))}>{i}</BootPagination.Item>);
    }

    items.push(<BootPagination.Item key={maxPage} active={page === maxPage} onClick={() => (onChange(maxPage))}>{maxPage}&nbsp;»</BootPagination.Item>);

    return items;
  }, [range, page, maxPage, onChange]);

  return (
    <BootPagination size={'sm'} bg={'secondary'}>
      {pageItems}
    </BootPagination>
  );
}
