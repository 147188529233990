// @flow
import {Button} from "react-bootstrap";

type Props = {
  onClick: () => void,
  disabled: boolean,
  children: string,
};

export default function SaveButton(props: Props) {
  const {
    onClick = () => {},
    disabled = false,
    children = 'Speichern',
    variant = 'success'
  } = props;

  return (
    <Button className={'mx-2'} size={'sm'} variant={variant} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  );
}
