// @flow
import type {PartnerMerchant, MerchantList} from "../../../Hooks/Api/useMerchantList";
import MerchantListRow from "./MerchantListRow";
import type {Account} from "../../../Hooks/Api/useAccountList";

type Props = {
  merchantList: MerchantList,
  accountMap: Map<Account>,
  stripingClasses?: Array<string>,
  isIgnoredList?: boolean,
};

export default function LoadedMerchantsListRows(props: Props) {
  const {
    merchantList,
    accountMap,
    stripingClasses = [undefined],
    isIgnoredList = false,
  } = props;

  const stripingInterval = stripingClasses.length;
  return (
    <>
      {merchantList.merchants.map((merchant: PartnerMerchant, index) => {
        return (
          <MerchantListRow
            key={merchant.partnerMerchantId}
            accountMap={accountMap}
            merchant={merchant}
            trClassName={stripingClasses[index % stripingInterval]}
            isIgnoredList={isIgnoredList}
          />
        );
      })}
    </>
  );
}
