// @flow
import React, {useEffect, useState} from "react";
import {Table, Button, Form} from "react-bootstrap";
import {updatePublisherPostbackUrl} from "../../../Helper/Api/Publisher/UpdatePublisherPostbackUrl";
import {useApiKey} from "../../../Context/ApiKey";
import FormTableRow from "./FormTableRow";
import {useFormData} from "../../../Hooks/Api/usePostbackFormData";
import {generateUrl, readPrevUrl} from "./UrlHelper";
import ResetButton from "./Resetbutton";
import {usePageNotificationSystem} from "../../../Context/PageNotification";
import NotificationToast from "../../../GenericComponents/NotificationToast";

type Props = {
  parsedBody: {
    postbackUrl: string | null,
    postbackStatus: string | null,
  }
}

export default function PostbackUrlForm(props: Props) {
  const [formData, setFormData, handleChange, validateForm, errors] = useFormData();
  const [postbackStatus, setPostbackStatus] = useState(props.parsedBody.postbackStatus);
  const {pushNotification} = usePageNotificationSystem();

  const apiKey = useApiKey();

  useEffect(() => {
    let prevUrl = props.parsedBody.postbackUrl;

    let url = '';
    let newParams = {};

    /**
     * Url kann in SQL null sein, daher folgende Prüfung
     */
    try {
      prevUrl = readPrevUrl(prevUrl)
      url = new URL(prevUrl.baseUrl)
      newParams = prevUrl.formParameters
    } catch {}

    setFormData((prevFormData) => ({
      ...prevFormData,
      BASE_URL: url.toString(),
      ...newParams,
    }));
  }, [props.parsedBody.postbackUrl, setFormData]);

  const handleSubmit = async (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (validateForm()) {
      const body = {
        postbackUrl: generateUrl(formData),
        postbackStatus,
      };
      const response = await updatePublisherPostbackUrl(props.publisherId, apiKey.value, body);
      if (response.response.statusCode === 204) {
        pushNotification(
          <NotificationToast
            id={`submit-success`}
            title={'Success'}
            variant={'success'}
            duration={4000}
          >
            Url update was successful
          </NotificationToast>
        );
      } else {
        pushNotification(
          <NotificationToast
            id={`submit-failure`}
            title={'Failure'}
            variant={'danger'}
            duration={4000}
          >
            {response.response.parsedBody.errors.body}
          </NotificationToast>
        );
      }
    }
  };

  return (
    <div className="form-container">
      <div className="url-preview">
        <label>URL Preview:</label>
        <Form.Control
          as={'textarea'}
          type={'text'}
          name="preview"
          size="sm"
          value={generateUrl(formData)}
          placeholder={'https://example.com/postback?api_key=123'}
          disabled={true}
        />
      </div>
      <br/>
      <Table borderless>
        <tbody>
        <FormTableRow label="Base URL" name="BASE_URL" value={formData.BASE_URL} error={errors.BASE_URL}
                      information={'Hier kann die Domain und weitere statische Query-Parameter eingetragen werden' +
                        '. Format: https://www.example.com/path?parameter=value'}
                      onChange={handleChange}/>
        </tbody>
      </Table>
      <Table className="form-table">
        <thead>
        <tr>
          <th className={"col-4"}>Platzhalter</th>
          <th className={"col-8"}>Query-Parameter</th>
        </tr>
        </thead>
        <tbody>
        <FormTableRow label="EVENT_ID" name="EVENT_ID" value={formData.EVENT_ID} error={errors.EVENT_ID}
                      information={'Eindeutige Id der Transaktion, 64 Character '}
                      onChange={handleChange}/>
        <FormTableRow label="ADVERTISER_ID" name="ADVERTISER_ID" value={formData.ADVERTISER_ID}
                      error={errors.ADVERTISER_ID}
                      information={'Id des Merchants, siehe Merchant Api'}
                      onChange={handleChange}/>
        <FormTableRow label="COMMISSION_ID" name="COMMISSION_ID" value={formData.COMMISSION_ID}
                      error={errors.COMMISSION_ID}
                      information={'Id der Commission, 24 Character'}
                      onChange={handleChange}/>
        <FormTableRow label="COMMISSION" name="COMMISSION" value={formData.COMMISSION} error={errors.COMMISSION}
                      information={'Wert der Commission, Dezimalzahl mit Trennzeichen “.”'}
                      onChange={handleChange}/>
        <FormTableRow label="MODIFIED_DATE" name="MODIFIED_DATE" value={formData.MODIFIED_DATE}
                      error={errors.MODIFIED_DATE}
                      information={'Änderungsdatum der Status Änderung im Format “2024-08-12T15:52:01+00:00”'}
                      onChange={handleChange}/>
        <FormTableRow label="SUB_ID" name="SUB_ID" value={formData.SUB_ID} error={errors.SUB_ID}
                      information={'Publisher PlacementId 1'}
                      onChange={handleChange}/>
        <FormTableRow label="SUB_ID2" name="SUB_ID2" value={formData.SUB_ID2} error={errors.SUB_ID2}
                      information={'Publisher PlacementId 2'}
                      onChange={handleChange}/>
        <FormTableRow label="EVENT_TYPE" name="EVENT_TYPE" value={formData.EVENT_TYPE} error={errors.EVENT_TYPE}
                      information={'“NEW” oder “UPDATE”'}
                      onChange={handleChange}/>
        <FormTableRow label="STATE" name="STATE" value={formData.STATE} error={errors.STATE}
                      information={'“open”, “confirmed”, “rejected”, “paid”'}
                      onChange={handleChange}/>
        <FormTableRow label="CLICK_DATE" name="CLICK_DATE" value={formData.CLICK_DATE} error={errors.CLICK_DATE}
                      information={'Datum des User Clicks im Format “2024-08-12T15:52:01+00:00”'}
                      onChange={handleChange}/>
        <FormTableRow label="CURRENCY" name="CURRENCY" value={formData.CURRENCY} error={errors.CURRENCY}
                      information={'“EUR”. Die Daten sind aktuell in dem Endpoint immer in EUR'}
                      onChange={handleChange}/>
        <FormTableRow label="MARKET" name="MARKET" value={formData.MARKET} error={errors.MARKET}
                      information={'Bezeichnung des Market in welchem der Click passiert ist im Format ISO 3166-1 alpha-2 '}
                      onChange={handleChange}/>
        <FormTableRow label="MERCHANT_NAME" name="MERCHANT_NAME" value={formData.MERCHANT_NAME}
                      error={errors.MERCHANT_NAME}
                      information={'Name des Merchants'}
                      onChange={handleChange}/>
        </tbody>
      </Table>

      <fieldset>
        <label>Welche Events soll der Publisher bekommen?</label>
        <div>
          <input
            type={'radio'}
            name={'postbackStatus'}
            id={'postbackStatus_both'}
            onChange={() => setPostbackStatus(null)}
            checked={postbackStatus === null}
          />
          <label htmlFor={'postbackStatus_both'}>Beide</label>
        </div>

        <div>
          <input
            type={'radio'}
            name={'postbackStatus'}
            id={'postbackStatus_new'}
            onChange={() => setPostbackStatus('NEW')}
            checked={postbackStatus === 'NEW'}
          />
          <label htmlFor={'postbackStatus_new'}>New</label>
        </div>

        <div>
          <input
            type={'radio'}
            name={'postbackStatus'}
            id={'postbackStatus_update'}
            onChange={() => setPostbackStatus('UPDATE')}
            checked={postbackStatus === 'UPDATE'}
          />
          <label htmlFor={'postbackStatus_update'}>Updates</label>
        </div>
      </fieldset>

      <div style={{ marginTop: 16 }}>
        <Button onClick={handleSubmit} variant="primary" style={{marginRight: 8}}>
          Save Form
        </Button>
        <ResetButton publisherId={props.publisherId} postbackStatus={postbackStatus} />
      </div>
    </div>
  );
}
