// @flow
import {Col, Form, Row} from "react-bootstrap";
import {useCallback, useMemo, useState} from "react";
import TimedTextInput from "../../../GenericComponents/Input/TimedTextInput";
import {AiFillEdit} from "react-icons/ai";
import Pagination from "../../../GenericComponents/Pagination";
import ViewTrafficStatusSelect from "../Common/ViewTrafficStatusSelect";
import type {Account} from "../../../Hooks/Api/useAccountList";

type Props = {
  accountMap: Map<Account>,
  defaultDomainFilter: ?string,
  defaultMarketFilter: ?string,
  defaultAccountFilter: ?string,
  defaultViewTrafficStatus: ?string,

  onDomainFilterChange: Function,
  onMarketFilterChange: Function,
  onAccountFilterChange: Function,
  onViewTrafficStatusChange: Function,

  page: number,
  maxPages: number,
  onPageChange: Function,

  showLegend?: boolean,
};

export default function MerchantFilterControls(props: Props) {
  const {
    accountMap,
    defaultDomainFilter,
    defaultMarketFilter,
    defaultAccountFilter,
    defaultViewTrafficStatus,

    onDomainFilterChange,
    onMarketFilterChange,
    onAccountFilterChange,
    onViewTrafficStatusChange,

    page,
    maxPages,
    onPageChange,

    showLegend = true,
  } = props;

  const [selectedMarket, setSelectedMarket] = useState<string>(defaultMarketFilter);

  const onMarketChange = useCallback((event) => {
    const newValue = event.target.value;

    setSelectedMarket(newValue);
    onMarketFilterChange(newValue);
  }, [setSelectedMarket, onMarketFilterChange]);

  const marketOptions = useMemo(() => {
    const options = new Map();
    options.set('any', <option key={'any'} value={'any'}>Alle Märkte</option>);

    for (const account: Account of accountMap.values()) {
      const {market} = account;

      if (!options.has(market)) {
        options.set(market, <option key={market} value={market}>{market.toUpperCase()}</option>);
      }
    }

    return Array.from(options.values(), (value) => value);
  }, [accountMap]);

  const accountOptions = useMemo(() => {
    const options = [<option key={'any'} value={'any'}>Alle Accounts</option>];

    for (const account: Account of accountMap.values()) {
      const hasMarketFiltered = selectedMarket !== null && selectedMarket !== 'any';
      if (hasMarketFiltered && account.market !== selectedMarket) {
        continue;
      }

      options.push(
        <option key={account.identifier} value={account.identifier}>
          {account.name}
        </option>
      );
    }

    return options;
  }, [accountMap, selectedMarket]);

  const legend = showLegend ? <em>
    <AiFillEdit/> = Bearbeiten durch Doppelklick
  </em> : null;

  return (
    <Row className={'mb-3'}>
      {/* Wenn die Controls umbrechen sorgt der Margin für Abstand */}
      <Col md={'auto'} className={'mb-2'}>
        <TimedTextInput
          name={'domain'}
          size={'sm'}
          placeholder={'Suchen'}
          defaultValue={defaultDomainFilter}
          onChange={onDomainFilterChange}
          focusOnLoad={true}
        />
      </Col>
      <Col md={"auto"} className={'mb-2'}>
        <Form.Select
          name={'market'}
          size={'sm'}
          value={defaultMarketFilter || 'any'}
          onChange={onMarketChange}
        >
          {marketOptions}
        </Form.Select>
      </Col>
      <Col md={"auto"}>
        <Form.Select
          size={'sm'}
          value={defaultAccountFilter || 'any'}
          onChange={(event) => onAccountFilterChange(event.target.value)}
        >
          {accountOptions}
        </Form.Select>
      </Col>
      <ViewTrafficStatusSelect value={defaultViewTrafficStatus} onChange={onViewTrafficStatusChange}/>
      <Col>
        <Pagination page={page} maxPage={maxPages} onChange={onPageChange}/>
      </Col>
      <Col>
        {/*<em>*/}
        {/*  Zellen von markierten Spalten (<AiFillEdit/>) können per Doppelklick bearbeitet werden*/}
        {/*</em>*/}
        {legend}
      </Col>
    </Row>
  );
}
