import {isEvaluating, isViewTrafficActive, viewTrafficStatusCode} from "../../ViewTrafficStatus";
import {handlePartnerMerchantUpdateRequest} from "./handlePartnerMerchantUpdateRequest";
import {updateMerchantViewTrafficStatus} from "./updateMerchantViewTrafficStatus";
import type {ApiResponseStatus, UseApiResponseType} from "../../Response";
import type {PartnerMerchant} from "../../../Hooks/Api/useMerchantList";

export const handlePartnerMerchantViewTrafficDoubleClick = async (
    apiStatus: ApiResponseStatus,
    setApiStatus: (status: UseApiResponseType) => void,
    apiKey: string,
    notificationPusher: Function,
    merchantState: PartnerMerchant,
    setMerchantState: (merchant: PartnerMerchant) => void,
) => {
    const isCurrentlyActive = isViewTrafficActive(merchantState.viewTrafficStatus);
    const newStatus = isCurrentlyActive ? viewTrafficStatusCode.disabledByBackend : viewTrafficStatusCode.enabledByBackend;

    if (isEvaluating(merchantState.viewTrafficStatus)) {
        if (!window.confirm(
            'Dieser Partner Merchant befindet sich in Evaluierung.'
            + '\nWenn Sie den Status ändern, wird die Evaluierung beendet.\nMöchten Sie fortfahren?'
        )) {
            return;
        }
    }

    const isOk = await handlePartnerMerchantUpdateRequest(
        updateMerchantViewTrafficStatus,
        apiStatus,
        setApiStatus,
        apiKey,
        notificationPusher,
        merchantState.partnerMerchantId,
        newStatus
    )

    if (isOk) {
        setMerchantState({ ...merchantState, viewTrafficStatus: newStatus});
    }
};
