// @flow
import {Button} from "react-bootstrap";
import {usePageNotificationSystem} from "../../../Context/PageNotification";
import NotificationToast from "../../../GenericComponents/NotificationToast";

type Props = {};
export default function RandomNotification(props: Props) {
  const notificationSystem = usePageNotificationSystem();

  return (
      <Button
        onClick={() => {
          const number = (Math.random() * 10000).toFixed(0);
          notificationSystem.pushNotification(
            <NotificationToast id={`debug-random-${number}`} title={'Your lucky numbers'} variant={'warning'}>
              <span>You see {number} and feel lucky inside.</span>
            </NotificationToast>
          );
        }}
      >Pop open a new toast</Button>
  );
}
