// @flow
import {Col, Row} from "react-bootstrap";
import type {AllowedAccounts} from "../../../Hooks/Api/usePublisherAllowedAccountsList";
import PartnerBlock from "./PartnerBlock";

type Props = {
  market: string,
  accountsData: AllowedAccounts,
  onAllowedChange: ?(partner: string, market: string, identifier: string | Array<string>, allowed: boolean) => void,
};

export default function MarketRow(props: Props) {
  const {
    market,
    accountsData,
    onAllowedChange = () => {},
  } = props;

  return (
    <Row>
      <Col md={'auto'} className={'mt-2 ms-3 me-1 p-0'}>
        <div style={circleStyle}>
          {market}
        </div>
      </Col>
      <Col>
        <Row>
          {Object.keys(accountsData[market]).map((partner) => (
            <Col key={partner} md={4} className={'my-2'}>
              <PartnerBlock
                market={market}
                partner={partner}
                accountsData={accountsData}
                onAllowedChange={onAllowedChange}
              />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
}

const circleStyle = {
  width: '1.75em',
  height: '1.75em',
  borderRadius: '50%',
  fontSize: '0.85em',
  lineHeight: '1.5em',
  textAlign: 'center',
  background: '#0004',
  color: '#fff',
};
