// @flow
import {Button} from "react-bootstrap";

type Props = {
  disabled: boolean,
  loading: boolean,
  title: string,
  queueForEvaluation: Function,
};

export default function RetryPartnerMerchantEvaluation(props: Props) {
  const {
    disabled,
    loading,
    queueForEvaluation,
    title,
  } = props;

  return (
    <Button
      disabled={disabled}
      onClick={queueForEvaluation}
      size={'sm'}
      title={title}
      variant={disabled ? 'secondary' : 'primary'}
    >
      {loading ? '...' : 'Retry'}
    </Button>
  );
}
