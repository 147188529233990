// @flow

import {fetchApiPost, OnApiErrorCallback} from "../fetchApi";
import {createResponse, ResponseStatus, UseApiResponseType} from "../../Response";
import type {UpdatePropertyFunction} from "./updateProperty";

export const queueMerchantForEvaluation: UpdatePropertyFunction = async (
    merchantId: string,
    body?: {},
    apiKey: string,
    pushNotification: ?Function = null,
    onError: ?OnApiErrorCallback = null,
): UseApiResponseType<any> => {

    try {
        const rawResponse = await fetchApiPost(
          `/merchant/${merchantId}/evaluation`,
          apiKey,
          body,
          null,
          null,
          pushNotification,
          onError,
        );
        return createResponse(
            rawResponse,
            ResponseStatus.ready,
          null,
        );
    } catch (error) {
        return createResponse(
            null,
            ResponseStatus.error,
            error.message,
        );
    }
}