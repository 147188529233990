// @flow

import {fetchApiPatch, OnApiErrorCallback} from "../fetchApi";
import {createResponse, ResponseStatus, UseApiResponseType} from "../../Response";
import type {ViewTrafficStatusCode} from "../../ViewTrafficStatus";
import type {UpdatePropertyFunction} from "./updateProperty";

export const updateMerchantViewTrafficStatus: UpdatePropertyFunction = async (
  merchantId: string,
  newStatus: ViewTrafficStatusCode,
  apiKey: string,
  pushNotification: ?Function = null,
  onError: ?OnApiErrorCallback = null,
): UseApiResponseType<any> => {
  const body = {
    status: newStatus,
  };

  try {
    const rawResponse = await fetchApiPatch(`/merchants/${merchantId}/view-traffic-status`, apiKey, body, null, null, pushNotification, onError);
    return createResponse(
      rawResponse,
      ResponseStatus.ready,
      null,
    );
  } catch (error) {
    return createResponse(
      null,
      ResponseStatus.error,
      error.message,
    );
  }
}
