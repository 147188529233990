// @flow
import FlagBadge from "../../../GenericComponents/FlagBadge";

type Props = {
  yieldadsStatus: boolean,
  pending: boolean,
  toggleStatus: Function,
  isEditable: boolean,
};

export default function YieldAdsStatus(props: Props) {
  const {
    yieldadsStatus,
    pending,
    toggleStatus,
    isEditable,
  } = props;

  if (isEditable) {
    return (
      <FlagBadge
        value={yieldadsStatus}
        textTrue={'Aktiv'}
        textFalse={'Inaktiv'}
        textForce={pending ? '...' : null}
        variantForce={pending ? 'warning' : null}
        onDoubleClick={toggleStatus}
      />
    );
  }

  return (
      <FlagBadge
          value={yieldadsStatus}
          textTrue={'Aktiv'}
          textFalse={'Inaktiv'}
          textForce={pending ? '...' : null}
          variantForce={pending ? 'warning' : null}
      />
  );
}
