// @flow
import {Button} from "react-bootstrap";
import {usePageNotificationSystem} from "../../../Context/PageNotification";
import {useEffect, useRef, useState} from "react";
import NotificationToast from "../../../GenericComponents/NotificationToast";

type Props = {};

export default function TimerNotification(props: Props) {
  const {pushNotification} = usePageNotificationSystem();

  const [enabled, setEnabled] = useState<boolean>(false);
  const intervalRef = useRef(null);

  const onClose = () => {
    setEnabled(false);
  };

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    if (!enabled) {
      return;
    }

    const updateNotification = () => {
      pushNotification(
        <NotificationToast id={'debug-time'} title={'Time'} variant={'info'} onClose={onClose}>
          The time is {new Date().toLocaleString(navigator.language)}
        </NotificationToast>
      )
    };

    updateNotification();
    intervalRef.current = setInterval(updateNotification, 200);

    return () => clearInterval(intervalRef.current);
  }, [enabled, pushNotification, intervalRef]);

  return (
    <Button
      variant={'success'}
      onClick={() => setEnabled(true)}
    >Add self-updating time notification</Button>
  );
}
