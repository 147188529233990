// @flow

import {Button, Col} from "react-bootstrap";
import NotificationToast from "../../GenericComponents/NotificationToast";
import {usePageNotificationSystem} from "../../Context/PageNotification";
import {useEffect, useState} from "react";
import {fetchApiGet, fetchApiPost} from "../../Helper/Api/fetchApi";
import {useApiKey} from "../../Context/ApiKey";
import ConfirmDialogSpinner from "./ConfirmDialogSpinner";
import ConfirmDialog from "./ConfirmDialog";


type Props = {
    selectedFromDate: string,
    selectedToDate: string,
    selectedPublisherId: string,
    reloadPaymentsList: Function,
    isEstimationButtonDisabled: boolean,
    setEstimationButtonDisabled: Function,
}

export default function PaymentEstimationAndCreateButton(props: Props) {
    const {
        selectedFromDate,
        selectedToDate,
        selectedPublisherId,
        reloadPaymentsList,
        isEstimationButtonDisabled,
        setEstimationButtonDisabled,
    } = props;

    const notificationSystem = usePageNotificationSystem();
    const [isRequestingEstimation, setIsRequestingEstimation] = useState(false);
    const [isRequestingCreate, setIsRequestingCreate] = useState(false);
    const apiKey = useApiKey().value;
    const [mustDisplaySpinner, setMustDisplaySpinner] = useState(false);
    const [mustDisplayConfirmDialog, setMustDisplayConfirmDialog] = useState(true);
    const [estimationResponse, setEstimationResponse] = useState(null);

    useEffect(() => {
        if (estimationResponse === null) {
            return;
        }

        notificationSystem.pushNotification(
          <NotificationToast
            id={'paymentEstimation'}
            variant={'info'}
            title={'Payment estimation'}>
              <table>
                  <tbody>
                  <tr>
                      <td>Publisher ID:</td>
                      <td>{estimationResponse.parsedBody.publisherId}</td>
                  </tr>
                  <tr>
                      <td>Commissions:</td>
                      <td style={{textAlign: 'right'}}>{estimationResponse.parsedBody.commissionCount}</td>
                  </tr>
                  <tr>
                      <td>Revenue:</td>
                      <td style={{textAlign: 'right'}}>{estimationResponse.parsedBody.sumRevenueEur} €</td>
                  </tr>
                  <tr>
                      <td>Revenue Yieldads:</td>
                      <td style={{textAlign: 'right'}}>{estimationResponse.parsedBody.sumRevenueYieldadsEur} €</td>
                  </tr>
                  <tr>
                      <td>Revenue Publisher:</td>
                      <td style={{textAlign: 'right'}}>{estimationResponse.parsedBody.sumRevenuePublisherEur} €</td>
                  </tr>
                  </tbody>
              </table>
              <br/>
              <ConfirmDialog
                mustDisplayConfirmDialog={mustDisplayConfirmDialog}
                onClickFunctionality={paymentConfirmed.bind(this, reloadPaymentsList)}
                isDisabled={estimationResponse.parsedBody.commissionCount < 1}
              />
              <ConfirmDialogSpinner mustDisplaySpinner={mustDisplaySpinner} />
          </NotificationToast>
        );
    }, [mustDisplaySpinner, mustDisplayConfirmDialog, estimationResponse]);

    async function doPaymentEstimation () {
        setEstimationButtonDisabled(true);
        setMustDisplayConfirmDialog(true);
        setMustDisplaySpinner(false);
        setEstimationResponse(null);

        if (isRequestingEstimation) {
            return;
        }

        notificationSystem.clearAll();
        setIsRequestingEstimation(true);

        const query = new URLSearchParams({
            from: selectedFromDate,
            to: selectedToDate,
            publisherId: selectedPublisherId,
        });

        const response = await fetchApiGet(
            '/commission-estimation',
            apiKey,
            query,
            new AbortController(),
            notificationSystem.pushNotification,
            null,
        );

        setIsRequestingEstimation(false);
        setEstimationButtonDisabled(false);

        if (response.parsedBody.errors) {
            notificationSystem.pushNotification(
                <NotificationToast
                    id={'paymentEstimation'}
                    variant={'warning'}
                    title={'Payment estimation'}
                >
                    Could not estimate payment:<br/>
                    <ul>
                        {
                            Object.keys(response.parsedBody.errors).map(
                              (key) => <li key={key}>{response.parsedBody.errors[key]}</li>
                            )
                        }
                    </ul>
                </NotificationToast>
            );

            return;
        }

        setEstimationResponse(response);
    }

    async function paymentConfirmed(reloadPaymentsList: Function) {
        setEstimationButtonDisabled(true);
        setMustDisplayConfirmDialog(false);
        setMustDisplaySpinner(true);

        // ### debug ##############################################################
        // await new Promise(resolve => setTimeout(resolve, 4000));
        // alert('Fake payment created');
        // setMustDisplaySpinner(false);
        // setEstimationButtonDisabled(false);
        // return;
        // ########################################################################

        if (isRequestingCreate) {
            return;
        }

        const response = await fetchApiPost('/create-payment', apiKey, {
            from: selectedFromDate,
            to: selectedToDate,
            publisherId: selectedPublisherId,
        }, null, new AbortController(), notificationSystem.pushNotification, null);

        setMustDisplaySpinner(false);

        if (response.parsedBody.errors) {
            notificationSystem.pushNotification(
              <NotificationToast
                id={'paymentConfirmation'}
                variant={'danger'}
                title={'Payment confirmation'}
              >
                  <p>
                      Could not confirm payment:<br/>
                      <ul>
                          {
                              Object.keys(response.parsedBody.errors).map(
                                (key) => <li key={key}>{response.parsedBody.errors[key]}</li>
                              )
                          }
                      </ul>
                      <br/>
                      Please contact your highly professional developer team.
                  </p>
              </NotificationToast>
            );

            return;
        }

        setEstimationButtonDisabled(false);

        notificationSystem.pushNotification(
            <NotificationToast
                id={'paymentConfirmation'}
                variant={'success'}
                title={'Payment created'}>
                <p>
                    Payment for selected month was created.<br/>
                    Payment contains {response.parsedBody.commissionCount} commissions.
                </p>
            </NotificationToast>
        );

        setIsRequestingCreate(false);
        reloadPaymentsList();
    }

    return (
        <Col className={'mb-4'}>
            <Button
                onClick={doPaymentEstimation}
                disabled={isEstimationButtonDisabled || isRequestingEstimation}
            >Payment Estimation</Button>
        </Col>
    );
}
