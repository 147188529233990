// @flow
import type {PartnerMerchant} from "../../../Hooks/Api/useMerchantList";
import FlagBadge from "../../../GenericComponents/FlagBadge";
import Priority from "./Priority";
import PartnerMerchantViewTrafficStatus from "./PartnerMerchantViewTrafficStatus";
import {dateStringToLocale} from "../../../Helper/DateHelper";
import RetryPartnerMerchantEvaluation from "./RetryPartnerMerchantEvaluation";
import {useState} from "react";
import {usePageNotificationSystem} from "../../../Context/PageNotification";
import {useApiKey} from "../../../Context/ApiKey";
import {canBeRetried, isEvaluating} from "../../../Helper/ViewTrafficStatus";
import YieldAdsStatus from "./YieldAdsStatus";
import {defaultApiDataInactive, ResponseStatus} from "../../../Helper/Response";
import {handlePartnerMerchantPriorityEdit} from "../../../Helper/Api/Merchants/handlePartnerMerchantPriorityEdit";
import {
    handlePartnerMerchantViewTrafficDoubleClick
} from "../../../Helper/Api/Merchants/handlePartnerMerchantViewtrafficStatusClick";
import {
    handlePartnerMerchantYieldadsStatusClick
} from "../../../Helper/Api/Merchants/handlePartnerMerchantYieldadsStatusClick";
import {handlePartnerMerchantEvaluationClick} from "../../../Helper/Api/Merchants/handlePartnerMerchantEvaluationClick";
import {FormEvent} from "react";
import type {Account} from "../../../Hooks/Api/useAccountList";

type Props = {
  merchant: PartnerMerchant,
  accountMap: Map<Account>,
  trClassName?: string,
  isIgnoredList?: boolean,
};

export default function MerchantListRow(props: Props) {
  const {
    merchant,
    accountMap,
    trClassName = undefined,
    isIgnoredList = false,
  } = props;

  const [merchantState, setMerchantState] = useState<PartnerMerchant>(merchant);
  const [apiStatus, setApiStatus] = useState(defaultApiDataInactive);
  const notificationSystem = usePageNotificationSystem();
  const apiKey = useApiKey();

  const {
    domain,
    identifier: accountIdentifier,
    market,
    priority,
    partnerStatus,
    yieldadsStatus,
    viewTrafficStatus,
    viewTrafficStatusUpdateDate,
    whitelistStatus,
    whitelistCreatedDate,
  } = merchantState;

  const account = accountMap.has(accountIdentifier)
    ? <span title={accountIdentifier}>{accountMap.get(accountIdentifier).name}</span>
    : <code>{accountIdentifier}</code>;

  const pending = apiStatus.status === ResponseStatus.pending;

  const onPriorityEdit = (event: FormEvent<HTMLFormElement>, setEditable: (value: boolean) => void) => handlePartnerMerchantPriorityEdit(
      event,
      setEditable,
      apiStatus,
      setApiStatus,
      apiKey.value,
      notificationSystem.pushNotification,
      merchantState,
      setMerchantState,
  );
  const onViewTrafficDoubleClick= () => handlePartnerMerchantViewTrafficDoubleClick(
      apiStatus,
      setApiStatus,
      apiKey.value,
      notificationSystem.pushNotification,
      merchantState,
      setMerchantState,
  );

  const onYieldadsDoubleClick = () => handlePartnerMerchantYieldadsStatusClick(
      apiStatus,
      setApiStatus,
      apiKey.value,
      notificationSystem.pushNotification,
      merchantState,
      setMerchantState,
  );

  const onEvaluationClick = () => handlePartnerMerchantEvaluationClick(
      apiStatus,
      setApiStatus,
      apiKey.value,
      notificationSystem.pushNotification,
      merchantState,
      setMerchantState,
  );

  const renderedWhitelistStatus = isIgnoredList ? null : <td className={'text-center'}>
    <FlagBadge
      value={whitelistStatus}
      textTrue={'Whitelisted'}
      textFalse={'Disallowed'}
      title={`Created on: ${ whitelistCreatedDate !== null ? dateStringToLocale(whitelistCreatedDate) : 'k.A.' }`}
    />
  </td>;

  const renderedRetryEvaluationButton = isIgnoredList ? null : <td className={'text-center'}>
    <RetryPartnerMerchantEvaluation
      disabled={pending || !canBeRetried(viewTrafficStatus) || priority < 1}
      loading={pending}
      title={
        isEvaluating(viewTrafficStatus)
          ? 'Der Merchant wartet bereits auf eine Evaluierung' : 'Füge den Merchant zur Warteschlange hinzu'
      }
      queueForEvaluation={onEvaluationClick}
    />
  </td>;

  return (
    <tr className={trClassName}>
      <td>{domain}</td>
      <td>{market}</td>
      <td>{account}</td>
      <td style={{width: '6em', maxWidth: '6em'}} className={'text-end'}>
        <Priority
            priority={priority}
            pending={pending}
            updatePriority={onPriorityEdit}
            isEditable={!isIgnoredList}
        />
      </td>
      <td className={'text-center'}>
        <FlagBadge
          value={partnerStatus}
          textTrue={'Aktiv'}
          textFalse={'Inaktiv'}
        />
      </td>
      <td className={'text-center'}>
        <YieldAdsStatus
            yieldadsStatus={yieldadsStatus}
            pending={pending}
            toggleStatus={onYieldadsDoubleClick}
            isEditable={!isIgnoredList}
        />
      </td>
      <td className={'text-center'}>
        <PartnerMerchantViewTrafficStatus
          viewTrafficStatus={viewTrafficStatus}
          updatedDate={viewTrafficStatusUpdateDate}
          pending={pending}
          toggleStatus={onViewTrafficDoubleClick}
          isEditable={!isIgnoredList}
        />
      </td>

      {renderedWhitelistStatus}
      {renderedRetryEvaluationButton}

    </tr>
  );
}
