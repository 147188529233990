// @flow
import {Col, Row} from "react-bootstrap";
import Navigation from "./Navigation/Navigation";
import {useLocation} from "react-router-dom";
import {useTabTitle} from "./Context/TabTitle";
import YieldAdsRoutes from "./Routes/YieldAdsRoutes";
import AuthenticationGuard from "./Routes/Authentication/AuthenticationGuard";

type Props = {};

// Um den title auf den Standard zurückzusetzen, falls eine Route den title nicht setzt
function TitleDefault() {
  // Um ein erneutes Rendern zu bewirken, wenn die Seite gewechselt wird
  useLocation();
  useTabTitle('');

  return null;
}

export default function Layout(props: Props) {
  return (
    <>
      <TitleDefault/>

      <Col style={{width: '100vw', height: '100vh'}}>
        <Row style={{width: '100vw', height: '100vh', marginLeft: '0', marginRight: '0'}}>
          <AuthenticationGuard>
            <Col xs={'auto'} className={'px-0 m-0 fade-in-fast'} style={{maxHeight: '100vh', overflowY: 'auto'}}>
              <Navigation/>
            </Col>

            <Col className={'p-4 fade-in-fast'} style={{maxHeight: '100vh', overflowY: 'auto'}}>
              <YieldAdsRoutes/>
            </Col>
          </AuthenticationGuard>
        </Row>
      </Col>
    </>
  );
}
