// @flow
import './DecoratedOption.css';
import {Variant} from "react-bootstrap/types";

type Props = {
  value: string | number,
  className?: string,
  variant?: Variant | 'primary-bright' | 'secondary-bright' | 'danger-bright' | 'success-bright' | 'warning-bright' | 'info-bright' | 'dark-bright' | 'light-bright',
  fontStyle?: 'regular' | 'italic' | 'bold',
  children: React.ReactNode,
};

function buildFontStyleClass(fontStyle): string {
  if (fontStyle === null) {
    return '';
  }

  switch (fontStyle) {
    case 'regular':
      return '';
    case 'bold':
      return 'decoration-bold';
    case 'italic':
      return 'fst-italic';

    default:
      return `fst-${fontStyle}`;
  }
}

export default function DecoratedOption(props: Props) {
  const {
    value,
    className = '',
    variant = null,
    fontStyle = null,
    children,
  } = props;

  let classes = `decorated-option ${className} ${buildFontStyleClass(fontStyle)}`;

  if (variant !== null) {
     classes += ` ${variant}`;
  }

  return (
    <option value={value} className={classes}>{children}</option>
  );
}
