// @flow
import {Card} from "react-bootstrap";
import Separator from "../../../GenericComponents/Separator";

type Props = {};

export default function SeparatorDebug(props: Props) {
  return (
    <Card className={'mt-4'}>
      <Card.Header>
        <Card.Title as={'h4'}>Separator</Card.Title>
      </Card.Header>
      <Card.Body>
        <Separator>Heading</Separator>
      </Card.Body>
    </Card>
  );
}
