// @flow
import NotificationDebug from "./Notification/NotificationDebug";
import SelectDebug from "./Select/SelectDebug";
import SeparatorDebug from "./Separator/SeparatorDebug";
import ModalDebug from "./Modal/ModalDebug";

type Props = {};

export default function DebugComponents(props: Props) {
  return (
    <>
      <NotificationDebug/>
      <SelectDebug/>
      <SeparatorDebug/>
      <ModalDebug/>
    </>
  );
}
