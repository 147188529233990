// @flow
import {usePageNotificationSystem} from "../../../Context/PageNotification";
import {useState} from "react";
import {Button} from "react-bootstrap";
import NotificationToast from "../../../GenericComponents/NotificationToast";
import {uppercaseFirst} from "../../../Helper/StringHelper";

type Props = {};

const colorList = [
  'red',
  'salmon',
  'crimson',

  'hotpink',
  'deeppink',

  'coral',
  'tomato',
  'orange',

  'gold',
  'yellow',
  'khaki',

  'plum',
  'lavender',
  'indigo',

  'lime',
  'olive',
  'teal',

  'cyan',
  'navy',

  'cornsilk',
  'bisque',
  'wheat',
  'peru',
  'chocolate',
  'maroon',

  'azure',
  'honeydew',
  'ivory',
  'linen',

  'silver',
];

const shuffleColor = (currentColor: ?string) => {
  let newColor = null;

  while (newColor === null || newColor === currentColor) {
    const index = Math.floor(Math.random() * colorList.length);
    newColor = colorList[index] || null;
  }

  return newColor;
};

function DedicatedToast() {
  const [color, setColor] = useState<number>(() => shuffleColor(null));

  return (
    <>
      <div className={'mb-2'}>This is the color "{uppercaseFirst(color)}":</div>
      <div className={'p-3 mb-2'} style={{backgroundColor: color, borderRadius: '.5em'}}/>
      <Button size={'sm'} onClick={() => setColor((old) => shuffleColor(old))}>Randomize</Button>
    </>
  );
}

export default function DedicatedComponentNotification(props: Props) {
  const notificationSystem = usePageNotificationSystem();

  return (
    <Button
      onClick={() => {
        notificationSystem.pushNotification(
          <NotificationToast id={'debug-click-color-dedicated'} title={'Dedicated Toast Component'}>
            <DedicatedToast/>
          </NotificationToast>
        );
      }}
    >Random Color</Button>
  );
}
