// @flow
import React, {useState} from 'react';
import {Button, Card, Col, Row} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {useTabTitle} from "../../Context/TabTitle";
import Spooky from "./Spooky";
import {useBackendDepths} from "../../Hooks/useBackendDepths";

type Props = {};

export default function Error404(props: Props) {
  const [unlocked, setUnlocked] = useState<boolean>(false);
  const [venturedDeeper, setVenturedDeeper] = useState<boolean>(false);

  const {pathname} = useLocation();
  useTabTitle('Page not found');
  const depthState = useBackendDepths();

  const peekIntoTheDepth = () => {
    if (!venturedDeeper) {
      depthState.ventureDeeper();
    }

    setVenturedDeeper(true);
    setUnlocked((old) => !old);
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title as={'h4'}>
            404 – Page Not Found
          </Card.Title>
        </Card.Header>

        <Card.Body>
          <Row>
            <Col sm={'12'}>
              This page (<code>{pathname}</code>) does not exist.
            </Col>
          </Row>

          <Row className={'mt-4'}>
            <Col sm={'12'}>
              {/*Du hast es geschafft, eine Seite zu finden, die es nicht gibt. "Conlaturation"*/}

              Vor dir liegen die <strong>Untiefen des Backends</strong>.
              Niemand hat es je geschafft, in die Tiefen vorzudringen.
              Oder hat es einfach niemand geschafft, wieder zu entkommen,
              um Geschichten von diesem Ort zu erzählen?
            </Col>
          </Row>

          <Row className={'mt-4'}>
            <Col sm={'auto'}>
              <Link to={'/dashboard'}>
                <Button variant={'secondary'} size={'sm'}>
                  Zurück zum Dashboard
                </Button>
              </Link>
            </Col>
            <Col sm={'auto'}>
              <Button
                variant={!unlocked ? 'dark' : 'danger'}
                size={'sm'}
                className={!unlocked ? 'text-secondary' : ''}
                onClick={peekIntoTheDepth}
              >
                {!unlocked
                  ? 'Es wagen, in die finst\'ren Tiefen vorzudringen'
                  : 'Das ist zu gruselig. Zurück an die Oberfläche kriechen'
                }
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {unlocked &&
        <Card className={'mt-4'}>
          <Card.Body>
            <Spooky depthState={depthState}/>
          </Card.Body>
        </Card>
      }
    </>
  );
}
