// @flow
import type {FormData} from "./PostbackFormTypes";
import {FormDataKeys} from "./PostbackFormTypes";

/**
 * Tauschen von Parameter Name und Value für die Form
 */
export const transformObject = (obj: { [string]: string }) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      const newKey = value.replace(/^\{|}$/g, ''); // Remove curly braces
      return [newKey, key];
    })
  );
};

/**
 * Url aus der Form generieren, incl. beachten von custom Parametern in der BASE_URL
 */
export const generateUrl = (formData: FormData) => {
  if (!formData.BASE_URL) {
    return '';
  }

  let baseUrl = formData.BASE_URL;
  const queryParams = new URLSearchParams(
    Object.entries(formData).reduce((acc, [key, value]) => {
      if (!(key in FormDataKeys)) {
        baseUrl += `?${value}=${key}`;
        return;
      }
      if (value && key !== 'BASE_URL') {
        acc[value] = `{${key}}`
      }
      return acc;
    }, {})
  ).toString();

  const baseUrlHasParams = baseUrl.includes('?');
  const requiredQuestionMark = baseUrlHasParams ? '' : '?';
  const requiredAmpersand = (baseUrl.endsWith('&') || !baseUrlHasParams) ? '' : '&';

  let url = baseUrl + requiredQuestionMark + requiredAmpersand + queryParams;
  url = url.replace(/%7B/g, '{').replace(/%7D/g, '}');
  return url;
};

/**
 * Existierende Url lesen und aufteilen in baseUrl und formParameters
 * baseUrl beinhaltet customParameters
 */
export const readPrevUrl = (prevUrl: string) => {
  const url = new URL(prevUrl);
  const rawParameters = Object.fromEntries(url.searchParams.entries());
  const transformedParameters = transformObject(rawParameters)
  let formParameters = {}
  let customParameters = {}
  for (const parameter in transformedParameters) {
    const key = transformedParameters[parameter]
    if (parameter in FormDataKeys) {
      formParameters[parameter] = key
    } else {
      customParameters[key] = parameter
    }
  }
  const customParams = new URLSearchParams(customParameters)

  const requiredQuestionMark = customParams.toString() ? '?' : '';

  const baseUrl = url.origin + url.pathname + requiredQuestionMark + customParams.toString()

  return {baseUrl, formParameters}
}
