// @flow

import {fetchApiPatch, OnApiErrorCallback} from "../fetchApi";
import {createResponse, ResponseStatus, UseApiResponseType} from "../../Response";
import type {UpdatePropertyFunction} from "./updateProperty";

export const updateMerchantsStatus: UpdatePropertyFunction = async (
  merchantId: string,
  newStatus: boolean,
  apiKey: string,
  pushNotification: ?Function = null,
  onError: ?OnApiErrorCallback = null,
): UseApiResponseType<any> => {
  const body = {
    status: newStatus ? 1 : 0,
  }

  try {
    const rawResponse = await fetchApiPatch(`/merchants/${merchantId}/status`, apiKey, body, null,  null, pushNotification, onError);
    return createResponse(
      rawResponse,
      ResponseStatus.ready,
      null,
    );
  } catch (error) {
    return createResponse(
      null,
      ResponseStatus.error,
      error.message,
    );
  }
}
