// @flow

import {createContext, useContext} from "react";
import {Variant} from "react-bootstrap/types";

export type PageAlert = {
  id: string,
  variant: Variant,
  title: ?string,
  content: React.ReactNode,
}

export type PageAlerts = {
  [string]: PageAlert,
};

export type PageAlertContextType = {
  alerts: PageAlerts,
  pushAlert: Function,
  clearAll: Function,
  clear: Function,
}

export const PageAlertContext = createContext(null);

export function usePageAlert(): PageAlertContextType {
  return useContext(PageAlertContext);
}
