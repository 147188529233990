// @flow

export type Props = {
  publisherId: string,
  parsedBody: Object,
};

export const FormDataKeys = {
  BASE_URL: 'BASE_URL',
  EVENT_ID: 'EVENT_ID',
  ADVERTISER_ID: 'ADVERTISER_ID',
  COMMISSION_ID: 'COMMISSION_ID',
  COMMISSION: 'COMMISSION',
  MODIFIED_DATE: 'MODIFIED_DATE',
  SUB_ID: 'SUB_ID',
  SUB_ID2: 'SUB_ID2',
  EVENT_TYPE: 'EVENT_TYPE',
  STATE: 'STATE',
  CLICK_DATE: 'CLICK_DATE',
  CURRENCY: 'CURRENCY',
  MARKET: 'MARKET',
  MERCHANT_NAME: 'MERCHANT_NAME'
};

type FormDataKey = $Keys<typeof FormDataKeys>;

export type FormData = {
  [FormDataKey]: string
};

export type Errors = {
  BASE_URL?: string;
  EVENT_ID?: string,
  ADVERTISER_ID?: string,
  COMMISSION_ID?: string,
  COMMISSION?: string,
  MODIFIED_DATE?: string,
  SUB_ID?: string,
  SUB_ID2?: string,
  EVENT_TYPE?: string,
  STATE?: string,
  CLICK_DATE?: string,
  CURRENCY?: string,
  MARKET?: string,
  MERCHANT_NAME?: string,
};

export type RowProps = {
  label: string,
  name: string,
  value: string,
  error: string,
  information: string,
  onChange: (name: string, value: string) => void,
};
