// @flow

import {useApiGet} from "./useApi";
import {UseApiResponseType} from "../../Helper/Response";
import type {OnApiErrorCallback} from "../../Helper/Api/fetchApi";
import type {Publisher} from "../../Routes/Publisher/Publisher";

export function usePublisherDetails(publisherId: string, onError: ?OnApiErrorCallback = null): UseApiResponseType<Publisher> {
  return useApiGet<Publisher>(`/publishers/${publisherId}`, null, onError);
}
