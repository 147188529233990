// @flow
import type {UseApiResponseType} from "../../../Helper/Response";
import type {Project, ProjectList} from "../../../Hooks/Api/useProjectList";
import {ResponseStatus} from "../../../Helper/Response";
import {Spinner, Table} from "react-bootstrap";
import ProjectsListRow from "./ProjectsListRow";
import {useTheme} from "../../../Context/Theme";

type Props = {
  projectList: UseApiResponseType<ProjectList>,
};

type RowsProps = {
  projectList: UseApiResponseType<ProjectList>,
};

function Rows(props: RowsProps) {
  const {projectList} = props;

  switch (projectList.status) {
    case ResponseStatus.pending:
      return (
        <tr>
          <td rowSpan={2} colSpan={1} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
          <td rowSpan={2} colSpan={3} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
          <td rowSpan={2} colSpan={1} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
        </tr>
      );
    case ResponseStatus.ready:
      if (projectList.response.statusCode !== 200) {
        return null;
      }

      return (
        <>
          {projectList.response.parsedBody.map((project: Project) => {
            return (
              <ProjectsListRow
                key={project.projectId}
                project={project}
              />
            );
          })}
        </>
      );
    case ResponseStatus.error:
      return null;

    default:
      return null;
  }
}

export default function ProjectsListTable(props: Props) {
  const {projectList} = props;

  const {theme} = useTheme();

  return (
    <Table striped variant={theme.type} hover>
      <thead>
      <tr>
        <td>Name</td>
        <td>Project&nbsp;ID</td>
        <td>Accounts</td>
        <td>Redirect Url</td>
        <td>Actions</td>
      </tr>
      </thead>
      <tbody>
      <Rows projectList={projectList}/>
      </tbody>
    </Table>
  );
}
