// @flow
import {Card, Col, Row, Spinner} from "react-bootstrap";
import React from "react";

type Props = {};

export default function InitialKeyCheck(props: Props) {
  return (
    <Col className={'p-4 fade-in-delayed'} style={{maxHeight: '100vh', overflowY: 'scroll'}}>
      <Card>
        <Card.Header>
          <Card.Title>Verifying API-Key</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              The API-Key is currently being verified. This should be very quick, and you should not see this!
            </Col>
          </Row>

          <Row className={'mt-4'}>
            <Col>
              <Spinner animation={'grow'}/>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}
