// @flow
import {BiSortAZ, BiSortZA} from "react-icons/bi";
import {GrUnsorted} from "react-icons/gr"
import {useCallback} from "react";
import type {SortingType} from "../Helper/Sorting";
import {reverseDirection} from "../Helper/Sorting";

type Props = {
  children: React.ReactNode,
  iconPosition?: 'l' | 'r',
  value: string,
  sorting: SortingType,
  setSorting: Function,
  className?: string,
  title?: string,
};

export default function SortableTableHeaderCell(props: Props) {
  const {
    children,
    iconPosition = 'r',
    value,
    sorting,
    setSorting,
    className = '',
    title = undefined,
  } = props;

  let icon = <GrUnsorted/>;

  if (sorting.field === value) {
    icon = sorting.direction === 'asc'
      ? <BiSortAZ className={'mx-2'}/>
      : <BiSortZA className={'mx-2'}/>;
  }

  let left;
  let right;

  if (iconPosition === 'l') {
    left = icon;
    right = children;
  } else {
    left = children;
    right = icon;
  }

  const onClick = useCallback(() => {
    const wasSortedByThis = sorting.field === value;
    const newSorting: SortingType = {
      field: value,
      direction: wasSortedByThis ? reverseDirection(sorting.direction) : 'asc',
    };

    setSorting(newSorting);
  }, [value, sorting, setSorting]);

  return (
    <th className={'cursor-pointer no-select ' + className} onClick={onClick} title={title}>
      {left}
      {right}
    </th>
  );
}
