// @flow
import SaveButton from "./SaveButton";
import type {AllowedAccounts} from "../../../Hooks/Api/usePublisherAllowedAccountsList";
import {useState} from "react";
import {Col, Row, Spinner} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {useApiKey} from "../../../Context/ApiKey";
import {updatePublisherAllowedAccounts} from "../../../Helper/Api/Publisher/UpdatePublisherAllowedAccounts";
import {usePageNotificationSystem} from "../../../Context/PageNotification";

type Props = {
  publisherId: string,
  updatedAccountsData: AllowedAccounts,
  isPending: boolean,
  setPending: (boolean) => void,
};

export default function SaveButtons(props: Props) {
  const {
    publisherId,
    updatedAccountsData,
  } = props;

  const navigate = useNavigate();
  const apiKey = useApiKey();
  const {pushNotification} = usePageNotificationSystem();

  const [pending, setPending] = useState<boolean>(false);

  const updateAndBack = async () => {
    setPending(true);

    const allowedIdentifiers = collectAllowedIdentifiers(updatedAccountsData);
    await updatePublisherAllowedAccounts(publisherId, allowedIdentifiers, apiKey.value, pushNotification);

    setPending(false);
    navigate(-1);
  };

  const back = async () => {
    navigate(-1);
  }

  return (
    <Row>
      <Col className={'d-flex justify-content-end'}>
        {pending && <div className={'fade-in-delayed-short'}><Spinner/></div>}

        <SaveButton
          onClick={back}
          variant={"secondary"}
          disabled={pending} // Damit man potentielle API-Fehler nicht übersieht
        >
          Abbrechen
        </SaveButton>

        <SaveButton
          onClick={updateAndBack}
          disabled={pending}
        >
          Übernehmen
        </SaveButton>
      </Col>
    </Row>
  );
}

function collectAllowedIdentifiers(accountsData: AllowedAccounts) {
  const collectedIdentifiers = [];

  for (const partnerData of Object.values(accountsData)) {
    for (const accounts of Object.values(partnerData)) {
      for (const account of accounts) {
        const {identifier, allowed} = account;
        if (allowed) {
          collectedIdentifiers.push(identifier);
        }
      }
    }
  }

  return collectedIdentifiers;
}
