// @flow
import {Badge, Col, Row} from "react-bootstrap";
import type {DepthState} from "../../Hooks/useBackendDepths";

type Props = {
  depthState: DepthState,
};

type StoryChunk = {
  requiredDepth: number,
  plot: React.ReactNode,
}

const story: Array<StoryChunk> = [
  {
    requiredDepth: 1,
    plot: <p>
      Du wagst dich in die <em>Untiefen</em> hinein, ohne zu erahnen, was dich erwarten würde.
      Du verlierst deinen Orientierungssinn, zusammen mit dem Zeitgefühl.
      Die Umgebung scheint dunkel zu sein, gar schwärzer als die Nacht. Nichts ist zu erkennen.
      Vielleicht Augen müssen sich erst an die Dunkelheit gewöhnen?
      Doch auch nach einer unbekannten, doch langen Zeit gibt der Ort nichts von sich preis.
      Es scheint sogar, als würde es sogar noch finsterer.
      Dieser Ort will scheinbar nicht gefunden werden.
    </p>,
  },
  {
    requiredDepth: 2,
    plot: <p>
      Es scheint ausweglos. Du irrst im Unbekannten umher, stolperst einige Male über Unebenheiten im Boden.
      Dieser fühlt sich seltsam an. Woraus besteht er?
      Eindeutig nicht aus einem herkömmlichen Material. Er fühlt sich… kurios an.
      Einige Stellen sind rutschig, gar glibberig.
      Deine Fantasie wagt es kaum, auch nur Hypothesen anzustellen, was es sein könnte.
      Womöglich ist es nur das pilzreiche Wurzelwerk eines schier endlosen Dickichts.
      <small><small><small>&nbsp;&nbsp;&nbsp;Ganz bestimmt!&nbsp;&nbsp;&nbsp;<small><small>Oder?</small></small></small></small></small>
    </p>,
  },
  {
    requiredDepth: 3,
    plot: <p>
      Nach endlosem blinden Wandern spürst du etwas vor dir.
      Obwohl nichts zu sehen ist, ist eindeutig ein Objekt zu vernehmen.
      Es ist, als würde etwas eine Wärme abstrahlen, die nur unterbewusst wahrnehmbar ist.
      Doch was könnte in dieser undurchdringbaren Dunkelheit nur so plötzlich auftauchen?
    </p>,
  },
  {
    requiredDepth: 4,
    plot: <p>
      Nicht nur war die Wärme so unvermittelt da, sie vermittelt auch ein Gefühl der Geborgenheit.
      Du versuchst, dich der Wärme zu nähern, bis du auf eine Wand stößt.
      Keine von Menschenhand geschaffene, sondern eine massive, natürliche steinerne Bergwand.
      Sie ist es, die diese kuriose Art von Wärme abgibt.
      Deine Neugierde zwingt dich, an ihr entlangzugehen.
      Sie erstreckt sich über eine große Distanz, bis du einen Eingang in den Berg erahnen kannst.
    </p>,
  },
  {
    requiredDepth: 5,
    plot: <p>
      Von Forschungsdrang gepackt wagst du dich in die Kaverne hinein.
      Obwohl die Finsternis bereits allgegenwärtig ist,
      so scheint dieser Ort noch den allerletzten Rest der Zuversicht zu entziehen.
      Folglich tastest du dich an der Höhlenwand entlang, um wenigstens ein kleines Gefühl der Orientierung zu haben.
      In halbwegs regelmäßigen Abständen befinden sich Einkerbungen in der Wand.
      Zweifelsohne sind diese nicht natürlichen Ursprungs.
      Wer könnte sie hier hinterlassen haben?
    </p>,
  },
  // {
  //   requiredDepth: 6,
  //   plot: <p>
  //     Refugium eines alten Kults. To Do
  //   </p>,
  // },
];

function collectStory(depth: number): Array<React.ReactNode> {
  const result = [];

  for (const storyChunk of story) {
    if (depth >= storyChunk.requiredDepth) {
      result.push(<div key={storyChunk.requiredDepth}>{storyChunk.plot}</div>);
    }
  }

  return result;
}

export default function Spooky(props: Props) {
  const {depthState} = props;

  return (
    <>
      <article>
        {collectStory(depthState.depth)}
        <p>
          Du bist dir nicht sicher, ob du tiefer hinabsteigen möchtest.
          Für den Moment traust du dich nicht und kehrst zurück.</p>
      </article>

      <hr/>

      <Row>
        <Col>
          <span>Wie unheimlich.</span>
          <strong className={'ms-1 cursor-pointer decoration-underline'} onClick={depthState.potionOfUnknowing}>
            Lethe trinken
          </strong>
        </Col>
        <Col className={'text-end'}>
          <Badge bg={'dark'}>{depthState.depth}</Badge>
        </Col>
      </Row>
    </>
  );
}
