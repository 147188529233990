// @flow
import {createResponse, defaultApiDataInactive, ResponseStatus, UseApiResponseType} from "../../../Helper/Response";
import FlagBadge from "../../../GenericComponents/FlagBadge";
import {useState} from "react";
import {useApiKey} from "../../../Context/ApiKey";
import {usePageNotificationSystem} from "../../../Context/PageNotification";
import {isEvaluating, isViewTrafficActive, viewTrafficStatusCode, viewTrafficStatusToName} from "../../../Helper/ViewTrafficStatus";
import type {ViewTrafficStatusCode} from "../../../Helper/ViewTrafficStatus";
import {dateStringToLocale} from "../../../Helper/DateHelper";

type Props = {
  initialStatus: boolean,
  updatedDate: string,
  domain: string,
  merchantId: string,
  updateApi: (id: string, newStatus: ViewTrafficStatusCode) => Promise<UseApiResponseType<any>>,
};

export default function GenericViewTrafficStatus(props: Props) {
  const {initialStatus, updatedDate, domain, merchantId, updateApi} = props;

  const [viewTrafficStatusOverride, setViewTrafficStatusOverride] = useState<boolean>(initialStatus);
  const [viewTrafficStatusChange, setViewTrafficStatusChange]     = useState(defaultApiDataInactive);

  const notificationSystem = usePageNotificationSystem();
  const apiKey = useApiKey();

  const toggleViewTrafficStatus = () => {
    // Ignorieren, wenn bereits ein Request läuft
    if (viewTrafficStatusChange.status === ResponseStatus.pending) {
      return;
    }

    if (isEvaluating(viewTrafficStatusOverride)) {
      if (!window.confirm(
        'Dieser Partner Merchant befindet sich in Evaluierung.'
          + '\nWenn Sie den Status ändern, wird die Evaluierung beendet.\nMöchten Sie fortfahren?'
        )) {
        return;
      }
    }

    (async () => {
      const isCurrentlyActive = isViewTrafficActive(viewTrafficStatusOverride);
      const oldStaus  = viewTrafficStatusOverride;
      const newStatus = isCurrentlyActive ? viewTrafficStatusCode.disabledByBackend : viewTrafficStatusCode.enabledByBackend;
      setViewTrafficStatusOverride(newStatus);

      setViewTrafficStatusChange(createResponse(
        null,
        ResponseStatus.pending,
        null,
      ));

      const response = await updateApi(
        merchantId,
        newStatus,
        apiKey.value,
        notificationSystem.pushNotification,
        () => <>Der View-Traffic Status für den Merchant "<span className={'decoration-underline'}>{domain}</span>" konnte nicht aktualisiert werden</>,
      );

      setViewTrafficStatusChange(response);

      if (response.status === ResponseStatus.error || response.response?.statusCode !== 200) {
        setViewTrafficStatusOverride(oldStaus);
      }
    })();
  };

  return (
    <FlagBadge
      value={isViewTrafficActive(viewTrafficStatusOverride)}
      textForce={viewTrafficStatusToName(viewTrafficStatusOverride)}
      variantForce={viewTrafficStatusChange.status === ResponseStatus.pending ? 'warning' : null}

      title={`Last updated: ${dateStringToLocale(updatedDate)}`}

      onDoubleClick={toggleViewTrafficStatus}
    />
  );
}
