// @flow

export type SortingDirection = 'asc' | 'desc';
export type SortingType = {
  field: ?string,
  direction: SortingDirection,
}

export function reverseDirection(direction: SortingDirection): SortingDirection {
  return direction === 'asc' ? 'desc' : 'asc';
}
