// @flow
import {Badge} from "react-bootstrap";

type Props = {
  value: boolean,
  textTrue?: string | React.ReactNode,
  textFalse?: string | React.ReactNode,
  textForce?: ?string | ?React.ReactNode,
  variantTrue?: string,
  variantFalse?: string,
  variantForce?: ?string,
  title?: string,
  onClick?: ?Function,
  onDoubleClick?: ?Function,
};

export default function FlagBadge(props: Props) {
  const {
    value,
    textTrue = 'true',
    textFalse = 'false',
    textForce = null,
    variantTrue = 'success',
    variantFalse = 'danger',
    variantForce = null,
    title = undefined,
    onClick = undefined,
    onDoubleClick = undefined,
  } = props;

  const variantToUse = variantForce || (value ? variantTrue : variantFalse);
  const textToUse    = textForce || (value ? textTrue : textFalse);

  return (
    <Badge
      bg={variantToUse}
      className={`no-select ${(onClick || onDoubleClick) ? 'cursor-pointer' : ''}`}
      title={title}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      {textToUse}
    </Badge>
  );
}
