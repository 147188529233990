// @flow

import {useApiGet} from "./useApi";
import {ResponseStatus, UseApiResponseType} from "../../Helper/Response";
import {useMemo} from "react";
import type {OnApiErrorCallback} from "../../Helper/Api/fetchApi";

export type Account = {
  identifier: number,
  name: string,
  partner: string,
  market: string,
  viewTrafficFactor: number,
  projects: Array<ReducedFrontendProject>,
};

export type ReducedFrontendProject = {
  projectId: string,
  name: string,
};

export type AccountList = Array<Account>;

export function useAccountList(onError: ?OnApiErrorCallback = null): UseApiResponseType<AccountList> {
  return useApiGet<AccountList>('/accounts', null, onError);
}

export function useAccountIdMapping(onError: ?OnApiErrorCallback = null): Map<Account> {
  const accountList = useAccountList(onError);

  return useMemo(() => {
    if (
      accountList.status === ResponseStatus.ready &&
      accountList.response.statusCode === 200
    ) {
      return buildAccountIdMap(accountList.response.parsedBody);
    }

    return new Map();
  }, [accountList]);
}

function buildAccountIdMap(accounts: AccountList): Map<Account> {
  const map = new Map();

  for (const account of accounts) {
    map.set(account.identifier, account);
  }

  return map;
}
