// @flow

import {Col} from "react-bootstrap";
import SelectSearch from "react-select-search";
import {useApiGetState} from "../../../../Hooks/Api/useApi";
import {useState} from "react";

type Props = {
  selectedPublisherId: string,
  setSelectedPublisherId: Function,
  setReloadMerchantList: Function,
};

export default function PublisherFilter(props: Props) {
  const {
    selectedPublisherId,
    setSelectedPublisherId,
    setReloadMerchantList,
  } = props;

  const [publishers, setPublishers] = useState<Array<Object>>([]);

  useApiGetState('/publishers', { 'format': 'filter'}, setPublishers,() => {
    setPublishers([]);
  });

  // Wenn man einen "alle" Publisher block Modus einbauen will.
  // if (publishers[0] && publishers[0].value !== 'all') {
  //   publishers.unshift({'name': 'alle Publisher', 'value': 'all'});
  // }

  return (
    <Col className={'mb-4'}>
      <SelectSearch
        style={{display:"inline-block"}}
        name="merchantNameSelectSearch"
        placeholder="Publisher auswählen"
        search={false}
        options={publishers}
        onChange={publisherId => {
          setSelectedPublisherId(publisherId);
          setReloadMerchantList((old: number) => old + 1);
        }}
        defaultValue={'all'}
        value={selectedPublisherId}
      />
    </Col>
  );
}
