// @flow
import {useCallback, useEffect, useState} from "react";
import {defaultTitle, TabTitleContext} from "../TabTitle";
import type {TabTitleContextType} from "../TabTitle";

type Props = {
  children: React.ReactNode,
};

export default function TabTitleContextWrapper(props: Props) {
  const [tabTitle, setTabTitle] = useState<string>(defaultTitle);
  const tabTitleContextValue: TabTitleContextType = {
    value: tabTitle,
    setTitle: useCallback((newTitle: string = '') => {
      const trimmedTitle = newTitle.trim();
      setTabTitle(trimmedTitle);
    }, [setTabTitle]),
  }

  useEffect(() => {
    if (tabTitle === '') {
      document.title = 'Yieldads';
      return;
    }

    document.title = `${tabTitle} – Yieldads`;
  }, [tabTitle]);

  return (
    <TabTitleContext.Provider value={tabTitleContextValue}>
      {props.children}
    </TabTitleContext.Provider>
  );
}
