// @flow
import type {AllowedAccounts} from "../../../Hooks/Api/usePublisherAllowedAccountsList";
import {Col, Form, Row} from "react-bootstrap";
import {useMemo} from "react";
import {partnerAbbreviationToFullName} from "../../../Helper/Partner";
import TimedTextInput from "../../../GenericComponents/Input/TimedTextInput";

type Props = {
  accountsData: AllowedAccounts,
  currentMarket?: ?string,
  currentPartner?: ?string,
  currentAccountName?: ?string,
  onMarketChange?: ?Function<string>,
  onPartnerChange?: ?Function<string>,
  onAccountNameChange?: ?Function<string>,
};

export default function FilterControls(props: Props) {
  const {
    accountsData,
    currentMarket = null,
    currentPartner = null,
    currentAccountName = null,
    onMarketChange = () => {},
    onPartnerChange = () => {},
    onAccountNameChange = () => {},
  } = props;

  const {markets, partners} = useMemo(() => {
    return collectMarketsAndPartners(accountsData);
  }, [accountsData]);

  const marketOptions = useMemo(() => {
    const options = [];
    options.push(<option key={'any'} value={'any'}>Alle Märkte</option>);

    for (const market of markets) {
      options.push(<option key={market} value={market}>{market.toUpperCase()}</option>);
    }

    return options;
  }, [markets]);

  const partnerOptions = useMemo(() => {
    const options = [];
    options.push(<option key={'any'} value={'any'}>Alle Partner</option>);

    for (const partner of partners) {
      options.push(<option key={partner} value={partner}>{partnerAbbreviationToFullName(partner)}</option>);
    }

    return options;
  }, [partners]);

  return (
    <Row>
      <Col md={"auto"} className={'mb-2'}>
        <Form.Select
          name={'market'}
          size={'sm'}
          value={currentMarket || 'any'}
          onChange={(event) => onMarketChange(anyToNull(event.target.value))}
        >
          {marketOptions}
        </Form.Select>
      </Col>
      <Col md={"auto"} className={'mb-2'}>
        <Form.Select
          name={'partner'}
          size={'sm'}
          value={currentPartner || 'any'}
          onChange={(event) => onPartnerChange(anyToNull(event.target.value))}
        >
          {partnerOptions}
        </Form.Select>
      </Col>
      <Col md={"auto"} className={'mb-2'}>
        <TimedTextInput
          name={'account-name'}
          size={'sm'}
          defaultValue={currentAccountName}
          placeholder={'Account-Name'}
          onChange={onAccountNameChange}
        />
      </Col>
    </Row>
  );
}

type MarketsPartners = {
  markets: Set<string>,
  partners: Set<string>,
}

function collectMarketsAndPartners(accountsData: AllowedAccounts): MarketsPartners {
  const markets = Object.keys(accountsData);

  const partnersSet = new Set<string>([]);
  for (const partners of Object.values(accountsData)) {
    for (const partner in partners) {
      partnersSet.add(partner);
    }
  }

  return {
    markets: new Set<string>(markets),
    partners: partnersSet,
  }
}

function anyToNull(input: ?string): ?string {
  if (input === 'any') {
    return null;
  }

  return input;
}
