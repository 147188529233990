// @flow

import type {ThemeType} from "../Context/Theme";
import {defaultTheme, Themes} from "../Context/Theme";

export function writeApiKey(apiKey: string) {
  // Buffer.from(apiKey).toString('base64')
  localStorage.setItem('apiKey', btoa(apiKey));
}

export function clearApiKey() {
  localStorage.removeItem('apiKey');
}

export function readApiKey(): ?string {
  const key = localStorage.getItem('apiKey');

  if (key !== null) {
    try {
      return atob(key);
    } catch {
      clearApiKey();
    }
  }

  return null;
}

export function storeTheme(theme: ThemeType) {
  localStorage.setItem('themeName', theme.name);
}

export function readTheme() {
  const themeName = localStorage.getItem('themeName');

  return Themes[themeName] || defaultTheme;
}

export function storeAccountListLayout(layout: string) {
  localStorage.setItem('accountListLayout', layout);
}

export function storeProjectListLayout(layout: string) {
  localStorage.setItem('projectListLayout', layout);
}

export function readAccountListLayout(defaultLayout: string) {
  return localStorage.getItem('accountListLayout') || defaultLayout;
}

export function readProjectListLayout(defaultLayout: string) {
  return localStorage.getItem('projectListLayout') || defaultLayout;
}
