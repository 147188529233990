// @flow

import {useState} from "react";

const storageKey = 'backend-depth';

function loadDepth() {
  const raw = localStorage.getItem(storageKey) || '0';
  const parsed = parseInt(raw);

  if (isNaN(parsed)) {
    localStorage.removeItem(storageKey);
    return 0;
  }

  return parsed;
}

export type DepthState = {
  depth: number,
  ventureDeeper: Function,
  potionOfUnknowing: Function,
}

export function useBackendDepths(): DepthState {
  const [depth, setDepth] = useState<number>(loadDepth());

  return {
    depth: depth,
    ventureDeeper: () => setDepth((old) => {
      const newDepth = old + 1;
      localStorage.setItem(storageKey, newDepth)

      return newDepth;
    }),
    potionOfUnknowing: () => {
      setDepth(0);
      localStorage.setItem(storageKey, 0)
    },
  }
}
