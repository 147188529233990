// @flow
import {Alert} from "react-bootstrap";
import {useState} from "react";

type Props = {
  title?: React.ReactNode,
  className?: string,
  onClose?: Function,
  children: React.ReactNode,
};

export default function ErrorBox(props: Props) {
  const {
    title = null,
    className = null,
    onClose = null,
    children,
    dismissible = true,
  } = props;

  const [dismissed, setDismissed] = useState<boolean>(false);

  if (dismissed) {
    return null;
  }

  const heading = title === null ? null : <Alert.Heading as={'h5'}>{title}</Alert.Heading>

  const onDismiss = () => {
    if (onClose) {
      onClose();
    }

    setDismissed(true);
  };

  return (
    <Alert className={`mb-3 ${className}`} variant={'danger'} dismissible={dismissible} onClose={onDismiss}>
      {heading}
      {children}
    </Alert>
  );
}
