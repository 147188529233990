// @flow
import YieldAdsAuthorityStatus from "./YieldAdsAuthorityStatus";
import YieldAdsMerchantPriority from "./YieldAdsMerchantPriority";
import type {YieldAdsMerchant} from "../../../Hooks/Api/useYieldAdsMerchantList";
import {useTheme} from "../../../Context/Theme";
import {CgListTree} from "react-icons/cg";
import {Button} from "react-bootstrap";
import YieldAdsMerchantViewTrafficStatus from "./YieldAdsMerchantViewTrafficStatus";
import FlagBadge from "../../../GenericComponents/FlagBadge";
import {dateStringToLocale} from "../../../Helper/DateHelper";

type Props = {
  merchant: YieldAdsMerchant,
  stripeClass: string,
  expanded: boolean,
  expand: (yieldadsMerchantId: string) => void,
  onPriorityChange: (yieldadsMerchantId: string) => void,
};

export default function YieldAdsMerchantListRow(props: Props) {
  const {merchant, stripeClass, expanded, expand, onPriorityChange} = props;
  const {
    yieldadsMerchantId,
    market,
    domain,
    priority,
    authorityYieldadsStatus,
    whitelistStatus,
    whitelistCreatedDate,
    viewTrafficStatus,
    viewTrafficStatusUpdateDate,
  } = merchant;

  const {theme} = useTheme();

  const classTextColor = theme.type === 'dark' ? 'text-secondary' : 'text-black';

  return (
    <tr className={stripeClass}>
      <td>
        <Button
          variant={`${stripeClass}-outline-inverse`}
          size={'sm'}
          className={`${expanded ? 'is-active' : ''} ${classTextColor}`}
          onClick={() => expand(expanded ? null : yieldadsMerchantId)}
          title={`Partner Merchants ${expanded ? 'verbergen' : 'anzeigen'}`}
        ><CgListTree/></Button>
      </td>
      <td>{domain}</td>
      <td>{market}</td>
      <td style={{width: '6em', maxWidth: '6em'}} className={'text-end'}>
        <YieldAdsMerchantPriority
          initialPriority={priority}
          domain={domain}
          yieldadsMerchantId={yieldadsMerchantId}
          onPriorityChange={onPriorityChange}
        />
      </td>
      <td className={'text-center'}>
        <YieldAdsAuthorityStatus
          initialStatus={authorityYieldadsStatus}
          domain={domain}
          yieldadsMerchantId={yieldadsMerchantId}
        />
      </td>
      <td className={'text-center'}>
        <YieldAdsMerchantViewTrafficStatus
          initialStatus={viewTrafficStatus}
          updatedDate={viewTrafficStatusUpdateDate}
          domain={domain}
          yieldAdsMerchantId={yieldadsMerchantId}
        />
      </td>
      <td className={'text-center'}>
        <FlagBadge
          value={whitelistStatus}
          textTrue={'Whitelisted'}
          textFalse={'Disallowed'}
          title={`Created on: ${ whitelistCreatedDate !== null ? dateStringToLocale(whitelistCreatedDate) : 'k.A.' }`}
        />
      </td>
    </tr>
  );
}
