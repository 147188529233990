// @flow
import {Card, Row, Table} from "react-bootstrap";
import {useTheme} from "../../../Context/Theme";
import "react-select-search/style.css"
import {useState} from "react";
import MerchantBlacklistListElements from "./MerchantBlacklistListElements";
import PublisherFilter from "./Filter/PublisherFilter";
import MerchantsFilter from "./Filter/MerchantsFilter";
import AddToBlacklistButton from "./AddToBlacklistButton";
import MarketFilter from "./Filter/MarketFilter";
import AccountsFilter from "./Filter/AccountsFilter";
import MultiErrorBox from "../../../GenericComponents/MultiErrorBox";
import {useSearchParams} from "react-router-dom";

type Props = {
  errors: Object,
  addError: Function,
  removeError: Function,
};

export default function MerchantBlacklistList(props: Props) {
  const {
    errors,
    addError,
    removeError,
  } = props;

  const {theme} = useTheme();

  let initialPublisherValue = null;

  const [query] = useSearchParams();

  if (query.get('publisher_id') !== null && query.get('publisher_id') !== undefined) {
    initialPublisherValue = query.get('publisher_id') || null;
  }

  const [selectedPublisherId, setSelectedPublisherId] = useState<string>(initialPublisherValue);
  const [selectedMerchantId, setSelectedMerchantId] = useState<string>('all');
  const [selectedMarket, setSelectedMarket] = useState<string>('all');
  const [selectedAccountId, setSelectedAccountId] = useState<string>('all');

  const [reloadMerchantList, setReloadMerchantList] = useState<number>(0);

  return (
    <Card>
      <Card.Body>
        <h4 className={'mb-4'}>Merchant Blacklist</h4>

        <MultiErrorBox errors={errors} title={'Fehler'} dismissible={false}/>

        <Card className={'mb-3'}>
          <Card.Body className={'pb-0'}>
            <Row xs={"auto"}>

              <PublisherFilter
                selectedPublisherId={selectedPublisherId}
                setSelectedPublisherId={setSelectedPublisherId}
                setReloadMerchantList={setReloadMerchantList}
              />

              <MerchantsFilter
                setSelectedMerchantId={setSelectedMerchantId}
                selectedMerchantId={selectedMerchantId}
                selectedAccountId={selectedAccountId}
                selectedMarket={selectedMarket}
                selectedPublisherId={selectedPublisherId}
                addError={addError}
                removeError={removeError}
              />

              <MarketFilter
                selectedMarket={selectedMarket}
                setSelectedMarket={setSelectedMarket}
                selectedMerchantId={selectedMerchantId}
                selectedAccountId={selectedAccountId}
              />

              <AccountsFilter
                selectedMerchantId={selectedMerchantId}
                selectedMarket={selectedMarket}
                selectedAccountId={selectedAccountId}
                setSelectedAccountId={setSelectedAccountId}
              />

              <AddToBlacklistButton
                selectedMerchantId={selectedMerchantId}
                selectedMarket={selectedMarket}
                selectedAccountId={selectedAccountId}
                selectedPublisherId={selectedPublisherId}
                setReloadMerchantList={setReloadMerchantList}
                addError={addError}
                removeError={removeError}
              />

            </Row>
          </Card.Body>
        </Card>

        <Table striped variant={theme.type} hover>
          <thead>
            <tr>
              <td>Merchant Name</td>
              <td>Market</td>
              <td>Account</td>
              <td>Merchant Id</td>
              <td></td>
            </tr>
          </thead>
          <MerchantBlacklistListElements
            addError={addError}
            removeError={removeError}
            selectedPublisherId={selectedPublisherId}
            reloadMerchantList={reloadMerchantList}
            setReloadMerchantList={setReloadMerchantList}
          />
        </Table>
      </Card.Body>
    </Card>
  );
}
