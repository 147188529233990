// @flow

import type {SortingType} from "./Sorting";
import type {MerchantList} from "../Hooks/Api/useMerchantList";

export default function sortMerchantList(
  merchantList: MerchantList,
  sorting: SortingType,
): MerchantList {
  const {field, direction} = sorting;
  if (field === null) {
    return {...merchantList, merchants: [...merchantList.merchants]};
  }

  const sortStart = performance.now();
  const sorted = merchantList.merchants.sort((left, right) => {
    const leftValue = left[field];
    const rightValue = right[field];

    if (typeof leftValue === 'number') {
      return leftValue - rightValue;
    }

    if (typeof leftValue === 'string') {
      return leftValue.localeCompare(rightValue);
    }

    // Alles Andere ist ungültig. Auf Standard-Sortierung zurückgreifen
    return left.partnerMerchantId.localeCompare(right.partnerMerchantId);
  });

  if (direction === 'desc') {
    sorted.reverse();
  }

  const sortDuration = (performance.now() - sortStart).toFixed(0);
  console.info(`Sort Duration: ${sortDuration} ms`);

  // Da sort in-place sortiert muss ein neues Array erzeugt werden
  return {
    ...merchantList,
    merchants: [...sorted],
  };
}
