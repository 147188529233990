// @flow
import {isViewTrafficActive, viewTrafficStatusToName} from "../../../Helper/ViewTrafficStatus";
import {dateStringToLocale} from "../../../Helper/DateHelper";
import FlagBadge from "../../../GenericComponents/FlagBadge";

type Props = {
  viewTrafficStatus: boolean,
  updatedDate: string,
  pending: boolean,
  toggleStatus: Function,
  isEditable: boolean,
};

export default function PartnerMerchantViewTrafficStatus(props: Props) {
  const {
    viewTrafficStatus,
    updatedDate,
    pending,
    toggleStatus,
    isEditable,
  } = props;

  if (isEditable) {
    return (
      <FlagBadge
        value={isViewTrafficActive(viewTrafficStatus)}
        textForce={pending ? '...' : viewTrafficStatusToName(viewTrafficStatus)}
        variantForce={pending ? 'warning' : null}
        title={`Last updated: ${dateStringToLocale(updatedDate)}`}
        onDoubleClick={toggleStatus}
      />
    );
  }

  return (
    <FlagBadge
      value={isViewTrafficActive(viewTrafficStatus)}
      textForce={pending ? '...' : viewTrafficStatusToName(viewTrafficStatus)}
      variantForce={pending ? 'warning' : null}
      title={`Last updated: ${dateStringToLocale(updatedDate)}`}
    />
  );
}
