// @flow
import type {AllowedAccounts} from "../../Hooks/Api/usePublisherAllowedAccountsList";

type Props = {
  accountsData: AllowedAccounts,
  market: string,
  partner: string,
};

export default function ModalTableContent(props: Props) {
  const {
    accountsData,
    market,
    partner
  } = props;

  const publisherList = accountsData[market][partner];

  return publisherList && publisherList
    .filter(publisher => publisher.allowed).length > 0 ? (
    publisherList
      .filter(publisher => publisher.allowed)
      .map(publisher => (
        <div key={publisher.identifier}>{publisher.name}</div>
      ))
  ) : (
    <div> – </div>
  )
}
