// @flow
import {Route, Routes} from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import Error404 from "./Error/Error404";
import ProjectsList from "./Projects/List/ProjectsList";
import MerchantList from "./Merchants/List/MerchantList";
import IgnoredMerchantList from "./Merchants/List/IgnoredMerchantList";
import DebugComponents from "./Debug/DebugComponents";
import PublisherList from "./Publisher/PublisherList";
import MerchantBlacklistListErrorStateHandler from "./MerchantBlacklist/List/MerchantBlacklistListErrorStateHandler";
import YieldAdsMerchantList from "./YieldAdsMerchants/List/YieldAdsMerchantList";
import YieldAdsMerchantWhitelist from "./MerchantWhitelist/List/YieldAdsMerchantWhitelist";
import YieldadsPayments from "./Payments/YieldadsPayments";
import AccountsList from "./Accounts/List/AccountsList";
import PublisherAllowedAccountsEdit from "./Publisher/PublisherAllowedAccountsEdit/PublisherAllowedAccountsEdit";
import PostbackUrlForm from "./Publisher/PublisherPostbackUrlEdit/PostbackUrlForm";
import PublisherDetails from "./Publisher/PublisherDetails";

type Props = {};

export default function YieldAdsRoutes(props: Props) {
  return (
    <Routes>
      <Route path={'/'} element={<Dashboard/>}/>

      <Route path={'/dashboard'} element={<Dashboard/>}/>

      <Route path={'/accounts/list'} element={<AccountsList/>}/>

      <Route path={'/projects/list'} element={<ProjectsList/>}/>

      <Route path={'/publisher/list'} element={<PublisherList/>}/>

      <Route path={'/publisher/payments/list'} element={<YieldadsPayments/>}/>
      <Route path={'/publisher/:id/allowed-accounts/edit'} element={<PublisherAllowedAccountsEdit/>}/>
      <Route path={'/publisher/:id/details'} element={<PublisherDetails/>}/>
      <Route path={'/publisher/:id/details/postback'} element={<PostbackUrlForm/>}/>

      <Route path={'/merchant-blacklist/list'} element={<MerchantBlacklistListErrorStateHandler/>}/>

      <Route path={'/merchant-whitelist/list'} element={<YieldAdsMerchantWhitelist/>}/>

      <Route path={'/partner-merchants/list'} element={<MerchantList/>}/>
      <Route path={'/ignored-partner-merchants/list'} element={<IgnoredMerchantList/>}/>
      <Route path={'/project-merchants/list'} element={<MerchantList/>}/> {/* Damit Bookmarks nicht kaputtgehen */}

      <Route path={'/yieldads-merchants/list'} element={<YieldAdsMerchantList/>}/>

      <Route path={'/debug/components'} element={<DebugComponents/>}/>

      <Route path={'*'} element={<Error404/>}/>

    </Routes>
  );
}
