// @flow

import {useApiGet} from "./useApi";
import {UseApiResponseType} from "../../Helper/Response";
import type {OnApiErrorCallback} from "../../Helper/Api/fetchApi";
import type {SortingDirection} from "../../Helper/Sorting";
import type {ViewTrafficStatusCode} from "../../Helper/ViewTrafficStatus";

export type YieldAdsMerchant = {
  yieldadsMerchantId: string,
  market: string,
  domain: string,
  priority: number,
  authorityYieldadsStatus: boolean,
  whitelistStatus: boolean,
  whitelistCreatedDate: string,
  lastUpdated: string,
  viewTrafficStatus: ViewTrafficStatusCode,
  viewTrafficStatusUpdateDate: string,
};

export type YieldAdsMerchantList = {
  filteredCount: number,
  merchants: Array<YieldAdsMerchant>,
};

export function useYieldAdsMerchantList(
  page: number,
  pageSize: ?number,
  sortingField: string,
  sortingDirection: ?SortingDirection,
  domain: ?string,
  market: ?string,
  viewTrafficStatus: ?ViewTrafficStatusCode,
  onError: ?OnApiErrorCallback = null,
): UseApiResponseType<YieldAdsMerchantList> {
  const query = {
    page: page,
    pageSize: pageSize,
    sortField: sortingField,
    sortDirection: sortingDirection,
    merchantDomain: domain,
    market: market,
    viewTrafficStatus: viewTrafficStatus,
  };

 return useApiGet<YieldAdsMerchantList>('/yieldads-merchants', query, onError);
}
