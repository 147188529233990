// @flow

import {Col} from "react-bootstrap";
import {renderSpinner} from "../MerchantBlacklistPending";
import SelectSearch from "react-select-search";
import {useApiGetState} from "../../../../Hooks/Api/useApi";
import {useState} from "react";

type Props = {
  setSelectedMerchantId: Function,
  selectedMerchantId: string,
  selectedAccountId: string,
  selectedMarket: string,
  selectedPublisherId: string,
  addError: Function,
};

export default function MerchantsFilter(props: Props) {
  const {
    setSelectedMerchantId,
    selectedMerchantId,
    selectedAccountId,
    selectedMarket,
    selectedPublisherId,
    addError,
    removeError,
  } = props;

  const [merchantFilterIsLoading, setMerchantFilterIsLoading] = useState<string>(false);
  const [blacklistableMerchants, setBlacklistableMerchants] = useState<Array<Object>>([]);
  const [queryString, setQueryString] = useState<string>('');

  useApiGetState(
    '/merchants',
    {"identifier": selectedAccountId, "market": selectedMarket, "merchantDomain": queryString, 'format': 'filter'},
    setBlacklistableMerchants,
    () => {
      setBlacklistableMerchants([]);
    }, () => {
      setMerchantFilterIsLoading(false);
    }, () => {
      setMerchantFilterIsLoading(true);
    }, () => {
      setMerchantFilterIsLoading(false);
      return selectedPublisherId === null || queryString.length < 3; // wenn der query kürzer als 3 keinen request machen.
    });

  function getOptions(query) {
    setQueryString(query);

    if (query.length > 0 && selectedPublisherId === null) {
      addError('Publisher', 'Bitte wählen Sie zuerst einen Publisher aus.');
    } else {
      removeError('Publisher');
    }

    if (Array.isArray(blacklistableMerchants) && blacklistableMerchants.length > 0) {
      return blacklistableMerchants;
    }
    return [];
  }

  if (blacklistableMerchants[0] && blacklistableMerchants[0].value !== 'all') {
    blacklistableMerchants.unshift({'name': 'alle Merchants', 'value': 'all'});
  }

  return (
    <Col className={'mb-4'}>
      <SelectSearch
        style={{display:"inline-block"}}
        name="merchantNameSelectSearch"
        placeholder="Merchant Namen eingeben"
        search={true}
        options={blacklistableMerchants}
        getOptions={getOptions}
        onChange={merchantId => setSelectedMerchantId(merchantId)}
        defaultValue={'all'}
        value={selectedMerchantId}
      />
      {renderSpinner(merchantFilterIsLoading && queryString.length > 2)}
    </Col>
  );
}
