import Spinner from 'react-bootstrap/Spinner';

type Props = {
    mustDisplaySpinner: boolean,
};

export default function ConfirmDialogSpinner(props: Props) {
    const {mustDisplaySpinner} = props;

    if (mustDisplaySpinner === true) {
        return <>
            <Spinner animation="border" />
            <br/>
            <span>This can take some time...</span>
        </>;
    }

    return null;
}
