// @flow

import {useApiGet} from "./useApi";
import {UseApiResponseType} from "../../Helper/Response";
import type {OnApiErrorCallback} from "../../Helper/Api/fetchApi";

export type AllowedAccounts = {
  /** Market */
  [string]: {
    /** Partner */
    [string]: Array<AllowedAccountInfo>
  }
};

export type AllowedAccountInfo = {
  name: string,
  identifier: string,
  allowed: boolean,
};

export function usePublisherAllowedAccountsList(publisherId: string, onError: ?OnApiErrorCallback = null): UseApiResponseType<AllowedAccounts> {
    return useApiGet<AllowedAccounts>(`/publishers/${publisherId}/accounts`, null, onError);
}
