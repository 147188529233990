// @flow

import {useCallback, useEffect, useState} from "react";
import {createResponse, defaultApiData, ResponseStatus, UseApiResponseType} from "../../Helper/Response";
import {fetchApi} from "../../Helper/Api/fetchApi";
import {useApiKey} from "../../Context/ApiKey";
import {usePageNotificationSystem} from "../../Context/PageNotification";
import type {OnApiErrorCallback} from "../../Helper/Api/fetchApi";

type Query = {
  [string]: string,
};

export function useApiGetState(
  route: string,
  query: ?Object = null,
  setState: Function,
  addError: Function = null,
  onSuccess: Function = null,
  whilePending: Function = null,
  mustAbort: Function = null,
) {
  const merchantData = useApiGet(route, query, (reason: string) => {
    if (addError !== null) {
      addError('useApiGet error', reason);
    }
  }, mustAbort);

  useEffect(() => {
    if (merchantData.status === 'pending') {
      if (whilePending !== null) {
        whilePending();
      }
      return;
    }
    if (merchantData.status === ResponseStatus.ready && merchantData.response.parsedBody.error === undefined) {
      setState(merchantData.response.parsedBody);
      if (onSuccess !== null) {
        onSuccess();
      }
    }
  }, [merchantData]);
}

export function useApiGet<T>(route: string, query: ?Object = null, onError: ?OnApiErrorCallback = null, mustAbort: Function = null): UseApiResponseType<T> {
  return useApi('GET', route, null, query, onError, mustAbort);
}

function getPurifiedQuery(query: ?Object) {
  if (query === null) {
    return null;
  }

  const queryWithoutNulls = Object.fromEntries(Object.entries(query || {}).filter(([_, value]) => value != null));
  return new URLSearchParams(queryWithoutNulls);
}

export function useApi<T>(
  method: string,
  route: string,
  body: ?Object = null,
  query: ?Query = null,
  onError: ?OnApiErrorCallback,
  mustAbort: Function = () => {return false}
): UseApiResponseType<T> {
  const [apiData, setApiData] = useState<UseApiResponseType<T>>(defaultApiData);
  const apiKey = useApiKey();
  const notificationSystem = usePageNotificationSystem();

  const refresh = useCallback(() => {
    const abortController = new AbortController();

    // for specific conditions when the api should not yet be called
    if (mustAbort !== null && mustAbort()) {
      return  () => {}; //abortController.abort;
    }

    (async () => {
      const searchParams = getPurifiedQuery(query);
      try {
        setApiData(defaultApiData);
        const response = await fetchApi(method, route, apiKey.value, abortController, body, searchParams, notificationSystem.pushNotification, onError);

        // TODO: Das ist ganz gut, wenn das an einer Stelle passiert. Aber das sollte deaktivierbar sein
        if (response.statusCode === 401) {
          console.warn('useApi 401 auth redirect');
          apiKey.clear();
        }

        setApiData(createResponse(
          response,
          ResponseStatus.ready,
          null,
        ));
      } catch (error) {
        if (abortController.signal.aborted) {
          return;
        }

        setApiData(createResponse(
          null,
          ResponseStatus.error,
          error.message,
        ));
      }
    })();

    return () => {
      abortController.abort();
    };
    // Da sonst die Query jedes Mal ein neues Objekt wäre => Endlosschleife
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, JSON.stringify(query), apiKey.value])

  useEffect(() => {
    refresh();
  }, [route, JSON.stringify(query), apiKey.value]);

  return {
    refresh,
    ...apiData,
  };
}
