// @flow

import {useApiGet} from "./useApi";
import {UseApiResponseType} from "../../Helper/Response";
import type {OnApiErrorCallback} from "../../Helper/Api/fetchApi";
import type {Publishers} from "../../Routes/Publisher/Publisher";

export function usePublisherList(onError: ?OnApiErrorCallback = null): UseApiResponseType<Publishers> {
    return useApiGet<Publishers>(`/publishers`, null, onError);
}
