// @flow
import {Card, Col, Form, Row} from "react-bootstrap";
import DecoratedOption from "../../../GenericComponents/Input/DecoratedOption";
import OptionSeparator from "../../../GenericComponents/Input/OptionSeparator";

type Props = {};

export default function SelectDebug(props: Props) {
  return (
    <Card className={'mt-4'}>
      <Card.Header>
        <Card.Title as={'h4'}>Decorated Select</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={'auto'}>
            <Variants/>
          </Col>

          <Col md={'auto'}>
            <FontStyles/>
          </Col>

          <Col md={'auto'}>
            <Separators/>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

function Variants() {
  const variants = [
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'dark',
    'light',
    'primary-bright',
    'secondary-bright',
    'danger-bright',
    'success-bright',
    'warning-bright',
    'info-bright',
    'dark-bright',
    'light-bright',
  ];

  const options = [<option key="" value="">Variants</option>];
  for (const variant of variants) {
    options.push(<OptionSeparator key={variant + '-sep'} size={'lg'}/>)
    options.push(<DecoratedOption key={variant} value={variant} variant={variant}>{variant}</DecoratedOption>);
  }

  return (
    <Form.Select
      name={'decorated-select'}
      size={'sm'}
      defaultValue={''}
    >
      {options}
    </Form.Select>
  );
}

function FontStyles() {
  const fontStyles = [
    'regular',
    'italic',
    'bold',
  ];

  const options = [<option key="" value="">Font-Styles</option>];
  for (const fontStyle of fontStyles) {
    options.push(<OptionSeparator key={fontStyle + '-sep'} size={'lg'}/>)
    options.push(<DecoratedOption key={fontStyle} value={fontStyle} fontStyle={fontStyle}>{fontStyle}</DecoratedOption>);
  }

  return (
    <Form.Select
      name={'decorated-select'}
      size={'sm'}
      defaultValue={''}
    >
      {options}
    </Form.Select>
  );
}

function Separators() {
  const sizes = [
    'sm',
    'md',
    'lg',
    'xl',
  ];

  const options = [
    <option key="" value="">Separators</option>,
    <OptionSeparator key={'sep'} size={'lg'}/>,
  ];

  for (const size of sizes) {
    options.push(<DecoratedOption key={size} value={size}>{size}</DecoratedOption>);
    options.push(<OptionSeparator key={size + '-sep'} size={size} variant={'secondary'}/>)
  }

  return (
    <Form.Select
      name={'decorated-select'}
      size={'sm'}
      defaultValue={''}
    >
      {options}
    </Form.Select>
  );
}
