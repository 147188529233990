// @flow

import {fetchApiPatch, OnApiErrorCallback} from "../fetchApi";
import {createResponse, ResponseStatus, UseApiResponseType} from "../../Response";
import type {UpdatePropertyFunction} from "./updateProperty";

export const updateMerchantIdPriority: UpdatePropertyFunction = async (
  merchantIdId: string,
  newPriority: number,
  apiKey: string,
  pushNotification: ?Function = null,
  onError: ?OnApiErrorCallback = null,
): UseApiResponseType<any> => {
  const body = {
    priority: newPriority,
  }

  try {
    const rawResponse = await fetchApiPatch(
        `/merchants/${merchantIdId}/priority`,
        apiKey,
        body,
        null,
        null,
        pushNotification,
        onError
    );
    return createResponse(
      rawResponse,
      ResponseStatus.ready,
      null,
    );
  } catch (error) {
    return createResponse(
      null,
      ResponseStatus.error,
      error.message,
    );
  }
}
