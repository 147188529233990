// @flow
import {Card, Spinner} from "react-bootstrap";
import {ResponseStatus} from "../../../Helper/Response";
import ErrorBox from "../../../GenericComponents/ErrorBox";
import {useEffect, useMemo, useRef, useState} from "react";
import PageAlertContextWrapper from "../../../Context/WrapperComponent/PageAlertContextWrapper";
import {useAccountIdMapping} from "../../../Hooks/Api/useAccountList";
import YieldAdsMerchantListTable from "./YieldAdsMerchantListTable";
import {useTabTitle} from "../../../Context/TabTitle";
import {useSearchParams} from "react-router-dom";
import type {SortingType} from "../../../Helper/Sorting";
import LoadedYieldAdsMerchantsListRows from "./LoadedYieldAdsMerchantsListRows";
import type {ApiErrors} from "../../../Helper/Api/fetchApi";
import {useYieldAdsMerchantList} from "../../../Hooks/Api/useYieldAdsMerchantList";
import YieldAdsMerchantFilterControls from "./YieldAdsMerchantFilterControls";

type Props = {};

const pageSize = 50;
export default function YieldAdsMerchantList(props: Props) {
  useTabTitle('Merchants (YieldAds)');

  const [query, setQuery] = useSearchParams();
  const [error, setError] = useState<?string>(null);

  // Kein onError, da der Toast ausreicht
  const accountMap = useAccountIdMapping(() => 'Es gab einen Fehler beim Laden der Accounts');

  // Query ist die single-source-of-truth für die Filter
  const filter = useMemo(() => ({
    domain: query.get('domain') || null,
    market: query.get('market') || null,
    viewTrafficStatus: query.get('viewTrafficStatus') || null,
  }), [query]);

  const page = useMemo(() => {
    const rawPage = query.get('page') || '1';
    const parsedPage = parseInt(rawPage);

    if (isNaN(parsedPage)) {
      return 1;
    }

    return parsedPage;
  }, [query]);

  const [sorting, setSorting] = useState<SortingType>(() => ({
    field: null,
    direction: 'asc',
  }: SortingType));

  // Hinweis: Beim Bearbeiten eines Merchants (Prio/Status) landen die neuen Werte nicht in der Liste hier,
  //          weswegen die beim Filtern nicht berücksichtigt werden.
  //          Wenn das ein Problem ist/wird, dann muss man die Änderungen hochreichen von der Liste hierhin und dann aktualisieren.
  // noinspection JSUnusedLocalSymbols
  const merchantList = useYieldAdsMerchantList(
    page,
    pageSize,
    sorting.field,
    sorting.direction,
    filter.domain,
    filter.market,
    filter.viewTrafficStatus,
    (reason: string, parsedBody: ApiErrors, statusCode: ?number) => {
      setError(reason);
    }
  );

  const lastKnownMerchantCount = useRef(0);
  useEffect(() => {
    lastKnownMerchantCount.current = 0;
  }, [filter.domain, filter.market]);

  const updateFilter = (key: string, value: any) => {
    const ignoreInQuery = value === '' || value === 'any';
    ignoreInQuery ? query.delete(key) : query.set(key, value);
    query.delete('page');

    setQuery(query);
  }

  const tableRows = useMemo(() => {
    // Wenn Daten da sind und kein API-Fehler
    const merchants = merchantList.status === ResponseStatus.ready && merchantList.response.statusCode === 200
      ? merchantList.response.parsedBody
      : [];

    switch (merchantList.status) {
      case ResponseStatus.pending:
        return (
          <tr className={'striped-odd'}>
            <td rowSpan={2} colSpan={3} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
            <td rowSpan={2} colSpan={2} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
            <td rowSpan={2} colSpan={1} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
            <td rowSpan={2} colSpan={2} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
          </tr>
        );
      case ResponseStatus.ready:
        if (merchantList.response.statusCode !== 200) {
          return null;
        }

        lastKnownMerchantCount.current = merchants.filteredCount;
        return <LoadedYieldAdsMerchantsListRows
          merchantList={merchants}
          accountMap={accountMap}
        />
      case ResponseStatus.error:
        return null;

      default:
        return null;
    }
  }, [merchantList, accountMap]);

  return (
    <Card>
      <Card.Body>
        <h4 className={'mb-4'}>Yieldads Merchants</h4>

        {error !== null && (
          <ErrorBox title={'Error during API fetch'}>
            {error}
          </ErrorBox>
        )}

        <PageAlertContextWrapper>
          <YieldAdsMerchantFilterControls
            accountMap={accountMap}
            defaultDomainFilter={filter.domain}
            defaultMarketFilter={filter.market}
            defaultViewTrafficStatus={filter.viewTrafficStatus}

            onDomainFilterChange={(newValue) => updateFilter('domain', newValue)}
            onMarketFilterChange={(newValue) => updateFilter('market', newValue)}
            onViewTrafficStatusChange={(newValue) => updateFilter('viewTrafficStatus', newValue)}

            page={page}
            maxPages={Math.ceil(lastKnownMerchantCount.current / pageSize)}
            onPageChange={(newPage) => {
              query.set('page', newPage);
              setQuery(query);
            }}
          />

          <YieldAdsMerchantListTable
            accountMap={accountMap}
            sorting={sorting}
            setSorting={setSorting}
          >
            {tableRows}
          </YieldAdsMerchantListTable>
        </PageAlertContextWrapper>
      </Card.Body>
    </Card>
  );
}
