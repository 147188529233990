// @flow
import {Themes, useTheme} from "../Context/Theme";
import {Button} from "react-bootstrap";
import {HiLightBulb} from "react-icons/hi";
import {TbBulbOff} from "react-icons/tb";
import {uppercaseFirst} from "../Helper/StringHelper";

type Props = {};

export default function ThemeSwitcher(props: Props) {
  const themeContext = useTheme();
  const isDarkTheme  = themeContext.theme.type === 'dark';
  const theme = themeContext.theme;

  return (
    <div className={'_navigation-theme-switcher'}>
      <Button
        variant={isDarkTheme ? 'secondary' : 'dark'}
        size={'sm'}
        onClick={() => themeContext.setTheme(theme.name === 'dark' ? Themes.light : Themes.dark)}
      >
        {uppercaseFirst(theme.name)} {isDarkTheme ? <TbBulbOff/> : <HiLightBulb/>}
      </Button>
    </div>
  );
}
