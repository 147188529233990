// @flow
import {Card, Spinner, Table} from "react-bootstrap";
import {useTheme} from "../../Context/Theme";
import "react-select-search/style.css"
import {useState} from "react";
import ErrorBox from "../../GenericComponents/ErrorBox";
import {Publisher} from "./Publisher";
import PublisherRow from "./PublisherRow";
import {usePublisherList} from "../../Hooks/Api/usePublisherList";
import {ResponseStatus} from "../../Helper/Response";
import type {UseApiResponseType} from "../../Helper/Response";
import type {Publishers} from "./Publisher";

type Props = {};

export default function PublisherList(props: Props) {
  const {theme} = useTheme();
  const [error, setError] = useState<?string>(null);

  const apiData = usePublisherList(setError);

  return (
    <Card>
      <Card.Body>
        <h4 className={'mb-4'}>Publisher List</h4>

        {error !== null && (
          <ErrorBox title={'Error during API fetch'}>
            {error}
          </ErrorBox>
        )}

        <Table striped variant={theme.type} hover>
          <thead>
          <tr>
            <th>Id</th>
            <th>Publisher Name</th>
            <th>Api-Key</th>
            <th>Permissions</th>
            <th>Partner Priority</th>
            <th>Allowed Accounts</th>
            <th>Redirect Host</th>
            <th>Zur Blacklist</th>
          </tr>
          </thead>
          <tbody>
            <Body apiData={apiData}/>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

type BodyProps = {
  apiData: UseApiResponseType<Publishers>,
}

function Body(props: BodyProps) {
  const {
    apiData,
  } = props;

  switch (apiData.status) {
    case ResponseStatus.pending:
      return <PendingIndicator/>;
    case ResponseStatus.ready:
      if (apiData.response.statusCode !== 200) {
        return null;
      }

      const publisherList = apiData.response.parsedBody;
      return publisherList.map((publisher: Publisher) => <PublisherRow
        key={publisher.id}
        publisher={publisher}
      />);
    case ResponseStatus.error:
      return null;

    default:
      return null;
  }
}

function PendingIndicator() {
  return (
    <tr>
      <td rowSpan={2} colSpan={3} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
      <td rowSpan={2} colSpan={2} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
      <td rowSpan={2} colSpan={3} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
    </tr>
  );
}
