// @flow
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useCallback, useState} from "react";

type Props = {};

export default function MagnificentButton(props: Props) {
  const [clicked, setClicked] = useState<boolean>(false);

  const onClick = useCallback(() => {
    console.info('It\'s correct!\nSource: %cTrust me bro!', 'font-style: italic');
    setClicked(true);
  }, []);

  return (
    <OverlayTrigger
      placement={'right'}
      overlay={
        <Tooltip>
          Tooltip on {clicked ? 'the far' : ''} <strong>left</strong>
        </Tooltip>
      }
    >
      <Button size={'sm'} variant={'secondary'} onClick={onClick}>Tooltip on left</Button>
    </OverlayTrigger>
  );
}
