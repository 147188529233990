// @flow

import type { YieldAdsPaymentsList } from "../../Hooks/Api/usePaymentsList";
import {useState} from "react";

type Props = {
    payment: YieldAdsPaymentsList,
    trClassName?: string,
};

export default function PaymentsListRow(props: Props) {
    const {
        payment,
        trClassName = undefined,
    } = props;

    const [paymentState, setPaymentState] = useState<YieldAdsPaymentsList>(payment);
    const currencyFormatter = new Intl.NumberFormat('en-US', 'currency');

    const {
        paymentId,
        publisherId,
        paidDate,
        fromDate,
        commissionCount,
        sumRevenueEur,
        sumRevenueYieldadsEur,
        sumRevenuePublisherEur,
    } = paymentState;

    const formattedFromDate = new Date(fromDate).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'long',
    });
    const formattedPaidDate = new Date(paidDate).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });

    return (
        <tr className={trClassName}>
            <td>{paymentId}</td>
            <td>{publisherId}</td>
            <td>{formattedFromDate}</td>
            <td>{formattedPaidDate}</td>
            <td style={{textAlign: 'right'}}>{commissionCount}</td>
            <td style={{textAlign: 'right'}}>{currencyFormatter.format(sumRevenueEur)} €</td>
            <td style={{textAlign: 'right'}}>{currencyFormatter.format(sumRevenueYieldadsEur)} €</td>
            <td style={{textAlign: 'right'}}>{currencyFormatter.format(sumRevenuePublisherEur)} €</td>
        </tr>
    );
}
