// @flow

import {fetchApiPatch, OnApiErrorCallback} from "../fetchApi";
import {createResponse, ResponseStatus, UseApiResponseType} from "../../Response";

export async function updateAccountViewTrafficFactor(
  identifier: string,
  viewTrafficFactor: number,
  apiKey: string,
  pushNotification: ?Function = null,
  onError: ?OnApiErrorCallback = null,
): UseApiResponseType<any> {
  const body = { viewTrafficFactor }

  try {
    const rawResponse = await fetchApiPatch(`/accounts/${identifier}/factor`, apiKey, body, null, null, pushNotification, onError);
    return createResponse(
      rawResponse,
      ResponseStatus.ready,
      null,
    );
  } catch (error) {
    return createResponse(
      null,
      ResponseStatus.error,
      error.message,
    );
  }
}
