// @flow

import type {UseApiResponseType} from "./Response";
import {createResponse, defaultApiData, ResponseStatus} from "./Response";
import {useEffect, useState} from "react";

export async function sleep(duration: number): Promise {
  return new Promise((resolve => {
    setTimeout(resolve, duration);
  }));
}

export async function fetchNonApi<T>(response: T): T {
  const result = {
    parsedBody: response,
    statusCode: 200,
  };

  await sleep(1500);

  return result;
}

export function useNonApi<T>(response: T): UseApiResponseType<T> {
  const [apiData, setApiData] = useState<UseApiResponseType<T>>(defaultApiData);

  useEffect(() => {
    (async () => {
      setApiData(createResponse(
        defaultApiData,
        ResponseStatus.pending,
        null,
      ));

      setApiData(createResponse(
        await fetchNonApi(response),
        ResponseStatus.ready,
        null,
      ));
    })();
  }, [response]);

  return apiData;
}
