// @flow
import {Table} from "react-bootstrap";
import {useTheme} from "../../../Context/Theme";
import type {SortingType} from "../../../Helper/Sorting";
import HeaderCell from "../../../GenericComponents/SortableTableHeaderCell";

type Props = {
  children: React.ReactNode,
  sorting: SortingType,
  setSorting: (newSorting: SortingType) => void,
};

export default function YieldAdsMerchantFingerprintWhitelistListTable(props: Props) {
  const {
    children,
    sorting,
    setSorting,
  } = props;

  const {theme} = useTheme();

  return (
    <Table variant={theme.type} hover>
      <thead>
      <tr>
        <HeaderCell
          iconPosition={'r'}
          value={'merchantName'}
          sorting={sorting}
          setSorting={setSorting}
        >Merchant Name</HeaderCell>

        <HeaderCell
          iconPosition={'r'}
          value={'market'}
          sorting={sorting}
          setSorting={setSorting}
        >Market</HeaderCell>

        <HeaderCell
          iconPosition={'r'}
          value={'whitelistStatus'}
          sorting={sorting}
          setSorting={setSorting}
        >Whitelist Status</HeaderCell>

        <HeaderCell
          iconPosition={'r'}
          value={'whitelistCreatedDate'}
          sorting={sorting}
          setSorting={setSorting}
        >Eingetragen seit</HeaderCell>

        <th>Bearbeiten</th>
      </tr>
      </thead>
      <tbody>
      {children}
      </tbody>
    </Table>
  );
}
