// @flow
import PaymentsListRow from "./PaymentsListRow";
import type {Payment} from "./Payment";
import type {ApiResponse} from "../../Helper/Response";
import React from "react";
import {Spinner} from "react-bootstrap";

type Props = {
    paymentsList: ?ApiResponse<Payment>,
    stripingClasses?: Array<string>,
};

export default function LoadedYieldadsPaymentsListRows(props: Props) {
    const {
        paymentsList,
        stripingClasses = [undefined],
    } = props;
    const stripingInterval = stripingClasses.length;

    if (paymentsList === null) {
        return (
            <tbody>
                <tr>
                    <td colSpan={11} className={'text-center'}>
                        <Spinner size={'sm'} animation={'grow'}/>
                    </td>
                </tr>
            </tbody>
        );
    }

    if (paymentsList.statusCode !== 200) {
        return (
            <tbody>
            <tr>
                <td colSpan={11} className={'text-center'}>
                    Es gab einen Fehler beim Laden
                </td>
            </tr>
            </tbody>
        )
    }

    if (paymentsList.parsedBody.count === 0) {
        return (
            <tbody>
            <tr>
                <td colSpan={11} className={'text-center'}>
                   Es sind keine Payments für den ausgewählten Zeitraum und Publisher vorhanden
                </td>
            </tr>
            </tbody>
        )
    }

    return (
        <tbody>
            {paymentsList.parsedBody.payments.map((payment: Payment, index) => {
                return (
                    <PaymentsListRow
                        key={index}
                        payment={payment}
                        trClassName={stripingClasses[index % stripingInterval]}
                    />
                );
            })}
        </tbody>
    );
}
