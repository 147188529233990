// @flow
import React from 'react';
import {Card} from "react-bootstrap";
import {useTabTitle} from "../../Context/TabTitle";
import MagnificentButton from "./MagnificentButton";

type Props = {};

export default function Dashboard(props: Props) {
  useTabTitle('Dashboard');

  return (
    <Card>
      <Card.Body>
        <h4 className={'mb-4'}>Dashboard</h4>

        <MagnificentButton/>
      </Card.Body>
    </Card>
  );
}
