// @flow

import {createContext, useContext} from "react";

export type ThemeType = {
  name: string,
  type: 'light' | 'dark',
}

export type ThemeContextType = {
  theme: ThemeType,
  setTheme: Function,
}

export const Themes: {
  [string]: ThemeType,
} = {
  'dark': {
    name: 'dark',
    type: 'dark',
  },
  'light': {
    name: 'light',
    type: 'light',
  }
}

export const defaultTheme: ThemeType = Themes.dark;

export const ThemeContext = createContext({
  theme: defaultTheme,
  setTheme: () => {},
});

export function useTheme(): ThemeContextType {
  return useContext(ThemeContext);
}

export function isDarkTheme(theme: ThemeType): boolean {
  return theme.type === 'dark';
}
