// @flow

import {Button} from "react-bootstrap";
import {fetchApiPost} from "../../../Helper/Api/fetchApi";
import {usePageNotificationSystem} from "../../../Context/PageNotification";
import {useApiKey} from "../../../Context/ApiKey";
import {GrAdd} from "react-icons/gr";

type Props = {
  yieldAdsMerchantId: string,
  whitelistStatus: boolean,
  refresh: () => void,
};

export default function AddToWhitelistButton(props: Props) {
  const {
    yieldAdsMerchantId,
    whitelistStatus,
    refresh,
  } = props;

  const {pushNotification} = usePageNotificationSystem();
  const apiKey = useApiKey().value;

  async function addToWhitelist() {
    await fetchApiPost(`/merchant-whitelist/${yieldAdsMerchantId}`, apiKey, null, null, null, pushNotification, null);
    refresh();
  }

  return (
    <Button
      className={'ms-3'}
      title={'Zur Whitelist hinzufügen'}
      variant={'success'}
      onClick={addToWhitelist}
      size={'sm'}
      hidden={whitelistStatus}
    ><GrAdd /></Button>
  );
}
