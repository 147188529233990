// @flow
import {ButtonGroup, Button, Card, Col, Form, Row} from "react-bootstrap";
import {partnerAbbreviationToFullName} from "../../../Helper/Partner";
import type {AllowedAccounts} from "../../../Hooks/Api/usePublisherAllowedAccountsList";
import {stringToHuedColor} from "../../../Helper/Color";
import {useTheme} from "../../../Context/Theme";
import React, {useMemo} from "react";

type Props = {
  market: string,
  partner: string,
  accountsData: AllowedAccounts,
  onAllowedChange: ?(partner: string, market: string, identifier: string | Array<string>, allowed: boolean) => void,
};

export default function PartnerBlock(props: Props) {
  const {
    market,
    partner,
    accountsData,
    onAllowedChange = () => {},
  } = props;

  const themeContext = useTheme();

  const partnerFullName = partnerAbbreviationToFullName(partner);
  const tint = stringToHuedColor(partnerFullName);
  const [tintStrengthHeader, tintStrengthBody] = mapTintStrength[themeContext.theme.type];

  const buttonGroupStyle = mapButtonGroupStyle[themeContext.theme.type]

  const allowedIdentifiers = useMemo(() => {
    return accountsData[market][partner].map((account) => account.identifier);
  }, [accountsData, market, partner]);

  const updateWholeBlock = (allowed: boolean) => {
    onAllowedChange(partner, market, allowedIdentifiers, allowed);
  }

  const allowMultiple = allowMultipleAccounts(partner);

  const onAllowedChangeWrapper = (partner: string, market: string, identifier: string, allowed: boolean) => {
    // Wenn ein Account aktiviert werden soll, nur einer erlaubt ist und bereits eins ausgewählt ist, vorher alle abwählen
    if (allowed && !allowMultiple && allowedIdentifiers.length > 1) {
      onAllowedChange(partner, market, allowedIdentifiers, false);
    }

    onAllowedChange(partner, market, identifier, allowed);
  }

  return (
    <Card>
      <Card.Header style={{backgroundColor: `rgba(${tint.red}, ${tint.green}, ${tint.blue}, ${tintStrengthHeader})`}}>
        <Row>
          <Col>
            {partnerFullName}
          </Col>
          <Col className={'d-flex justify-content-end pe-1'}>
            {allowMultiple &&
              <ButtonGroup size={'sm'} style={buttonGroupStyle}>
                <Button
                  variant={'success'}
                  title={'Diesen Block aktivieren'}
                  onClick={() => updateWholeBlock(true)}
                >+</Button>
                <Button
                  variant={'danger'}
                  title={'Diesen Block deaktivieren'}
                  onClick={() => updateWholeBlock(false)}
                >-</Button>
              </ButtonGroup>
            }
          </Col>
        </Row>
      </Card.Header>
      <Card.Body style={{backgroundColor: `rgba(${tint.red}, ${tint.green}, ${tint.blue}, ${tintStrengthBody})`}}>
        {accountsData[market][partner].map((account) => (
        <React.Fragment key={account.identifier}>
          <Form.Check
            id={`allowed-${account.identifier}`}
            type={allowMultiple ? 'checkbox' : 'radio'}
            label={account.name}
            checked={account.allowed}
            // onChange wird bei Radio-Buttons nicht getriggert, wenn diese bereits aktiv sind.
            onClick={() => onAllowedChangeWrapper(partner, market, account.identifier, !account.allowed)}
            readOnly={true} // Sonst gibt's mecker von react, weil ein Input ohne "onChange" böse ist.
          />
        </React.Fragment>
      ))}
      </Card.Body>
    </Card>
  );
}

const mapTintStrength = {
  'dark': ['0.035', '0.02'],
  'light': ['0.08', '0.045'],
};

const mapButtonGroupStyle = {
  // Der Kontrast ist sonst viel zu hoch/grell im Dark-Mode. Vllt. gibt's da aber noch eine bessere Lösung?
  'dark': {filter: 'saturate(0.65)'},
  'light': {},
};

function allowMultipleAccounts(partner: string): boolean {
  const mapping = {
    ydk: true,
    adv: false,
    tad: false,
    ift: false,
    adn: false,
  };

  const lower = partner.toLowerCase();
  const show = mapping[lower];
  if (show === undefined) {
    return true;
  }

  return show;
}
