// @flow
import type {UseApiResponseType} from "../../../Helper/Response";
import type {Account, AccountList} from "../../../Hooks/Api/useAccountList";
import {ResponseStatus} from "../../../Helper/Response";
import {Button, Card, Col, Row, Spinner} from "react-bootstrap";
import {TiShoppingCart} from "react-icons/ti";
import {Link} from "react-router-dom";
import './ProjectsCardListStyle.css';

type Props = {
  accountList: UseApiResponseType<AccountList>,
};

export default function AccountsListCards(props: Props) {
  const {accountList} = props;

  switch (accountList.status) {
    case ResponseStatus.pending:
      const pendingCards = [0, 1, 2, 3];

      return (
        <Row>
          {pendingCards.map((index) => (
            <Col key={index} style={{minWidth: '30em'}} className={'mb-4'}>
              <Card>
                <Card.Header>
                  <Card.Title className={'mb-0'}>
                    <Row>
                      <Col>▒░░░░░░░&nbsp;▒░░░░░&nbsp;–&nbsp;▒▒</Col>
                      <Col className={'pe-2 text-end'}>
                        <Button
                          size={'sm'}
                          variant={'outline-secondary'}
                          className={'color-purple'}
                          title={'Zeige alle Merchants zu diesem Account'}
                        ><TiShoppingCart/></Button>
                      </Col>
                    </Row>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Spinner size={'sm'} animation={'grow'}/>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      );
    case ResponseStatus.ready:
      if (accountList.response.statusCode !== 200) {
        return null;
      }

      return (
        <Row>
          {accountList.response.parsedBody.map((account: Account) => {
            const {identifier, name, partner, market, viewTrafficFactor, projects} = account;

            return (
              <Col key={identifier} md={'auto'} className={'mb-4'}>
                <Card>
                  <Card.Header>
                    <Card.Title className={'mb-0'}>
                      <Row>
                        <Col>{name}</Col>
                        <Col className={'pe-2 text-end'}>
                          <Link to={`/partner-merchants/list?account=${identifier}`}>
                            <Button
                              size={'sm'}
                              variant={'outline-secondary'}
                              className={'color-purple'}
                              title={'Zeige alle Merchants zu diesem Account'}
                            ><TiShoppingCart/></Button>
                          </Link>
                        </Col>
                      </Row>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    Account&nbsp;ID: <code>{identifier}</code>
                    <br/>
                    Projects:
                    <ul className={'accounts-projects-card-list'}>
                      {projects.map((project) => (
                        <li key={project.projectId}>
                          <span className={'project-name'}>{project.name}</span>{' '}
                          <span className={'project-identifier'}>(<code>{project.projectId}</code>)</span>
                        </li>
                      ))}
                    </ul>
                    View Traffic Factor: {viewTrafficFactor}
                    <br/>
                    Partner: {partner}
                    <br/>
                    Market: {market}
                    <br/>
                  </Card.Body>
                </Card>
              </Col>
            )
          })}
        </Row>
      );
    case ResponseStatus.error:
      return null;

    default:
      return null;
  }
}
