// @flow

import {Col} from "react-bootstrap";
import SelectSearch from "react-select-search";
import {useState} from "react";
import {useApiGetState} from "../../../Hooks/Api/useApi";

type Props = {
    selectedPublisherId: string,
    setSelectedPublisherId: Function,
    setReloadPaymentsList: Function,
    disabled: boolean,
};

export default function PublisherFilter(props: Props) {
    const {
        selectedPublisherId,
        setSelectedPublisherId,
        setReloadPaymentsList,
        disabled,
    } = props;

    const [publishers, setPublishers] = useState<Array<Object>>([]);

    useApiGetState('/publishers', { 'format': 'filter'}, setPublishers,() => {
        setPublishers([]);
    });

    return (
        <Col className={'mb-4'}>
            <SelectSearch
                style={{display:"inline-block"}}
                name="merchantNameSelectSearch"
                placeholder="Select publisher"
                search={false}
                options={publishers}
                onChange={publisherId => {
                    setSelectedPublisherId(publisherId);
                    setReloadPaymentsList((old: number) => old +1);
                }}
                defaultValue={'all'}
                value={selectedPublisherId}
                disabled={disabled}
            />
        </Col>
    );
}
