// @flow

import {isViewTrafficActive, viewTrafficStatusCode, viewTrafficStatusToName} from "../../../Helper/ViewTrafficStatus";
import OptionSeparator from "../../../GenericComponents/Input/OptionSeparator";
import DecoratedOption from "../../../GenericComponents/Input/DecoratedOption";

type Props = {};

export default function ViewTrafficStatusOptions({}: Props) {
  const knownStatusCodes = Object.values(viewTrafficStatusCode).filter((code) => code !== viewTrafficStatusCode.unknown);

  const enabledCodes   = knownStatusCodes.filter((code) => isViewTrafficActive(code));
  const enabledOptions = enabledCodes.map((code) => (
    <DecoratedOption key={code} variant={'success-bright'} value={code}>{viewTrafficStatusToName(code)}</DecoratedOption>
  ));

  const disabledCodes   = knownStatusCodes.filter((code) => !isViewTrafficActive(code));
  const disabledOptions = disabledCodes.map((code) => (
    <DecoratedOption key={code} variant={'danger-bright'} value={code}>{viewTrafficStatusToName(code)}</DecoratedOption>
  ));

  return [
    <DecoratedOption key={'any'} value={'any'}>Alle VT-Status</DecoratedOption>,
    <OptionSeparator key={'sep1'} size={"md"}/>,
    <DecoratedOption key={'enabled'} value={'enabled'} fontStyle={'bold'} variant={'success-bright'}>Enabled</DecoratedOption>,
    ...enabledOptions,
    <OptionSeparator key={'sep2'} size={"md"}/>,
    <DecoratedOption key={'disabled'} value={'disabled'} fontStyle={'bold'} variant={'danger-bright'}>Disabled</DecoratedOption>,
    ...disabledOptions
  ];
}
