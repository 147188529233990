// @flow

import {Col} from "react-bootstrap";
import SelectSearch from "react-select-search";
import {useApiGetState} from "../../../../Hooks/Api/useApi";
import {renderSpinner} from "../MerchantBlacklistPending";
import {useState} from "react";

type Props = {
  setSelectedAccountId: Function,
  selectedMerchantId: string,
  selectedMarket: string,
  selectedAccountId: string,
};

export default function AccountsFilter(props: Props) {
  const {
    selectedMerchantId,
    selectedMarket,
    selectedAccountId,
    setSelectedAccountId,
  } = props;

  const [accountFilterIsLoading, setAccountFilterIsLoading] = useState<string>(true);
  const [accounts, setAccounts] = useState<Array<Object>>([]);

  useApiGetState('/accounts', {yieldadsMerchantId: selectedMerchantId, market: selectedMarket, format: 'filter'}, setAccounts, () => {
    setAccounts([]);
  }, () => {
    setAccountFilterIsLoading(false);
  }, () => {
    setAccountFilterIsLoading(true);
  });

  if (accounts[0] && accounts[0].value !== 'all') {
    accounts.unshift({'name': 'alle Accounts', 'value': 'all'});
  }

  return (
    <Col className={'mb-4'}>
      <SelectSearch
        style={{display:"inline-block"}}
        name="accountSelectSearch"
        placeholder="Account/alle"
        search={true}
        options={accounts}
        onChange={accountId => setSelectedAccountId(accountId)}
        defaultValue={'all'}
        value={selectedAccountId}
      />
      {renderSpinner(accountFilterIsLoading)}
    </Col>
  );
}
