// @flow
import {Button, Col, Row} from "react-bootstrap";
import {Variant} from "react-bootstrap/types";
import {uppercaseFirst} from "../../../Helper/StringHelper";
import {usePageNotificationSystem} from "../../../Context/PageNotification";
import {useCallback} from "react";
import NotificationToast from "../../../GenericComponents/NotificationToast";

type Props = {};

const variants: Array<Variant> = [
  undefined,
  'primary',
  'secondary',
  'success',
  'danger',
  'warning',
  'info',
  'light',
  'dark',
];

export default function NotificationVariants(props: Props) {
  const {pushNotification} = usePageNotificationSystem();

  const handleClick = useCallback((variant: Variant) => {
    const variantDisplayName = uppercaseFirst(variant || 'none');
    if (variant === 'none') {
      variant = undefined;
    }

    pushNotification(
      <NotificationToast
        id={'notification-debug-variant'}
        title={`Notification of variant ${variantDisplayName}`}
        variant={variant}
      >
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
        sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
        At vero eos et accusam et justo duo dolores et ea rebum.
        Stet clita kasd gubergren, no sea takimata sanctus est.
      </NotificationToast>
    );
  }, [pushNotification]);

  return (
    <Row className={'mt-4'}>
      {variants.map((variant) => (
        <Col key={variant || 'none'} className={'m-0 pe-0'}>
          <Button variant={variant || 'outline-secondary'} size={'sm'} onClick={() => handleClick(variant)}>
            {uppercaseFirst(variant || 'none')}
          </Button>
        </Col>
      ))}
    </Row>
  );
}
