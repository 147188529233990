// @flow
import {Button, Card, Row, Table} from "react-bootstrap";
import {useTheme} from "../../Context/Theme";
import "react-select-search/style.css"
import React, {useEffect, useState} from "react";
import MultiErrorBox from "../../GenericComponents/MultiErrorBox";
import PublisherFilter from "./Filter/PublisherFilter";
import LoadedYieldadsPaymentsListRows from "./LoadedYieldadsPaymentsListRows";
import YearMonthFilter from "./Filter/YearMonthFilter";
import PaymentEstimationAndCreateButton from "./PaymentEstimationAndCreateButton";
import generateMonthList from "./GenerateMonthList";
import {usePageNotificationSystem} from "../../Context/PageNotification";
import {fetchApiGet} from "../../Helper/Api/fetchApi";
import {useApiKey} from "../../Context/ApiKey";
import type {ApiResponse} from "../../Helper/Response";
import {useAppEnv} from "../../Context/AppEnvContext";
import Infos from "./Infos";

type Props = {};

export default function YieldadsPayments(props: Props) {
    const {
        errors,
    } = props;

    const {theme} = useTheme();
    const [reloadPaymentsList, setReloadPaymentsList] = useState(0);
    const [selectedPublisherId, setSelectedPublisherId] = useState<string>('zckcmh5e2w6ndj7n'); // standard: synatix
    const [isEstimationButtonDisabled, setIsEstimationButtonDisabled] = useState(true);
    const [showInfo, setShowInfo] = useState(false);
    const notificationSystem = usePageNotificationSystem();
    const apiKey = useApiKey().value;
    const [paymentsList, setPaymentsList] = useState<ApiResponse>(null);

    const monthList = generateMonthList(useAppEnv());
    const [fromYearMonth, setFromYearMonth] = useState(monthList[0].from);
    const [toYearMonth, setToYearMonth] = useState(monthList[0].to);

    async function requestPayments() {
        setPaymentsList(null);

        const query = new URLSearchParams({
            from: fromYearMonth,
            to: toYearMonth,
            publisherId: selectedPublisherId,
        });

        const response = await fetchApiGet(
            '/payments',
            apiKey,
            query,
            new AbortController(),
            notificationSystem,
            null
        );
        setPaymentsList(response);
    }

    useEffect(() => {
        if (selectedPublisherId !== null) {
            setIsEstimationButtonDisabled(false);
            requestPayments();
        }
    }, [selectedPublisherId, toYearMonth, fromYearMonth])

    useEffect(() => {
        notificationSystem.clearAll();
    }, [reloadPaymentsList])

    return (
        <Card>
            <Card.Body>
                <h4 className={'mb-4'}>Publisher Payments</h4>

                <MultiErrorBox errors={errors} title={'Fehler'} dismissible={false}/>

                <Card className={'mb-3'}>
                    <Card.Body className={'pb-0'}>
                        <Row xs={"auto"}>
                            <PublisherFilter
                                selectedPublisherId={selectedPublisherId}
                                setSelectedPublisherId={setSelectedPublisherId}
                                setReloadPaymentsList={setReloadPaymentsList}
                                disabled={isEstimationButtonDisabled}
                            />
                            <YearMonthFilter
                                selectedYearMonth={monthList.find((month) => month.from === fromYearMonth).value}
                                setSelectedFromYearMonth={setFromYearMonth}
                                setSelectedToYearMonth={setToYearMonth}
                                options={monthList}
                                setReloadPaymentList={setReloadPaymentsList}
                                disabled={isEstimationButtonDisabled}
                            />
                            <PaymentEstimationAndCreateButton
                                selectedFromDate={fromYearMonth}
                                selectedToDate={toYearMonth}
                                selectedPublisherId={selectedPublisherId}
                                reloadPaymentsList={requestPayments}
                                isEstimationButtonDisabled={isEstimationButtonDisabled}
                                setEstimationButtonDisabled={setIsEstimationButtonDisabled}
                            />
                        </Row>
                    </Card.Body>
                </Card>

                <Button
                  variant={'outline-info'}
                  size={'sm'}
                  style={{marginBottom: '15px'}}
                  onClick={() => setShowInfo(!showInfo)}
                >{showInfo ? 'Hide' : 'Show'} infos</Button>

                <Infos show={showInfo} />

                <br/>

                <Table striped variant={theme.type} hover>
                    <thead>
                    <tr>
                        <th>Payment ID</th>
                        <th>Publisher ID</th>
                        <th>Payment Month</th>
                        <th>Yieldads Paid Date</th>
                        <th style={{textAlign: 'right'}}>Commissions</th>
                        <th style={{textAlign: 'right'}}>Revenue Sum</th>
                        <th style={{textAlign: 'right'}}>Revenue Yieldads</th>
                        <th style={{textAlign: 'right'}}>Revenue Publisher</th>
                    </tr>
                    </thead>
                    <LoadedYieldadsPaymentsListRows paymentsList={paymentsList}/>
                </Table>
            </Card.Body>
        </Card>
    );
}
