// @flow
import {useState} from "react";
import {createResponse, defaultApiDataInactive, ResponseStatus} from "../../../Helper/Response";
import {Badge, Form} from "react-bootstrap";
import {usePageNotificationSystem} from "../../../Context/PageNotification";
import NotificationToast from "../../../GenericComponents/NotificationToast";
import {useApiKey} from "../../../Context/ApiKey";
import {updateYieldAdsMerchantPriority} from "../../../Helper/Api/Merchants/updateYieldAdsMerchantPriority";

type Props = {
  initialPriority: number,
  domain: string,
  yieldadsMerchantId: string,
  onPriorityChange: (yieldadsMerchantId: string) => void,
};

export default function YieldAdsMerchantPriority(props: Props) {
  const {initialPriority, domain, yieldadsMerchantId, onPriorityChange} = props;

  const [priorityOverride, setPriorityOverride] = useState<boolean>(initialPriority);
  const [editable, setEditable] = useState<boolean>(false);
  const [priorityChange, setPriorityChange]     = useState(defaultApiDataInactive);

  const notificationSystem = usePageNotificationSystem();
  const apiKey = useApiKey();

  const sendPriorityUpdateToApi = (newPriority: number) => {
    // Ignorieren, wenn bereits ein Request läuft
    if (priorityChange.status === ResponseStatus.pending) {
      return;
    }

    (async () => {
      const oldPriority = priorityOverride;
      setPriorityOverride(newPriority);

      setPriorityChange(createResponse(
        null,
        ResponseStatus.pending,
        null,
      ));

      const response = await updateYieldAdsMerchantPriority(
        yieldadsMerchantId,
        newPriority,
        apiKey.value,
        notificationSystem.pushNotification,
        () => <>Die Priorität für den Merchant "<span className={'decoration-underline'}>{domain}</span>" konnte nicht aktualisiert werden</>,
      );

      setPriorityChange(response);

      if (response.status === ResponseStatus.error || response.response?.statusCode !== 200) {
        setPriorityOverride(oldPriority);
      } else {
        onPriorityChange(newPriority);
      }
    })();
  };

  if (editable) {
    const onConfirm = (event) => {
      event.preventDefault();

      setEditable(false);

      // onBlur vom Input || onSubmit von der Form
      const input = event.target.value || event.target.elements[0].value;
      const newPriority = parseInt(input);
      if (isNaN(newPriority)) {
        notificationSystem.pushNotification(
          <NotificationToast id={'priority-input-error'} title={'Invalid Value'}>
            Der eingegebene Wert ({input}) ist keine gültige Ganzzahl!
          </NotificationToast>
        );
        return;
      }

      if (newPriority !== priorityOverride) {
        sendPriorityUpdateToApi(newPriority);
      }
    };

    return (
      <Form onSubmit={onConfirm}>
        <Form.Control
          type={'number'}
          size={'sm'}
          min={0}
          max={10000}
          defaultValue={priorityOverride}
          style={{maxWidth: '100%'}}
          onBlur={onConfirm}
          autoFocus={true}
        />
      </Form>
    );
  }

  if (priorityChange.status === ResponseStatus.pending) {
    return <Badge bg={'warning'}>{priorityOverride}</Badge>
  }

  return (
    <div className={'cursor-pointer'} onDoubleClick={() => setEditable(true)}>
      {priorityOverride}
    </div>
  );
}
