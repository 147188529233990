import type {UpdatePropertyFunction} from "./updateProperty";
import type {UseApiResponseType} from "../../Response";
import {createResponse, ResponseStatus} from "../../Response";
import {createNotification} from "../../../Context/PageNotification";

export const startPartnerMerchantUpdateRequest = async (
    handleUpdateRequest: UpdatePropertyFunction,
    setApiStatus: (status: UseApiResponseType) => void,
    apiKey: string,
    notificationPusher: Function,
    partnerMerchantId: number,
    data?: any
): Promise<boolean> => {
    setApiStatus(createResponse(
        null,
        ResponseStatus.pending,
        null,
    ));

    const response: UseApiResponseType<Response> = await handleUpdateRequest(
        partnerMerchantId,
        data,
        apiKey,
        notificationPusher,
        () => <>Der Merchant "<span className={'decoration-underline'}>{partnerMerchantId}</span>" konnte nicht
            aktualisiert werden</>,
    );

    setApiStatus(response);

    const isOk = !(response.status === ResponseStatus.error || response.response?.statusCode !== 200);

    const message = isOk ? response.response.parsedBody?.message : response.message;


    if (message) {
        notificationPusher(
            createNotification('evaluation-response-message', 'Nachricht vom System', message)
        );
    }

    return isOk;
};