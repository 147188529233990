// @flow

import {PageAlertContext} from "../PageAlert";
import type {PageAlert, PageAlertContextType, PageAlerts} from "../PageAlert";
import {useCallback, useMemo, useState} from "react";
import {Variant} from "react-bootstrap/types";
import {Alert} from "react-bootstrap";

type Props = {
  children: React.ReactNode,
};

type AlertsProps = {
  alerts: PageAlerts,
  clear: Function,
}

function Alerts(props: AlertsProps) {
  const {alerts, clear} = props;

  return Object.values(alerts).map((alert: PageAlert) => (
    <Alert key={alert.id} variant={alert.variant} dismissible onClose={() => clear(alert.id)}>
      {alert.title !== null &&
        <Alert.Heading as={'h5'}>{alert.title}</Alert.Heading>
      }
      {alert.content}
    </Alert>
  ));
}

export default function PageAlertContextWrapper(props: Props) {
  const [alerts, setAlerts] = useState<PageAlerts>({});

  const pushAlert = useCallback((id: string, variant: Variant, title: ?string, content: React.ReactNode) => {
    setAlerts((old) => ({
      ...old,
      [id]: {id, variant, title, content},
    }));
  }, []);

  const clearAll = useCallback(() => {
    setAlerts({});
  }, []);

  const clear = useCallback((id: string) => {
    setAlerts((old) => {
      const newAlerts = {...old};
      delete newAlerts[id];
      return newAlerts;
    });
  }, []);

  const contextValue: PageAlertContextType = useMemo(() => {
    return {alerts, pushAlert, clearAll, clear};
  }, [alerts, pushAlert, clearAll, clear]);

  return (
    <PageAlertContext.Provider value={contextValue}>
      <Alerts alerts={alerts} clear={clear}/>

      {props.children}
    </PageAlertContext.Provider>
  );
}
