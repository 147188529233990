// @flow

import {createContext, useContext} from "react";

export type ApiKeyContextType = {
  value: string,
  clear: Function,
}

export const ApiKeyContext = createContext(null);

export function useApiKey(): ApiKeyContextType {
  return useContext(ApiKeyContext);
}
