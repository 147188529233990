// @flow
import {useParams} from "react-router-dom";
import {Card, Col, Row, Spinner} from "react-bootstrap";
import PageAlertContextWrapper from "../../../Context/WrapperComponent/PageAlertContextWrapper";
import type {AllowedAccounts} from "../../../Hooks/Api/usePublisherAllowedAccountsList";
import {usePublisherAllowedAccountsList} from "../../../Hooks/Api/usePublisherAllowedAccountsList";
import type {UseApiResponseType} from "../../../Helper/Response";
import {ResponseStatus} from "../../../Helper/Response";
import FilterControls from "./FilterControls";
import {useMemo, useState} from "react";
import AccountsEdit from "./AccountsEdit";
import SaveButtons from "./SaveButtons";

export default function PublisherAllowedAccountsEdit() {
  const {id: publisherId} = useParams();

  const data = usePublisherAllowedAccountsList(publisherId);

  return (
    <Card>
      <Card.Header as={'h4'} className="sticky-card-header">
        Freigegebene Accounts für Publisher <code>{publisherId}</code>
      </Card.Header>
      <Card.Body>
        <PageAlertContextWrapper>
          {inner(data, publisherId)}
        </PageAlertContextWrapper>
      </Card.Body>
    </Card>
  );
}

function inner(apiData: UseApiResponseType<AllowedAccounts>, publisherId: string) {
  switch (apiData.status) {
    case ResponseStatus.pending:
      return <PendingIndicator/>;
    case ResponseStatus.ready:
      if (apiData.response.statusCode !== 200) {
        return null;
      }

      return <Content publisherId={publisherId} accountsData={apiData.response.parsedBody}/>;
    case ResponseStatus.error:
      return null;

    default:
      return null;
  }
}

function PendingIndicator() {
  return <Row className={'fade-in-delayed-short'}>
    <Col className={'d-flex justify-content-center'}><Spinner animation={'grow'}/></Col>
    <Col className={'d-flex justify-content-center'}><Spinner animation={'grow'}/></Col>
    <Col className={'d-flex justify-content-center'}><Spinner animation={'grow'}/></Col>
  </Row>
}

type Props = { publisherId: string, accountsData: AllowedAccounts };

function Content(props: Props) {
  const {
    publisherId,
    accountsData,
  } = props;

  const [updatedAccountsData, setUpdatedAccountsData] = useState<AllowedAccounts>(accountsData);

  const [partnerFilter, setPartnerFilter] = useState<?string>(null);
  const [marketFilter, setMarketFilter] = useState<?string>(null);
  const [accountNameSearch, setAccountNameSearch] = useState<string>('');

  const filteredAccounts = useMemo(() => {
    return filterAllowedAccounts(updatedAccountsData, partnerFilter, marketFilter, accountNameSearch);
  }, [updatedAccountsData, marketFilter, partnerFilter, accountNameSearch]);

  const updateAllowed = (partner, market, identifier, allowed) => setUpdatedAccountsData((old: AllowedAccounts) => {
    const newData = {...old};
    const accounts = newData[market][partner];

    // Common-Logik für den Fall, dass mehrere Identifiers gleichzeitig aktualisiert werden.
    const identifiers: Array<string> = Array.isArray(identifier) ? identifier : [identifier];
    for (const identifier of identifiers) {
      const account = accounts.find((a) => a.identifier === identifier);
      if (account !== undefined) {
        account.allowed = allowed;
      }
    }

    // Nicht jedes mal .find() zu nutzen klingt schlauer, wenn man eh mehrere/alle aktualisieren will.
    // Diese Schleife klappt jedoch nicht, vmtl. bekommt man Kopien und keine Referenzen.
    // for (const identifier of identifiers) {
    //   for (const account of accounts) {
    //     console.log(account);
    //     if (account.identifier === identifiers) {
    //       account.allowed = allowed;
    //     }
    //   }
    // }

    return newData;
  });

  return <>
    <Row>
      <Col md={'auto'}>
        <FilterControls
          accountsData={accountsData}
          currentMarket={marketFilter}
          currentPartner={partnerFilter}
          currentAccountName={accountNameSearch}
          onMarketChange={setMarketFilter}
          onPartnerChange={setPartnerFilter}
          onAccountNameChange={setAccountNameSearch}
        />
      </Col>
      <Col>
        <SaveButtons publisherId={publisherId} updatedAccountsData={updatedAccountsData}/>
      </Col>
    </Row>

    {/* TODO: Kann man dem div sagen "so groß wie du brauchst, aber nicht zu groß",
              ohne die max-Höhe selber hardzucoden? */}
    <div className={'pe-4'} style={{overflowY: 'scroll', maxHeight: '82vh', borderTop: 'var(--bs-card-border-width) solid var(--bs-card-border-color)'}}>
      <AccountsEdit accountsData={filteredAccounts} onAllowedChange={updateAllowed}/>
    </div>
  </>
}

function filterAllowedAccounts(
  accountsData: AllowedAccounts,
  partnerFilter: ?string,
  marketFilter: ?string,
  accountNameSearch: string,
): AllowedAccounts {
  if (partnerFilter === null && marketFilter === null && accountNameSearch === '') {
    return accountsData;
  }

  const filtered = {};
  for (const marketKey in accountsData) {
    if (marketFilter !== null && marketKey !== marketFilter) {
      continue;
    }

    const partnerData = accountsData[marketKey];
    const filteredPartnerData = {};
    for (const partnerKey in partnerData) {
      if (partnerFilter !== null && partnerKey !== partnerFilter) {
        continue;
      }

      const accountFiltered = partnerData[partnerKey].filter(
        (account) => account.name.toLowerCase().includes(accountNameSearch.toLowerCase()),
      );

      if (Object.keys(accountFiltered).length === 0) {
        continue;
      }

      filteredPartnerData[partnerKey] = accountFiltered;
    }

    filtered[marketKey] = filteredPartnerData;
  }

  return filtered;
}
