// @flow

import {Button, Card, Modal} from 'react-bootstrap';
import {useState} from "react";

type Props = {};

export default function ModalDebug(props: Props) {
  return (
    <Card className={'mt-4'}>
      <Card.Header>
        <Card.Title as={'h4'}>Modals</Card.Title>
      </Card.Header>
      <Card.Body>
        <Inner/>
      </Card.Body>
    </Card>
  );
}

function Inner() {
  const [show, setShow] = useState<boolean>(false);
  const [size, setSize] = useState<string>('sm');

  return (
    <>
      <Button className={'me-4'} size={'sm'} variant="primary" onClick={() => {setShow(true); setSize('sm')}}>
        Open Modal (sm)
      </Button>

      <Button className={'me-4'} size={'sm'} variant="primary" onClick={() => {setShow(true); setSize('lg')}}>
        Open Modal (lg)
      </Button>

      <Button className={'me-4'} size={'sm'} variant="primary" onClick={() => {setShow(true); setSize('xl')}}>
        Open Modal (xl)
      </Button>

      <Modal show={show} onHide={() => setShow(false)} size={size}>
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Modal body text goes here.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
