// @flow
import {createResponse, defaultApiDataInactive, ResponseStatus} from "../../../Helper/Response";
import FlagBadge from "../../../GenericComponents/FlagBadge";
import {useState} from "react";
import {useApiKey} from "../../../Context/ApiKey";
import {usePageNotificationSystem} from "../../../Context/PageNotification";
import {updateYieldAdsMerchantAuthorityStatus} from "../../../Helper/Api/Merchants/updateYieldAdsMerchantAuthorityStatus";

type Props = {
  initialStatus: boolean,
  domain: string,
  yieldadsMerchantId: string,
};

export default function YieldAdsAuthorityStatus(props: Props) {
  const {initialStatus, domain, yieldadsMerchantId} = props;

  const [yieldAdsActiveOverride, setYieldAdsActiveOverride] = useState<boolean>(initialStatus);
  const [yieldAdsStatusChange, setYieldAdsStatusChange]     = useState(defaultApiDataInactive);

  const notificationSystem = usePageNotificationSystem();
  const apiKey = useApiKey();

  const toggleYieldAdsStatus = () => {
    // Ignorieren, wenn bereits ein Request läuft
    if (yieldAdsStatusChange.status === ResponseStatus.pending) {
      return;
    }

    (async () => {
      const oldStaus = yieldAdsActiveOverride;
      const newStatus = !oldStaus;
      setYieldAdsActiveOverride(newStatus);

      setYieldAdsStatusChange(createResponse(
        null,
        ResponseStatus.pending,
        null,
      ));

      const response = await updateYieldAdsMerchantAuthorityStatus(
        yieldadsMerchantId,
        newStatus,
        apiKey.value,
        notificationSystem.pushNotification,
        () => <>Der Status für den Merchant "<span className={'decoration-underline'}>{domain}</span>" konnte nicht aktualisiert werden</>,
      );

      setYieldAdsStatusChange(response);

      if (response.status === ResponseStatus.error || response.response?.statusCode !== 200) {
        setYieldAdsActiveOverride(oldStaus);
      }
    })();
  };

  return (
    <FlagBadge
      value={yieldAdsActiveOverride}
      textTrue={'Aktiv'}
      textFalse={'Inaktiv'}
      variantForce={yieldAdsStatusChange.status === ResponseStatus.pending ? 'warning' : null}

      onDoubleClick={toggleYieldAdsStatus}
    />
  );
}
