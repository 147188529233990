// @flow
import {uppercaseFirst} from "../Helper/StringHelper";

type Props = {
  responseBody: {
    [string]: any,
  },
};

type Errors = {
  [string]: string,
}

export default function ApiErrorsList(props: Props) {
  const {responseBody} = props;

  const errors: ?Errors = responseBody.errors || null;

  if (errors === null) {
    return (
      <details>
        <summary>Response</summary>
        <pre className={'scroll-thin'} style={{maxHeight: '30vh', overflowY: 'auto', fontFamily: 'monospace'}}>
          {JSON.stringify(responseBody, null, 2)}
        </pre>
      </details>
    );
  }

  return (
    <ul className={'mb-1'}>
      {Object.keys(errors).map((key) => <li key={key}>{uppercaseFirst(key)}: {errors[key]}</li>)}
    </ul>
  );
}
