// @flow
import type {UseApiResponseType} from "../../../Helper/Response";
import type {Account, AccountList} from "../../../Hooks/Api/useAccountList";
import {ResponseStatus} from "../../../Helper/Response";
import {Spinner, Table} from "react-bootstrap";
import AccountsListRow from "./AccountsListRow";
import {useTheme} from "../../../Context/Theme";
import {AiFillEdit} from "react-icons/ai";

type Props = {
  accountList: UseApiResponseType<AccountList>,
};

type RowsProps = {
  accountList: UseApiResponseType<AccountList>,
};

function Rows(props: RowsProps) {
  const {accountList} = props;

  switch (accountList.status) {
    case ResponseStatus.pending:
      return (
        <tr>
          <td rowSpan={2} colSpan={2} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
          <td rowSpan={2} colSpan={3} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
          <td rowSpan={2} colSpan={2} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
        </tr>
      );
    case ResponseStatus.ready:
      if (accountList.response.statusCode !== 200) {
        return null;
      }

      return (
        <>
          {accountList.response.parsedBody.map((account: Account) => {
            return (
              <AccountsListRow
                key={account.identifier}
                account={account}
              />
            );
          })}
        </>
      );
    case ResponseStatus.error:
      return null;

    default:
      return null;
  }
}

export default function AccountsListTable(props: Props) {
  const {accountList} = props;

  const {theme} = useTheme();

  return (
    <Table striped variant={theme.type} hover>
      <thead>
      <tr>
        <td>Name</td>
        <td>Account&nbsp;ID</td>
        <td>Projects</td>
        <td><span><small>(<AiFillEdit/>)</small>{' '}View Traffic Faktor</span></td>
        <td>Partner</td>
        <td>Market</td>
        <td>Actions</td>
      </tr>
      </thead>
      <tbody>
      <Rows accountList={accountList}/>
      </tbody>
    </Table>
  );
}
