import {Card, Col, Spinner} from "react-bootstrap";

export function renderPending(blacklistIsLoading: boolean) {

  if (blacklistIsLoading === true) {
    const pendingCards = [0, 1, 2, 3];
    return <tr>{(pendingCards.map((index) => (
      <td key={'cardbody' + index }>
        <Card.Body>
          <Spinner size={'sm'} variant="light" animation={'grow'}/>
        </Card.Body>
      </td>
    )))}</tr>;
  }
  return '';
}

export function renderSpinner(isLoading: boolean) {
  // das mr-5 funktioniert nicht, daher style={{'margin-right':'20px'}}
  if (isLoading === true) {
    return <Col className={'p-0 mb-0 ml-0 mt-0 mr-5'} style={{'marginRight':'20px'}}>
    <Spinner size={'sm mt-3'} variant="light" animation={'grow'}/>
    </Col>
  }
  return '';
}

export function renderEmpty(merchantList: Array, blacklistIsLoading: boolean, selectedPublisherId: string) {
  let messageText = 'Es gibt bisher keine Blacklist-Einträge';
  if (selectedPublisherId === null) {
    messageText = 'Bitte wählen sie einen Publisher aus';
  }
  if (!Array.isArray(merchantList) || !(merchantList.length > 0) && !(blacklistIsLoading === true && !selectedPublisherId === undefined)) {
    return <tr>
      <td colSpan={4} className={'p-2'}>
        {messageText}
      </td>
    </tr>;
  }
  return '';
}