// @flow
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import logo from "./logo.png";

type Props = {};

export default function NavigationHead(props: Props) {
  return (
    <Row className={'navigation-header'}>
      <Col>
        <Link to={'/dashboard'} className={'media navigation-link'}>
            <span className={'media-left'}>
              <img src={logo} alt="Logo"/>
            </span>
          <div className={'navigation-title mt-auto'}>
            <h5>Yieldads</h5>
          </div>
        </Link>
      </Col>
    </Row>
  );
}
