// @flow
import NavigationHead from "./NavigationHead";
import NavigationLinks from "./NavigationLinks";
import ThemeSwitcher from "./ThemeSwitcher";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import Logout from "./Logout";
import {Col, Row} from "react-bootstrap";

type Props = {};

export default function Navigation(props: Props) {
  const {pathname} = useLocation();
  const navigate   = useNavigate();

  useEffect(() => {
    if (pathname === '/') {
      navigate('/dashboard');
    }
  }, [pathname, navigate]);

  return (
    <div className={'navigation'}>
      <NavigationHead/>
      <NavigationLinks/>
      <div className={'navigation-bottom'}>
        {/*<AppEnvSwitcher/>*/}

        <Row>
          <Col>
            <ThemeSwitcher/>
          </Col>
          <Col>
            <Logout/>
          </Col>
        </Row>
      </div>
    </div>
  );
}
