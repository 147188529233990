import {createNotification} from "../../../Context/PageNotification";
import {handlePartnerMerchantUpdateRequest} from "./handlePartnerMerchantUpdateRequest";
import {updateMerchantIdPriority} from "./updateMerchantIdPriority";
import {FormEvent} from "react";
import type {ApiResponseStatus, UseApiResponseType} from "../../Response";
import type {PartnerMerchant} from "../../../Hooks/Api/useMerchantList";

export const handlePartnerMerchantPriorityEdit = async (
    event: FormEvent<HTMLFormElement>,
    setEditable: (value: boolean) => void,
    apiStatus: ApiResponseStatus,
    setApiStatus: (status: UseApiResponseType) => void,
    apiKey: string,
    notificationPusher: Function,
    merchantState: PartnerMerchant,
    setMerchantState: (merchant: PartnerMerchant) => void,
) => {
    event.preventDefault();
    const target: HTMLFormElement = event.target;

    // onBlur vom Input || onSubmit von der Form
    const input = target.value || target.elements[0].value;
    const newPriority = parseInt(input);
    if (isNaN(newPriority)) {
        notificationPusher(
            createNotification(
                'priority-input-error',
                'Invalid Value',
                `Der eingegebene Wert (${input}) ist keine gültige Ganzzahl!`
            )
        );
        return;
    }

    if (newPriority !== merchantState.priority) {
        const isOk = await handlePartnerMerchantUpdateRequest(
            updateMerchantIdPriority,
            apiStatus,
            setApiStatus,
            apiKey,
            notificationPusher,
            merchantState.partnerMerchantId,
            newPriority
        );
        if (isOk) {
            setMerchantState({ ...merchantState, priority: newPriority});
            setEditable(false);
        }
    }
}
