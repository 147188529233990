// @flow
export function uppercaseFirst(input: string): string {
  return input.charAt(0).toUpperCase() + input.substring(1);
}

export function boolToString(value: boolean): string {
  return value ? 'true' : 'false';
}

const alphabetAlnum = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
export function randomStringAlnum(length: number): string {
  let result = '';

  for (let _ = 0; _ < length; _++) {
    const index = Math.floor(Math.random() * alphabetAlnum.length);
    result += alphabetAlnum.charAt(index);
  }

  return result;
}

export function hashCode(input: string) {
  let hash = 0;

  for (let i = 0, len = input.length; i < len; i++) {
    let charCode = input.charCodeAt(i);
    hash = (hash << 5) - hash + charCode;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
}
