// @flow
// noinspection GrazieInspection

import {useApiGet} from "./useApi";
import {UseApiResponseType} from "../../Helper/Response";
import type {OnApiErrorCallback} from "../../Helper/Api/fetchApi";
import type {SortingDirection} from "../../Helper/Sorting";
import type {ViewTrafficStatusCode} from "../../Helper/ViewTrafficStatus";

export type PartnerMerchant = {
  partnerMerchantId: string,
  yieldadsMerchantId: string,
  identifier: string,
  market: string,
  domain: string,
  externalMerchantId: number,
  priority: number,
  lastUpdated: string,
  partnerStatus: boolean,
  yieldadsStatus: boolean,
  whitelistStatus: boolean,
  whitelistCreatedDate: string,
  viewTrafficStatus: ViewTrafficStatusCode,
  viewTrafficStatusUpdateDate: string,
} | {
  name: string,
  value: string,
};

export type MerchantList = {
  filteredCount: ?number,
  merchants: Array<PartnerMerchant>,
};

export function useIgnoredMerchantList(
  page: number,
  pageSize: ?number,
  sortingField: string,
  sortingDirection: ?SortingDirection,
  domain: ?string,
  market: ?string,
  projectIdentifier: ?string,
  viewTrafficStatus: ?ViewTrafficStatusCode,
  yieldadsMerchantId: ?string = null,
  onError: ?OnApiErrorCallback = null,
): UseApiResponseType<MerchantList> {
  const query = {
    page: page,
    pageSize: pageSize,
    sortField: sortingField,
    sortDirection: sortingDirection,
    merchantDomain: domain,
    market: market,
    identifier: projectIdentifier,
    viewTrafficStatus: viewTrafficStatus,
    yieldadsMerchantId: yieldadsMerchantId,
  };

  return useApiGet<MerchantList>('/ignored-merchants', query, onError);
}
