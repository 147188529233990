// @flow

export type ViewTrafficStatusCode = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export const viewTrafficStatusCode = {
  unknown: 0,

  enabledByBackend: 1,
  disabledByBackend: 2,

  enabledByImport: 3,
  disabledByImport: 4,

  enabledByEvaluation: 5,
  disabledByEvaluation: 6,

  enabledByEvaluationResult: 7,
  disabledByEvaluationResult: 8,

  enabledByCommissionImport: 9,

  waitingForEvaluation: 10,
};

// const mapCodeToName = new Map(Object.keys(viewTrafficStatusCode).map((key) => [viewTrafficStatusCode[key], key]));
export const viewTrafficStatusCodeNames = {
  [viewTrafficStatusCode.unknown]: 'Unknown',

  [viewTrafficStatusCode.enabledByBackend]: 'Enabled by Backend',
  [viewTrafficStatusCode.disabledByBackend]: 'Disabled by Backend',

  [viewTrafficStatusCode.enabledByImport]: 'Enabled by Import',
  [viewTrafficStatusCode.disabledByImport]: 'Disabled by Import',

  [viewTrafficStatusCode.enabledByEvaluation]: 'Enabled by Evaluation',
  [viewTrafficStatusCode.disabledByEvaluation]: 'Disabled by Evaluation',

  [viewTrafficStatusCode.enabledByEvaluationResult]: 'Enabled by Evaluation Result',
  [viewTrafficStatusCode.disabledByEvaluationResult]: 'Disabled by Evaluation Result',

  [viewTrafficStatusCode.enabledByCommissionImport]: 'Enabled by Commission Import',

  [viewTrafficStatusCode.waitingForEvaluation]: 'Waiting for Evaluation',
};

export function viewTrafficStatusToName(statusCode: ViewTrafficStatusCode): string {
  return viewTrafficStatusCodeNames[statusCode] || viewTrafficStatusCodeNames[viewTrafficStatusCode.unknown];
}

export function isViewTrafficActive(statusCode: ViewTrafficStatusCode): boolean {
  switch (statusCode) {
    case viewTrafficStatusCode.enabledByBackend:
    case viewTrafficStatusCode.enabledByImport:
    case viewTrafficStatusCode.enabledByEvaluation:
    case viewTrafficStatusCode.enabledByEvaluationResult:
    case viewTrafficStatusCode.enabledByCommissionImport:
      return true;

    case viewTrafficStatusCode.disabledByBackend:
    case viewTrafficStatusCode.disabledByImport:
    case viewTrafficStatusCode.disabledByEvaluation:
    case viewTrafficStatusCode.disabledByEvaluationResult:
    case viewTrafficStatusCode.waitingForEvaluation:
      return false;

    default:
      console.info(`Unknown ViewTrafficStatus Code (${statusCode})`);
      return false;
  }
}

export function isEvaluating(statusCode: ViewTrafficStatusCode): boolean {
  switch (statusCode) {
    case viewTrafficStatusCode.enabledByEvaluation:
    case viewTrafficStatusCode.disabledByEvaluation:
    case viewTrafficStatusCode.waitingForEvaluation:
      return true;
    case viewTrafficStatusCode.enabledByBackend:
    case viewTrafficStatusCode.enabledByImport:
    case viewTrafficStatusCode.enabledByEvaluationResult:
    case viewTrafficStatusCode.disabledByBackend:
    case viewTrafficStatusCode.disabledByImport:
    case viewTrafficStatusCode.disabledByEvaluationResult:
    case viewTrafficStatusCode.enabledByCommissionImport:
      return false;
    default:
      console.info(`Unknown ViewTrafficStatus Code (${statusCode})`);
      return false;
  }
}

export function canBeRetried(statusCode: ViewTrafficStatusCode): boolean {
  switch (statusCode) {
    case viewTrafficStatusCode.enabledByEvaluation:
    case viewTrafficStatusCode.disabledByEvaluation:
    case viewTrafficStatusCode.waitingForEvaluation:
    case viewTrafficStatusCode.disabledByImport:
      return false;
    case viewTrafficStatusCode.enabledByBackend:
    case viewTrafficStatusCode.enabledByImport:
    case viewTrafficStatusCode.enabledByEvaluationResult:
    case viewTrafficStatusCode.disabledByBackend:
    case viewTrafficStatusCode.disabledByEvaluationResult:
    case viewTrafficStatusCode.enabledByCommissionImport:
      return true;
    default:
      console.info(`Unknown ViewTrafficStatus Code (${statusCode})`);
      return false;
  }
}
