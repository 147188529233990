// @flow

import {Button} from "react-bootstrap";
import {fetchApiDelete} from "../../../Helper/Api/fetchApi";
import {usePageNotificationSystem} from "../../../Context/PageNotification";
import {useApiKey} from "../../../Context/ApiKey";
import {BsFillTrashFill} from "react-icons/bs";

type Props = {
  yieldAdsMerchantId: string,
  whitelistStatus: boolean,
  domain: string,
  refresh: () => void,
};

export default function DeleteFromWhitelistButton(props: Props) {
  const {
    yieldAdsMerchantId,
    whitelistStatus,
    domain,
    refresh,
  } = props;

  const {pushNotification} = usePageNotificationSystem();
  const apiKey = useApiKey().value;

  async function deleteFromWhitelist() {
    if (!window.confirm('Eintrag für ' + domain + ' wirklich entfernen?')) {
      return;
    }

    await fetchApiDelete(`/merchant-whitelist/${yieldAdsMerchantId}`, apiKey, {}, null, null, pushNotification);
    refresh();
  }

  return (
    <Button
      className={'ms-3'}
      title={'Von der Whitelist Löschen'}
      hidden={!whitelistStatus}
      variant={'danger'}
      size={'sm'}
      onClick={deleteFromWhitelist}
    ><BsFillTrashFill /></Button>
  );
}
