import {fetchApiPatch, OnApiErrorCallback} from "../fetchApi";
import {createResponse, ResponseStatus, UseApiResponseType} from "../../Response";

export async function updatePublisherAllowedAccounts(
  publisherID: string,
  identifiers: Array<string>,
  apiKey: string,
  pushNotification: ?Function = null,
  onError: ?OnApiErrorCallback = null,
): UseApiResponseType<any> {
  try {
    const rawResponse = await fetchApiPatch(`/publishers/${publisherID}/accounts`, apiKey, identifiers, null, null, pushNotification, onError);
    return createResponse(
      rawResponse,
      ResponseStatus.ready,
      null,
    );
  } catch (error) {
    return createResponse(
      null,
      ResponseStatus.error,
      error.message,
    );
  }
}
