// @flow
import {Card} from "react-bootstrap";
import "react-select-search/style.css"
import React from "react";
import {TiInfoLargeOutline} from "react-icons/ti";

type Props = {};

export default function Infos(props: Props) {
    const {
        show,
    } = props;

    if (!show) {
        return null;
    }

    return (
      <div id="info">
          <Card>
              <Card.Body style={{paddingTop: '10px', paddingBottom: '6px'}}>
                  <TiInfoLargeOutline style={{float: 'left'}} color={'yellow'} />
                  <div style={{float: 'left', marginLeft: '10px', marginTop: '-4px'}}>
                      Payments can only be created since the third day of month because commissions for Advanced Store are marked as paid on the second day of month.
                  </div>
              </Card.Body>
          </Card>

          <Card style={{marginTop: '4px'}}>
              <Card.Body style={{paddingTop: '10px', paddingBottom: '6px'}}>
                  <TiInfoLargeOutline style={{float: 'left'}} color={'yellow'} />
                  <div style={{float: 'left', marginLeft: '10px', marginTop: '-4px'}}>
                      Payments before November 2023 have been created manually and will not be shown in the list.
                  </div>
              </Card.Body>
          </Card>
      </div>
    );
}
