import {Button} from "react-bootstrap";

type Props = {
    mustDisplayConfirmDialog: boolean,
    onClickFunctionality: Function,
    isDisabled: bool,
};

export default function ConfirmDialog(props: Props) {
    const {mustDisplayConfirmDialog, onClickFunctionality, isDisabled} = props;

    if (mustDisplayConfirmDialog === true) {
        return (
            <div id={"confirmDialog"}>
                <p>Do you want to execute this Payment?</p>
                <Button
                    id={"confirmButton"}
                    size={'sm'}
                    variant={'success'}
                    className={'me-2'}
                    onClick={onClickFunctionality}
                    disabled={isDisabled}
                >
                    Confirm Payment
                </Button>
            </div>
        )
    }
    return '';
}
