// @flow
import {Button} from "react-bootstrap";
import {usePageNotificationSystem} from "../../../Context/PageNotification";
import {useEffect, useState} from "react";
import NotificationToast from "../../../GenericComponents/NotificationToast";

type Props = {};

export default function ClickCounterUseEffectNotification(props: Props) {
  const {pushNotification} = usePageNotificationSystem();

  const [clicks, setClicks] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (!show) {
      return;
    }

    pushNotification(
      <NotificationToast id={'debug-click-counter-useEffect'} title={'Click Counter'}>
        <div className={'mb-2'}>Closing this toast will preserve its state.</div>
        <div className={'mb-2'}>You clicked {clicks} time{clicks === 1 ? '' : 's'}</div>
        <Button size={'sm'} variant={'danger'}  className={'me-2'} onClick={() => setClicks(0)}>Reset</Button>
        <Button size={'sm'} variant={'success'} className={'me-2'} onClick={() => setClicks((old) => old + 1)}>+1</Button>
      </NotificationToast>
    );
  }, [show, clicks, pushNotification]);

  return (
      <Button
        onClick={() => {
          setShow(true);
          setClicks((old) => old + 1);
        }}
      >Click Counter useEffect Toast</Button>
  );
}
