// @flow
import React from "react";
import {TiInfoLarge} from "react-icons/ti";
import TimedTextInput from "../../../GenericComponents/Input/TimedTextInput";
import type {RowProps} from "./PostbackFormTypes";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";


export default function FormTableRow({label, name, value, error, information, onChange}: RowProps) {
  const handleChange = (value) => {
    onChange(name, value);
  };

  return (
    <tr>
      <td className={"col-4"}>
        <OverlayTrigger
          placement={'left'}
          overlay={
            <Tooltip>
              {information}
            </Tooltip>
          }
        >
          <div>
            <TiInfoLarge/>
            {label}
          </div>
        </OverlayTrigger>

      </td>
      <td className={"col-8"}>
        <TimedTextInput
          name={name}
          defaultValue={value}
          size={'sm'}
          placeholder={name}
          onChange={handleChange}
        />
        {error && <div className="error-text color-orange">{error}</div>}
      </td>
    </tr>
  );
}
