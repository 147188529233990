// @flow
import {useEffect, useMemo, useState} from "react";
import type {AppEnvContextType, AppEnvType} from "../AppEnvContext";
import {AppEnv, AppEnvContext} from "../AppEnvContext";
import {Env} from "../../Helper/Env";

type Props = {
  children: React.ReactNode,
};

export default function AppEnvContextWrapper(props: Props) {
  const [appEnv, setAppEnv] = useState<string>(Env.AppEnv || 'production');
  const appEnvContextValue: AppEnvContextType = useMemo(() => ({
    value: appEnv,
    _realValue: Env._RealAppEnv || 'production',
    override: (newValue: AppEnvType) => {
      Env.AppEnv = newValue;
      setAppEnv(newValue);
    },
  }), [appEnv]);

  useEffect(() => {
    // noinspection JSUnusedGlobalSymbols
    window.appEnv = {
      override: (newEnv: string = '') => {
        if (!AppEnv.hasOwnProperty(newEnv)) {
          const envsConcat = Object.values(AppEnv).join(', ');
          console.info(`Use one of these: %c${envsConcat}`, 'font-style: italic');
          return;
        }

        appEnvContextValue.override(newEnv);
      },
      restore: () => {
        appEnvContextValue.override(appEnvContextValue._realValue);
      }
    };

    return () => {window.appEnv = undefined};
  }, [appEnvContextValue]);

  return (
    <AppEnvContext.Provider value={appEnvContextValue}>
      {props.children}
    </AppEnvContext.Provider>
  );
}
