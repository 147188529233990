// @flow
import {Alert} from "react-bootstrap";
import {useState} from "react";

type ErrorObject = {
  name: string,
  message: string,
};

type Props = {
  errors: ErrorObject[],
  title?: React.ReactNode,
  className?: string,
  onClose?: Function,
};

export default function MultiErrorBox(props: Props) {
  const {
    errors,
    title = null,
    className = null,
    onClose = null,
    dismissible = true,
  } = props;

  const [dismissed, setDismissed] = useState<boolean>(false);

  if (dismissed) {
    return null;
  }

  const heading = title === null ? null : <Alert.Heading as={'h5'}>{title}</Alert.Heading>

  const onDismiss = () => {
    if (onClose) {
      onClose();
    }

    setDismissed(true);
  };

  if (errors === undefined || Object.keys(errors).length === 0 || errors === null || errors.length === 0) {
    return (<span/>);
  }

  let errorString = '';
  for (const field in errors) {
    errorString += field + ': ' + '"' + errors[field] + '"\n ';
  }

  return (
    <Alert className={`mb-3 ${className}`} variant={'danger'} dismissible={dismissible} onClose={onDismiss}>
      {heading}
      {errorString}
    </Alert>
  );
}
