// @flow
import {useState} from "react";
import {Badge, Form} from "react-bootstrap";
import {FormEvent} from "react";

type Props = {
  priority: number,
  pending: boolean,
  updatePriority: (event: FormEvent<HTMLFormElement>, setEditable: (value: boolean) => void) => void,
  isEditable: boolean,
};

export default function Priority(props: Props) {
  const {
    priority,
    pending,
    updatePriority,
    isEditable = false,
  } = props;

  const [editable, setEditable] = useState<boolean>(false);

  if (editable) {
    return (
      <Form onSubmit={(event) => updatePriority(event, setEditable)}>
        <Form.Control
          type={'number'}
          size={'sm'}
          defaultValue={priority}
          style={{maxWidth: '100%'}}
          onBlur={(event) => updatePriority(event, setEditable())}
          autoFocus={true}
        />
      </Form>
    );
  }

  if (pending) {
    return <Badge bg={'warning'}>...</Badge>
  }

  if (isEditable) {
    return (
      <div className={'cursor-pointer'} onDoubleClick={() => setEditable(true)}>
        {priority}
      </div>
    );
  }

  return <div>{priority}</div>;
}
