// @flow

import {Col} from "react-bootstrap";
import SelectSearch from "react-select-search";

type Props = {
    selectedYearMonth: string,
    setSelectedFromYearMonth: Function,
    setSelectedToYearMonth: Function,
    options: Array,
    setReloadPaymentList: Function,
    disabled: boolean,
};

export default function YearMonthFilter(props: Props) {
    const {
        selectedYearMonth,
        setSelectedFromYearMonth,
        setSelectedToYearMonth,
        options,
        setReloadPaymentList,
        disabled,
    } = props;

    return (
        <Col className={'mb-4'}>
            <SelectSearch
                style={{display:"inline-block"}}
                name="yearSelect"
                placeholder="Select month"
                search={false}
                options={options}
                onChange={yearMonth => {
                    const selectedOption = options.find(option => option.value === yearMonth);
                    setSelectedFromYearMonth(selectedOption.from);
                    setSelectedToYearMonth(selectedOption.to);
                    setReloadPaymentList((old: number) => old + 1);
                }}
                value={selectedYearMonth}
                disabled={disabled}
            />
        </Col>
    );
}
