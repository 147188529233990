// @flow
import {useState} from "react";
import {createResponse, defaultApiDataInactive, ResponseStatus} from "../../../Helper/Response";
import {Badge, Form} from "react-bootstrap";
import {usePageNotificationSystem} from "../../../Context/PageNotification";
import NotificationToast from "../../../GenericComponents/NotificationToast";
import {useApiKey} from "../../../Context/ApiKey";
import {updateAccountViewTrafficFactor} from "../../../Helper/Api/Project/updateAccountViewTrafficFactor";

type Props = {
  initialFactor: number,
  identifier: string,
};

export default function ViewTrafficFactor({initialFactor, identifier}: Props) {
  const [factor, setFactor] = useState<boolean>(initialFactor);
  const [editable, setEditable] = useState<boolean>(false);
  const [priorityChange, setPriorityChange] = useState(defaultApiDataInactive);

  const notificationSystem = usePageNotificationSystem();
  const apiKey = useApiKey();

  const sendPriorityUpdateToApi = (newPriority: number) => {
    // Ignorieren, wenn bereits ein Request läuft
    if (priorityChange.status === ResponseStatus.pending) {
      return;
    }

    (async () => {
      const oldFactor = factor;
      setFactor(newPriority);

      setPriorityChange(createResponse(
        null,
        ResponseStatus.pending,
        null,
      ));

      // const response = await updateShopPriority(partnerMerchantId, newPriority, apiKey.value);
      const response = await updateAccountViewTrafficFactor(
        identifier,
        newPriority,
        apiKey.value,
        notificationSystem.pushNotification,
        () => <>
            Der View Traffic Faktor für den Account "<span className={'decoration-underline'}>{identifier}</span>"
            konnte nicht aktualisiert werden
          </>,
      );

      setPriorityChange(response);

      if (response.status === ResponseStatus.error || response.response?.statusCode !== 200) {
        setFactor(oldFactor);
      }
    })();
  };

  if (editable) {
    const onConfirm = (event) => {
      event.preventDefault();

      setEditable(false);

      // onBlur vom Input || onSubmit von der Form
      const input = event.target.value || event.target.elements[0].value;
      const newFactor = parseFloat(input);
      if (isNaN(newFactor)) {
        notificationSystem.pushNotification(
          <NotificationToast id={'viewtraffic-input-error'} title={'Invalid Value'}>
            Der eingegebene Wert ({input}) ist keine gültige Ganzzahl!
          </NotificationToast>
        );
        return;
      }

      if (newFactor !== factor) {
        sendPriorityUpdateToApi(newFactor);
      }
    };

    return (
      <Form onSubmit={onConfirm}>
        <Form.Control
          type={'number'}
          step={'0.25'}
          size={'sm'}
          min={0}
          defaultValue={factor}
          style={{maxWidth: '100%'}}
          onBlur={onConfirm}
          autoFocus={true}
        />
      </Form>
    );
  }

  if (priorityChange.status === ResponseStatus.pending) {
    return <Badge bg={'warning'}>{factor}</Badge>
  }

  return (
    <div className={'cursor-pointer'} onDoubleClick={() => setEditable(true)}>
      {factor}
    </div>
  );
}
