// @flow
import "react-select-search/style.css"
import {useState} from "react";
import MerchantBlacklistList from "./MerchantBlacklistList";

type Props = {};

export default function MerchantBlacklistListErrorStateHandler(props: Props) {
  const [errors, setErrors] = useState<?string>([]);

  const addError = (field: string, message: string ) => {
    let currentState = errors;
    currentState[field] = message;
    setErrors({...currentState});
  };

  const removeError = (field: string) => {
    let currentState = errors;

    let newState = [];
    for (const localField in errors) {
      if (localField === field) {
        delete currentState[field];
      }
    }

    setErrors({...newState});
  };
  
  return (
    <MerchantBlacklistList errors={errors} addError={addError} removeError={removeError} />
  );
}
