// @flow

import {partnerAbbreviationToFullName} from "../Helper/Partner";

type Props = {
  partner: string,
};

export default function PartnerAbbreviation(props: Props) {
  const {
    partner,
  } = props;

  return (
    <abbr title={partnerAbbreviationToFullName(partner)}>
      {partner}
    </abbr>
  );
}
