// @flow
import {Card} from "react-bootstrap";
import TimerNotification from "./TimerNotification";
import ClickCounterOnClickNotification from "./ClickCounterOnClickNotification";
import ClickCounterUseEffectNotification from "./ClickCounterUseEffectNotification";
import RandomNotification from "./RandomNotification";
import DedicatedComponentNotification from "./DedicatedComponentNotification";
import NotificationVariants from "./NotificationVariants";
import TemporaryNotification from "./TemporaryNotification";

type Props = {};

export default function NotificationDebug(props: Props) {
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title as={'h4'}>Notification Toasts</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className={'mb-2'}><TimerNotification/></div>
          <div className={'mb-2'}><ClickCounterOnClickNotification/></div>
          <div className={'mb-2'}><ClickCounterUseEffectNotification/></div>
          <div className={'mb-2'}><RandomNotification/></div>
          <div className={'mb-2'}><DedicatedComponentNotification/></div>
          <div className={'mb-2'}><NotificationVariants/></div>
          <div className={'mb-2'}><TemporaryNotification/></div>
        </Card.Body>
      </Card>
    </>
  );
}
