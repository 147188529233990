// @flow
import {alpha2ToFullGerman} from "../Helper/Market";

type Props = {
  market: string,
};

export default function MarketAbbreviation(props: Props) {
  const {
    market,
  } = props;

  return (
    <abbr title={alpha2ToFullGerman(market)}>
      {market}
    </abbr>
  );
}
