/*
// Based on https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2, manually adjusted.
let output = '';
const table = document.querySelector('#mw-content-text > div.mw-content-ltr.mw-parser-output > table:nth-child(25) > tbody')
for (const child of table.children) {
    const alpha2 = child.children[0].id;
    const long = child.children[1].innerText.replaceAll('\'', '\\\'')
    const entry = (`${alpha2}: '${long}',\n`);
    output += entry;
}

console.log(output);
 */
// const mappingAlpha2En = {
//   AD: 'Andorra',
//   AE: 'United Arab Emirates',
//   AF: 'Afghanistan',
//   AG: 'Antigua and Barbuda',
//   AI: 'Anguilla',
//   AL: 'Albania',
//   AM: 'Armenia',
//   AO: 'Angola',
//   AQ: 'Antarctica',
//   AR: 'Argentina',
//   AS: 'American Samoa',
//   AT: 'Austria',
//   AU: 'Australia',
//   AW: 'Aruba',
//   AX: 'Åland Islands',
//   AZ: 'Azerbaijan',
//   BA: 'Bosnia and Herzegovina',
//   BB: 'Barbados',
//   BD: 'Bangladesh',
//   BE: 'Belgium',
//   BF: 'Burkina Faso',
//   BG: 'Bulgaria',
//   BH: 'Bahrain',
//   BI: 'Burundi',
//   BJ: 'Benin',
//   BL: 'Saint Barthélemy',
//   BM: 'Bermuda',
//   BN: 'Brunei Darussalam',
//   BO: 'Bolivia, Plurinational State of',
//   BQ: 'Bonaire, Sint Eustatius and Saba',
//   BR: 'Brazil',
//   BS: 'Bahamas',
//   BT: 'Bhutan',
//   BV: 'Bouvet Island',
//   BW: 'Botswana',
//   BY: 'Belarus',
//   BZ: 'Belize',
//   CA: 'Canada',
//   CC: 'Cocos (Keeling) Islands',
//   CD: 'Congo, Democratic Republic of the',
//   CF: 'Central African Republic',
//   CG: 'Congo',
//   CH: 'Switzerland',
//   CI: 'Côte d\'Ivoire',
//   CK: 'Cook Islands',
//   CL: 'Chile',
//   CM: 'Cameroon',
//   CN: 'China',
//   CO: 'Colombia',
//   CR: 'Costa Rica',
//   CU: 'Cuba',
//   CV: 'Cabo Verde',
//   CW: 'Curaçao',
//   CX: 'Christmas Island',
//   CY: 'Cyprus',
//   CZ: 'Czechia',
//   DE: 'Germany',
//   DJ: 'Djibouti',
//   DK: 'Denmark',
//   DM: 'Dominica',
//   DO: 'Dominican Republic',
//   DZ: 'Algeria',
//   EC: 'Ecuador',
//   EE: 'Estonia',
//   EG: 'Egypt',
//   EH: 'Western Sahara',
//   ER: 'Eritrea',
//   ES: 'Spain',
//   ET: 'Ethiopia',
//   FI: 'Finland',
//   FJ: 'Fiji',
//   FK: 'Falkland Islands (Malvinas)',
//   FM: 'Micronesia, Federated States of',
//   FO: 'Faroe Islands',
//   FR: 'France',
//   GA: 'Gabon',
//   GB: 'United Kingdom of Great Britain and Northern Ireland',
//   GD: 'Grenada',
//   GE: 'Georgia',
//   GF: 'French Guiana',
//   GG: 'Guernsey',
//   GH: 'Ghana',
//   GI: 'Gibraltar',
//   GL: 'Greenland',
//   GM: 'Gambia',
//   GN: 'Guinea',
//   GP: 'Guadeloupe',
//   GQ: 'Equatorial Guinea',
//   GR: 'Greece',
//   GS: 'South Georgia and the South Sandwich Islands',
//   GT: 'Guatemala',
//   GU: 'Guam',
//   GW: 'Guinea-Bissau',
//   GY: 'Guyana',
//   HK: 'Hong Kong',
//   HM: 'Heard Island and McDonald Islands',
//   HN: 'Honduras',
//   HR: 'Croatia',
//   HT: 'Haiti',
//   HU: 'Hungary',
//   ID: 'Indonesia',
//   IE: 'Ireland',
//   IL: 'Israel',
//   IM: 'Isle of Man',
//   IN: 'India',
//   IO: 'British Indian Ocean Territory',
//   IQ: 'Iraq',
//   IR: 'Iran, Islamic Republic of',
//   IS: 'Iceland',
//   IT: 'Italy',
//   JE: 'Jersey',
//   JM: 'Jamaica',
//   JO: 'Jordan',
//   JP: 'Japan',
//   KE: 'Kenya',
//   KG: 'Kyrgyzstan',
//   KH: 'Cambodia',
//   KI: 'Kiribati',
//   KM: 'Comoros',
//   KN: 'Saint Kitts and Nevis',
//   KP: 'Korea, Democratic People\'s Republic of',
//   KR: 'Korea, Republic of',
//   KW: 'Kuwait',
//   KY: 'Cayman Islands',
//   KZ: 'Kazakhstan',
//   LA: 'Lao People\'s Democratic Republic',
//   LB: 'Lebanon',
//   LC: 'Saint Lucia',
//   LI: 'Liechtenstein',
//   LK: 'Sri Lanka',
//   LR: 'Liberia',
//   LS: 'Lesotho',
//   LT: 'Lithuania',
//   LU: 'Luxembourg',
//   LV: 'Latvia',
//   LY: 'Libya',
//   MA: 'Morocco',
//   MC: 'Monaco',
//   MD: 'Moldova, Republic of',
//   ME: 'Montenegro',
//   MF: 'Saint Martin (French part)',
//   MG: 'Madagascar',
//   MH: 'Marshall Islands',
//   MK: 'North Macedonia',
//   ML: 'Mali',
//   MM: 'Myanmar',
//   MN: 'Mongolia',
//   MO: 'Macao',
//   MP: 'Northern Mariana Islands',
//   MQ: 'Martinique',
//   MR: 'Mauritania',
//   MS: 'Montserrat',
//   MT: 'Malta',
//   MU: 'Mauritius',
//   MV: 'Maldives',
//   MW: 'Malawi',
//   MX: 'Mexico',
//   MY: 'Malaysia',
//   MZ: 'Mozambique',
//   NA: 'Namibia',
//   NC: 'New Caledonia',
//   NE: 'Niger',
//   NF: 'Norfolk Island',
//   NG: 'Nigeria',
//   NI: 'Nicaragua',
//   NL: 'Netherlands, Kingdom of the',
//   NO: 'Norway',
//   NP: 'Nepal',
//   NR: 'Nauru',
//   NU: 'Niue',
//   NZ: 'New Zealand',
//   OM: 'Oman',
//   PA: 'Panama',
//   PE: 'Peru',
//   PF: 'French Polynesia',
//   PG: 'Papua New Guinea',
//   PH: 'Philippines',
//   PK: 'Pakistan',
//   PL: 'Poland',
//   PM: 'Saint Pierre and Miquelon',
//   PN: 'Pitcairn',
//   PR: 'Puerto Rico',
//   PS: 'Palestine, State of',
//   PT: 'Portugal',
//   PW: 'Palau',
//   PY: 'Paraguay',
//   QA: 'Qatar',
//   RE: 'Réunion',
//   RO: 'Romania',
//   RS: 'Serbia',
//   RU: 'Russian Federation',
//   RW: 'Rwanda',
//   SA: 'Saudi Arabia',
//   SB: 'Solomon Islands',
//   SC: 'Seychelles',
//   SD: 'Sudan',
//   SE: 'Sweden',
//   SG: 'Singapore',
//   SH: 'Saint Helena, Ascension and Tristan da Cunha',
//   SI: 'Slovenia',
//   SJ: 'Svalbard and Jan Mayen',
//   SK: 'Slovakia',
//   SL: 'Sierra Leone',
//   SM: 'San Marino',
//   SN: 'Senegal',
//   SO: 'Somalia',
//   SR: 'Suriname',
//   SS: 'South Sudan',
//   ST: 'Sao Tome and Principe',
//   SV: 'El Salvador',
//   SX: 'Sint Maarten (Dutch part)',
//   SY: 'Syrian Arab Republic',
//   SZ: 'Eswatini',
//   TC: 'Turks and Caicos Islands',
//   TD: 'Chad',
//   TF: 'French Southern Territories',
//   TG: 'Togo',
//   TH: 'Thailand',
//   TJ: 'Tajikistan',
//   TK: 'Tokelau',
//   TL: 'Timor-Leste',
//   TM: 'Turkmenistan',
//   TN: 'Tunisia',
//   TO: 'Tonga',
//   TR: 'Türkiye',
//   TT: 'Trinidad and Tobago',
//   TV: 'Tuvalu',
//   TW: 'Taiwan, Province of China',
//   TZ: 'Tanzania, United Republic of',
//   UA: 'Ukraine',
//   UG: 'Uganda',
//   UM: 'United States Minor Outlying Islands',
//   US: 'United States of America',
//   UY: 'Uruguay',
//   UZ: 'Uzbekistan',
//   VA: 'Holy See',
//   VC: 'Saint Vincent and the Grenadines',
//   VE: 'Venezuela, Bolivarian Republic of',
//   VG: 'Virgin Islands (British)',
//   VI: 'Virgin Islands (U.S.)',
//   VN: 'Viet Nam',
//   VU: 'Vanuatu',
//   WF: 'Wallis and Futuna',
//   WS: 'Samoa',
//   YE: 'Yemen',
//   YT: 'Mayotte',
//   ZA: 'South Africa',
//   ZM: 'Zambia',
//   ZW: 'Zimbabwe',
// }
//
// // Versucht, das Kürzel aufzulösen.
// // Wird kein entsprechender langer Name gefunden wird das Kürzel wieder zurückgegeben.
// export function alpha2ToFullEnglish(alpha: string): string {
//   const uppercased = alpha.toUpperCase();
//   return mappingAlpha2En[uppercased] || alpha;
// }

/*
// Based on https://de.wikipedia.org/wiki/ISO-3166-1-Kodierliste, manually adjusted.
let output = '';
const table = document.querySelector('#mw-content-text > div.mw-content-ltr.mw-parser-output > table.wikitable.sortable.tabelle-kopf-fixiert.jquery-tablesorter > tbody')
for (const child of table.children) {
    if (child.id.length === 1 || child.children[0].hasAttribute('colspan')) {continue}
    const long = child.children[0].innerText.trim();
    const alpha2 = child.children[1].innerText.replaceAll(/[0-9\[\]]/g, '');
    const entry = (`${alpha2}: '${long}',\n`);
    output += entry;
}

console.log(output);
 */
const mappingAlpha2De = {
  AF: 'Afghanistan',
  EG: 'Ägypten',
  AX: 'Åland',
  AL: 'Albanien',
  DZ: 'Algerien',
  AS: 'Amerikanisch-Samoa',
  VI: 'Amerikanische Jungferninseln',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarktis',
  AG: 'Antigua und Barbuda',
  GQ: 'Äquatorialguinea',
  AR: 'Argentinien',
  AM: 'Armenien',
  AW: 'Aruba',
  AC: 'Ascension',
  AZ: 'Aserbaidschan',
  ET: 'Äthiopien',
  AU: 'Australien',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesch',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgien',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivien',
  BQ: 'Bonaire, Saba, Sint Eustatius',
  BA: 'Bosnien und Herzegowina',
  BW: 'Botswana',
  BV: 'Bouvetinsel',
  BR: 'Brasilien',
  VG: 'Britische Jungferninseln',
  IO: 'Britisches Territorium im Indischen Ozean',
  BN: 'Brunei',
  BG: 'Bulgarien',
  BF: 'Burkina Faso',
  BU: 'Burma (jetzt Myanmar)',
  BI: 'Burundi',
  EA: 'Ceuta, Melilla',
  CL: 'Chile',
  CN: 'Volksrepublik China',
  CP: 'Clipperton',
  CK: 'Cookinseln',
  CR: 'Costa Rica',
  CW: 'Curaçao',
  DK: 'Dänemark',
  DD: 'Deutsche Demokratische Republik (historisch)',
  DE: 'Deutschland',
  DG: 'Diego Garcia (reserviert für ITU)',
  DM: 'Dominica',
  DO: 'Dominikanische Republik',
  DJ: 'Dschibuti',
  EC: 'Ecuador',
  CI: 'Elfenbeinküste (Côte d’Ivoire)',
  SV: 'El Salvador',
  ER: 'Eritrea',
  EE: 'Estland',
  SZ: 'Eswatini[4]',
  CE: 'Europäische Gemeinschaft',
  EU: 'Europäische Union',
  FK: 'Falklandinseln',
  FO: 'Färöer',
  FJ: 'Fidschi',
  FI: 'Finnland',
  FR: 'Frankreich',
  FX: 'Frankreich France métropolitaine',
  GF: 'Französisch-Guayana',
  PF: 'Französisch-Polynesien',
  TF: 'Französische Süd- und Antarktisgebiete',
  GA: 'Gabun',
  GM: 'Gambia',
  GE: 'Georgien',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GD: 'Grenada',
  GR: 'Griechenland',
  GL: 'Grönland',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey (Kanalinsel)',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard und McDonaldinseln',
  HN: 'Honduras',
  HK: 'Hongkong',
  IN: 'Indien',
  ID: 'Indonesien',
  IM: 'Isle of Man',
  IQ: 'Irak',
  IR: 'Iran',
  IE: 'Irland',
  IS: 'Island',
  IL: 'Israel',
  IT: 'Italien',
  JM: 'Jamaika',
  JP: 'Japan',
  YE: 'Jemen',
  JE: 'Jersey (Kanalinsel)',
  JO: 'Jordanien',
  YU: 'Jugoslawien (historisch)',
  KY: 'Cayman Islands (Kaimaninseln)',
  KH: 'Kambodscha',
  CM: 'Kamerun',
  CA: 'Kanada',
  IC: 'Kanarische Inseln',
  CV: 'Kap Verde (Cabo Verde)',
  KZ: 'Kasachstan',
  QA: 'Katar',
  KE: 'Kenia',
  KG: 'Kirgisistan',
  KI: 'Kiribati',
  CC: 'Kokosinseln',
  CO: 'Kolumbien',
  KM: 'Komoren',
  CD: 'Demokratische Republik Kongo',
  CG: 'Republik Kongo',
  KP: 'Nordkorea',
  KR: 'Südkorea',
  HR: 'Kroatien',
  CU: 'Kuba',
  KW: 'Kuwait',
  LA: 'Laos',
  LS: 'Lesotho',
  LV: 'Lettland',
  LB: 'Libanon',
  LR: 'Liberia',
  LY: 'Libyen',
  LI: 'Liechtenstein',
  LT: 'Litauen',
  LU: 'Luxemburg',
  MO: 'Macau',
  MG: 'Madagaskar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Malediven',
  ML: 'Mali',
  MT: 'Malta',
  MA: 'Marokko',
  MH: 'Marshallinseln',
  MQ: 'Martinique',
  MR: 'Mauretanien',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexiko',
  FM: 'Föderierte Staaten von Mikronesien',
  MD: 'Moldau',
  MC: 'Monaco',
  MN: 'Mongolei',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MZ: 'Mosambik',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NC: 'Neukaledonien',
  NZ: 'Neuseeland',
  NT: 'Neutrale Zone',
  NI: 'Nicaragua',
  NL: 'Niederlande',
  AN: 'Niederländische Antillen (historisch)',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  MP: 'Nördliche Marianen',
  MK: 'Nordmazedonien',
  NF: 'Norfolkinsel',
  NO: 'Norwegen',
  OM: 'Oman',
  AT: 'Österreich',
  TL: 'Osttimor (Timor-Leste)',
  PK: 'Pakistan',
  PS: 'Palästina',
  PW: 'Palau',
  PA: 'Panama',
  PG: 'Papua-Neuguinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippinen',
  PN: 'Pitcairninseln',
  PL: 'Polen',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  RE: 'Réunion',
  RW: 'Ruanda',
  RO: 'Rumänien',
  RU: 'Russland',
  SB: 'Salomonen',
  BL: 'Saint-Barthélemy',
  MF: 'Saint-Martin (französischer Teil)',
  ZM: 'Sambia',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'São Tomé und Príncipe',
  SA: 'Saudi-Arabien',
  SE: 'Schweden',
  CH: 'Schweiz (Confoederatio Helvetica)',
  SN: 'Senegal',
  RS: 'Serbien',
  SC: 'Seychellen',
  SL: 'Sierra Leone',
  ZW: 'Simbabwe',
  SG: 'Singapur',
  SX: 'Sint Maarten',
  SK: 'Slowakei',
  SI: 'Slowenien',
  SO: 'Somalia',
  SU: 'Sowjetunion (UdSSR, historisch)',
  ES: 'Spanien',
  LK: 'Sri Lanka',
  SH: 'St. Helena, Ascension und Tristan da Cunha',
  KN: 'St. Kitts und Nevis',
  LC: 'St. Lucia',
  PM: 'Saint-Pierre und Miquelon',
  VC: 'St. Vincent und die Grenadinen',
  ZA: 'Südafrika',
  SD: 'Sudan',
  GS: 'Südgeorgien und die Südlichen Sandwichinseln',
  SS: 'Südsudan',
  SR: 'Suriname',
  SJ: 'Spitzbergen und Jan Mayen',
  SY: 'Syrien',
  TJ: 'Tadschikistan',
  TW: 'Taiwan',
  TZ: 'Tansania',
  TH: 'Thailand',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad und Tobago',
  TA: 'Tristan da Cunha',
  TD: 'Tschad',
  CZ: 'Tschechien',
  TN: 'Tunesien',
  TR: 'Türkei',
  TM: 'Turkmenistan',
  TC: 'Turks- und Caicosinseln',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  HU: 'Ungarn',
  UM: 'United States Minor Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Usbekistan',
  VU: 'Vanuatu',
  VA: 'Vatikanstadt',
  VE: 'Venezuela',
  AE: 'Vereinigte Arabische Emirate',
  US: 'Vereinigte Staaten',
  GB: 'Großbritannien',
  UK: 'Vereinigtes Königreich',
  VN: 'Vietnam',
  WF: 'Wallis und Futuna',
  CX: 'Weihnachtsinsel',
  EH: 'Westsahara',
  ZR: 'Zaire (jetzt Demokratische Republik Kongo)',
  CF: 'Zentralafrikanische Republik',
  CY: 'Zypern',
}

// Versucht, das Kürzel aufzulösen.
// Wird kein entsprechender langer Name gefunden wird das Kürzel wieder zurückgegeben.
export function alpha2ToFullGerman(alpha: string): string {
  const uppercased = alpha.toUpperCase();
  return mappingAlpha2De[uppercased] || alpha;
}
