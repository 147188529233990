// @flow
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import type {UseApiResponseType} from "../../Helper/Response";
import type {Publisher, Publishers} from "./Publisher";
import {usePublisherDetails} from "../../Hooks/Api/usePublisherDetails";
import {ResponseStatus} from "../../Helper/Response";
import {Spinner} from "react-bootstrap";
import {Button} from "react-bootstrap";
import PostbackUrlForm from "./PublisherPostbackUrlEdit/PostbackUrlForm";

type Props = {};

export default function PublisherDetails(props: Props) {
  const {id: publisherId} = useParams();
  const apiData = usePublisherDetails(publisherId);
  const [activeTab, setActiveTab] = useState<string>('postback');

  switch (apiData.status) {
    case ResponseStatus.pending:
      return <PendingIndicator/>;
    case ResponseStatus.ready:
      if (apiData.response.statusCode !== 200) {
        return null;
      }

      const renderTabContent = () => {
        switch (activeTab) {
          case 'postback':
            return <Postback publisherId={publisherId} apiData={apiData}/>;
          default:
            return `Unkown tab ${activeTab}`;
        }
      }

      return (
        <div className='publisher-details row'>
          <OverviewBody apiData={apiData}/>
          <div className="tabs-wrapper col-md-6 col-sm-12">
            <div className="tabs">
              <Button
                className={activeTab === 'postback' ? 'active' : ''}
                onClick={() => setActiveTab('postback')}
              >
                Postback
              </Button>
            </div>
            <br />
            <div className="tab-content">
              {renderTabContent()}
            </div>
          </div>
        </div>
      );
    case ResponseStatus.error:
      return null;

    default:
      return null;
  }
}

type BodyProps = {
  apiData: UseApiResponseType<Publishers>,
}

function OverviewBody(props: BodyProps) {
  const {
    apiData,
  } = props;

  return (
    <div className="overview col-md-6 col-sm-12">
      <Overview publisher={apiData.response.parsedBody}/>
    </div>
  )
}

function Overview(publisher) {
  return (
    <table>
      <tbody>
      <tr className="row">
        <td className="col-sm-12 col-lg-3">Publisher-Name:</td>
        <td className="col-sm-12 col-lg-9">{publisher.publisher.name}</td>
      </tr>
      <tr className="row">
        <td className="col-sm-12 col-lg-3">Publisher-Id:</td>
        <td className="col-sm-12 col-lg-9">{publisher.publisher.id}</td>
      </tr>
      <tr className="row">
        <td className="col-sm-12 col-lg-3">API-Key:</td>
        <td className="col-sm-12 col-lg-9">{publisher.publisher.apiKey}</td>
      </tr>
      <tr className="row">
        <td className="col-sm-12 col-lg-3">Permissions:</td>
        <td className="col-sm-12 col-lg-9">
          <div>
            {publisher.publisher.permissions.map(permission => (
              <li key={permission}>
                {permission}
              </li>
            ))}
          </div>
        </td>
      </tr>
      <tr className="row">
        <td className="col-sm-12 col-lg-3">Priorities:</td>
        <td className="col-sm-12 col-lg-9">
                        <pre>
                            {JSON.stringify(publisher.publisher.partnerPriority, null, 2)}
                        </pre>
        </td>
      </tr>
      </tbody>
    </table>
  );
}

function Postback(props: Props) {
  return <PostbackUrlForm publisherId={props.publisherId} parsedBody={props.apiData.response.parsedBody}/>;
}

function PendingIndicator() {
  return (
    <Spinner size={'sm'} animation={'grow'}/>
  );
}
