// @flow

import {createContext, useContext} from "react";

export type AppEnvType = 'testing' | 'production';

export type AppEnvContextType = {
  value: AppEnvType,
  _realValue: AppEnvType,
  override: (newValue: AppEnvType) => void,
}

export const AppEnv = {
  testing: 'testing',
  production: 'production',
}

export const AppEnvContext = createContext(null);

export function useAppEnv(): AppEnvContextType {
  return useContext(AppEnvContext);
}
