// @flow
import {Table} from "react-bootstrap";
import type {Account} from "../../../Hooks/Api/useAccountList";
import {useTheme} from "../../../Context/Theme";
import {AiFillEdit} from "react-icons/ai";
import type {SortingType} from "../../../Helper/Sorting";
import HeaderCell from "../../../GenericComponents/SortableTableHeaderCell";

type Props = {
  children: React.ReactNode,
  accountMap: Map<Account>,
  sorting: SortingType,
  setSorting: (newSorting: SortingType) => void,
};

export default function YieldAdsMerchantListTable(props: Props) {
  const {
    children,
    sorting,
    setSorting,
  } = props;

  const {theme} = useTheme();

  return (
    <Table variant={theme.type} hover>
      <thead>
      <tr>
        <th>&nbsp;</th>

        <HeaderCell
          iconPosition={'r'}
          value={'domain'}
          sorting={sorting}
          setSorting={setSorting}
        >Domain</HeaderCell>

        <HeaderCell
          iconPosition={'r'}
          value={'market'}
          sorting={sorting}
          setSorting={setSorting}
        >Market</HeaderCell>

        <HeaderCell
          iconPosition={'l'}
          value={'priority'}
          sorting={sorting}
          setSorting={setSorting}
          className={'text-end text-nowrap'}
        ><small>(<AiFillEdit/>)</small> Priority</HeaderCell>

        <HeaderCell
          iconPosition={'r'}
          value={'yieldadsStatus'}
          sorting={sorting}
          setSorting={setSorting}
          className={'text-center'}
        >Authority Status <small>(<AiFillEdit/>)</small></HeaderCell>

        <HeaderCell
          iconPosition={'r'}
          value={'viewTrafficStatus'}
          sorting={sorting}
          setSorting={setSorting}
          className={'text-center'}
          title={'View Traffic Status'}
        >VT Status <small>(<AiFillEdit/>)</small></HeaderCell>

        <HeaderCell
          iconPosition={'r'}
          value={'whitelistStatus'}
          sorting={sorting}
          setSorting={setSorting}
          className={'text-center'}
          title={'Whitelist Status'}
        >Whitelist Status</HeaderCell>

      </tr>
      </thead>
      <tbody>
      {children}
      </tbody>
    </Table>
  );
}
