// @flow
import {useEffect, useState} from "react";
import type {ThemeContextType, ThemeType} from "../Theme";
import {ThemeContext} from "../Theme";
import {readTheme, storeTheme} from "../../Helper/localStorageHelper";

type Props = {
  children: React.ReactNode,
};

export default function ThemeContextWrapper(props: Props) {
  const [theme, setTheme] = useState<ThemeType>(readTheme());
  const themeContextValue: ThemeContextType = {
    theme: theme,
    setTheme: (newTheme: ThemeType) => {
      storeTheme(newTheme);
      setTheme(newTheme);
    },
  };

  useEffect(() => {
    // TODO: Kann man das Fallback-Theme hier noch mit einbringen, damit man fehlende Werte klar sieht?
    //       Muss nachrangig zum aktiven Theme sein. Wenn man das einfach in die Class-List hinzufügt
    //       hat es prio, egal in welcher Reihenfolge.
    // Damit Komponenten, die nicht gewrappt werden (z.B. Modal Dialog), ebenfalls die Theme Variablen haben.
    document.body.className = `theme-${theme.name}`;
  }, [theme.name]);

  return (
    <ThemeContext.Provider value={themeContextValue}>
      <div className={'theme-fallback'}>
        <div id={'app'} className={`theme-${theme.name}`}>
          {props.children}
        </div>
      </div>

    </ThemeContext.Provider>
  );
}
