// @flow
type Props = {
  children: string,
  className?: string,
};

/**
 * Like an <hr/> but with text
 */
export default function Separator(props: Props) {
  const {
    children = '',
    className = '',
  } = props;

  return (
    <div className={`separator ${className}`}>
      {children}
    </div>
  );
}
