// @flow

import {Col} from "react-bootstrap";
import SelectSearch from "react-select-search";
import {useApiGetState} from "../../../../Hooks/Api/useApi";
import {renderSpinner} from "../MerchantBlacklistPending";
import {useState} from "react";

type Props = {
  selectedMarket: string,
  setSelectedMarket: string,
  selectedMerchantId: string,
  selectedAccountId: string,
};

export default function MarketFilter(props: Props) {
  const {
    selectedMarket,
    setSelectedMarket,
    selectedMerchantId,
    selectedAccountId,
  } = props;

  const [marketFilterIsLoading, setMarketFilterIsLoading] = useState<string>(true);
  const [markets, setMarkets] = useState<Array<Object>>([]);

  useApiGetState('/market', {yieldadsMerchantId: selectedMerchantId, identifier: selectedAccountId}, setMarkets, () => {
    setMarkets([]);
  },() => {
    setMarketFilterIsLoading(false);
  }, () => {
    setMarketFilterIsLoading(true);
  });

  if (markets[0] && markets[0].value !== 'all') {
    markets.unshift({'name': 'alle Markets', 'value': 'all'});
  }

  return (
    <Col className={'mb-4'}>
      <SelectSearch
        style={{display:"inline-block"}}
        name="marketSelectSearch"
        placeholder="Market/alle"
        search={true}
        options={markets}
        onChange={market => setSelectedMarket(market)}
        defaultValue={'all'}
        value={selectedMarket}
      />
      {renderSpinner(marketFilterIsLoading)}
    </Col>
  );
}
