// @flow

export type ApiResponse<T> = {
  parsedBody: T,
  statusCode: number,
}

export type ApiResponseStatus = 'inactive' | 'pending' | 'ready' | 'error';
export type UseApiResponseType<T> = {
  response: ?ApiResponse<T>,
  status: ApiResponseStatus,
  message: ?string,
  refresh: () => void,
}

export const ResponseStatus = {
  pending: 'pending',
  ready: 'ready',
  error: 'error',
}

export const defaultApiData: UseApiResponseType<null> = {
  response: null,
  status: 'pending',
  message: null,
}

export const defaultApiDataInactive: UseApiResponseType<null> = {
  response: null,
  status: 'inactive',
  message: null,
}

export function createResponse<T>(response: ?T, status: ApiResponseStatus, message: ?string): UseApiResponseType<T> {
  return {
    response: response,
    status: status,
    message: message,
  }
}
