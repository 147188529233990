// @flow

import {useApiGet} from "./useApi";
import {ResponseStatus, UseApiResponseType} from "../../Helper/Response";
import {useMemo} from "react";
import type {OnApiErrorCallback} from "../../Helper/Api/fetchApi";

export type Project = {
  projectId: string,
  name: string,
  redirectUrl: string,
  hasSearchEngine: boolean,
  accounts: Array<ReducedFrontendAccount>,
};

export type ReducedFrontendAccount = {
  identifier: string,
  name: string,
};

export type ProjectList = Array<Project>;

export function useProjectList(onError: ?OnApiErrorCallback = null): UseApiResponseType<ProjectList> {
  return useApiGet<ProjectList>('/projects', null, onError);
}

export function useProjectIdMapping(onError: ?OnApiErrorCallback = null): Map<Project> {
  const projectList = useProjectList(onError);

  return useMemo(() => {
    if (
      projectList.status === ResponseStatus.ready &&
      projectList.response.statusCode === 200
    ) {
      return buildProjectIdMap(projectList.response.parsedBody);
    }

    return new Map();
  }, [projectList]);
}

function buildProjectIdMap(projects: ProjectList): Map<Project> {
  const map = new Map();

  for (const project of projects) {
    map.set(project.projectId, project);
  }

  return map;
}
