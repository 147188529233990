// @flow

export function dateStringToLocale(input: string, locale: ?string = null): string {
  const date = new Date(input);

  // Wenn das Datum ungültig ist, keine Formatierung vornehmen
  if (isNaN(date)) {
    return input;
  }

  return date.toLocaleString(locale || navigator.language || 'de-DE');
}
