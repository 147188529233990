// @flow

import {Button} from "react-bootstrap";
import {BsFillTrashFill} from "react-icons/bs";

type Props = {
  merchantId: string,
  merchantName: string,
  yieldadsMerchantId: string,
  market: string,
  accountName: string,
  deleteButtonDisabled: string|false,
};

export default function MerchantBlacklistListRow(props: Props) {
  const {
    merchantId,
    merchantName,
    yieldadsMerchantId,
    market,
    accountName,
    deleteCallback,
    deleteButtonDisabled,
    deleteIsLoading
  } = props;

  let disabled = false;

  if (deleteButtonDisabled === merchantId) {
    disabled = true;
  }

  let variant = 'danger';
  if (deleteIsLoading === merchantId) {
    variant = 'warning';
  }

  return (
    <tr>
      <td>{merchantName}</td>
      <td>{market.replace('*', 'alle')}</td>
      <td>{accountName}</td>
      <td>{yieldadsMerchantId}</td>
      <td className={'text-end'}>
        <Button
          variant={variant}
          size={'sm'}
          onClick={() => deleteCallback(merchantId, merchantName)}
          disabled={disabled}
        ><BsFillTrashFill /></Button>
      </td>
    </tr>
  );
}
