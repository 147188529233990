// @flow

import {createContext, useContext, useEffect} from "react";

export type TabTitleContextType = {
  value: string,
  setTitle: Function,
}

export const defaultTitle = 'Yieldads';

export const TabTitleContext = createContext({
  value: defaultTitle,
  setTitle: () => {},
});

export function useTabTitle(newTitle: string = null): TabTitleContextType {
  const tabTitle = useContext(TabTitleContext);

  useEffect(() => {
    if (newTitle !== null) {
      tabTitle.setTitle(newTitle);
    }
    // Keine Dependencies, damit der Title jedes Mal aktualisiert wird.
    // Das ist notwendig, wenn es mehrere Komponenten gibt die diesen Hook nutzen.
    // Das eigentliche "Caching" passiert dann im TabTitleContextWrapper
  });

  // TODO: Eventuell den newTitle einsetzen, damit die Komponente schon mit dem neuen Titel arbeiten kann?
  //       Andererseits entspräche das nicht der Wahrheit, da der Titel noch nicht wirklich gesetzt ist.
  //       Wäre dann eher ein Versprechen.

  return tabTitle;
}
