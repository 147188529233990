// @flow
import {ApiKeyContext, ApiKeyContextType} from "../../Context/ApiKey";
import {useEffect, useRef, useState} from "react";
import {verifyApiKey} from "../../Helper/Api/apiKey";
import {Button, Card, Col, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import ErrorBox from "../../GenericComponents/ErrorBox";
import InitialKeyCheck from "./InitialKeyCheck";
import {clearApiKey, readApiKey, writeApiKey} from "../../Helper/localStorageHelper";
import {ResponseStatus} from "../../Helper/Response";

type Props = {};

type ApiKeyStatus = 'pending' | 'error' | 'valid';

export default function AuthenticationGuard(props: Props) {
  const inputRef = useRef(null);

  const [apiKey, setApiKey]             = useState<?string>(() => readApiKey());
  const [apiKeyState, setApiKeyState]   = useState<?ApiKeyStatus>(null);

  const [initialValid, setInitialValid] = useState<?boolean>(null);
  const [error, setError]               = useState<?string>(null);

  // Workaround, damit ein Clear/Logout sauber funktioniert ohne Neuladen der Seite
  const [clearCount, setClearCount] = useState<number>(0);

  const [isYeeting, setIsYeeting] = useState<boolean>(false);

  const testKey = async (): Promise<boolean> => {
    setApiKeyState('pending');
    const response = await verifyApiKey(new AbortController(), apiKey);

    if (response.status === ResponseStatus.error) {
      setInitialValid(false);
      setError(response.message);
      setApiKeyState('error');
      return false;
    }

    if (response.response?.statusCode !== 200) {
      setInitialValid(false);
      setError('The given API-Key is not valid');
      setApiKeyState('error');
      return false;
    }

    setInitialValid(true);
    setError(null);
    setApiKeyState('valid');
    return true;
  };

  // Validate initial
  useEffect(() => {
    if (apiKey === null) {
      setInitialValid(false);
      return;
    }

    (testKey)();
    // Der will hier noch apiKey und testKey haben. Die sollen aber nicht mit rein,
    // da dieser Hook nur für den initialen, einmaligen Test gedacht ist.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCount]);

  useEffect(() => {
    // Initialer Test wird anders gehandhabt
    if (initialValid === null) {
      return;
    }

    testKey().then((valid: boolean) => {
      if (valid) {
        writeApiKey(apiKey);
      }
    });
    // Der will hier noch initialValid and testKey haben. Die sollen aber nicht mit rein,
    // da dieser Hook unabhängig vom initialen, einmaligen Test gedacht ist.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey]);

  const setApiKeyFromInput = (event) => {
    event.preventDefault();
    const value = inputRef.current.value;
    if (value === '') {
      setApiKeyState('error');
      setError('Missing input value');
      return;
    }

    if (value.toUpperCase() === 'XYZZY') {
      setApiKeyState('error');
      setError('Das funktioniert wohl nicht überall');
      return;
    }

    if (value.toLowerCase() === 'yeet') {
      setIsYeeting(true);
      setTimeout(() => setIsYeeting(false), 3500);

      return;
    }

    setApiKey(value);
  };

  if (apiKey !== null && initialValid === null) {
    return <InitialKeyCheck/>;
  }

  if (apiKeyState === 'valid') {
    const apiKeyContextValue: ApiKeyContextType = {
      value: apiKey,
      clear: () => {
        clearApiKey();
        setApiKey(null);
        setApiKeyState(null);
        setInitialValid(null);
        setError(null);
        setClearCount((old) => old + 1);
      },
    };

    return (
      <ApiKeyContext.Provider value={apiKeyContextValue}>
        {props.children}
      </ApiKeyContext.Provider>
    );
  }

  return (
    <Col className={`p-4`} style={{maxHeight: '100vh', overflowY: 'scroll'}}>
      <Card id={isYeeting ? 'yeet' : undefined}>
        <Card.Header>
          <Card.Title>Authentication Required</Card.Title>
        </Card.Header>
        <Card.Body>
          {error !== null && (
            <Row>
              <Col className="mx-3 mt-3">
                <ErrorBox className={'mb-0'} onClose={() => setError(null)}>
                  {error}
                </ErrorBox>
              </Col>
            </Row>
          )}

          <Form onSubmit={setApiKeyFromInput}>
            <Row>
              <Col className="m-3">
                <InputGroup>
                  <InputGroup.Text id="input-api-key">
                    API-Key
                  </InputGroup.Text>

                  <Form.Control
                    ref={inputRef}
                    placeholder="• • • • • • • •"
                    aria-label="API-Key"
                    aria-describedby="input-api-key"
                    type="password"
                  />

                  {apiKeyState === 'pending' && (
                    <InputGroup.Text>
                      <Spinner size={'sm'} animation={'border'}/>
                    </InputGroup.Text>
                  )}

                  <Button size={'xl'} variant={'primary'} onClick={setApiKeyFromInput}>Authenticate</Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Col>
  );
}
