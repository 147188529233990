// @flow
import {Button} from "react-bootstrap";
import {useApiKey} from "../Context/ApiKey";

type Props = {};

// Diese Komponente wird nicht wirklich gebraucht, hilft aber beim Testen der Auth-Page
export default function Logout(props: Props) {
  const apiKey = useApiKey();

  const logout = () => {
    apiKey.clear();
    // Das Leeren des Key bringt die Auth-Page wieder zum Vorschein
    // window.location.reload();
  };

  return (
    <div className={'_navigation-logout'}>
      <Button
        variant={'outline-danger'}
        size={'sm'}
        onClick={logout}
      >
        Abmelden
      </Button>
    </div>
  );
}
