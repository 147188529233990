// @flow

import type {AllowedAccounts} from "../../../Hooks/Api/usePublisherAllowedAccountsList";
import MarketRow from "./MarketRow";
import Separator from "../../../GenericComponents/Separator";
import React from "react";
import {alpha2ToFullGerman} from "../../../Helper/Market";

type Props = {
  accountsData: AllowedAccounts,
  onAllowedChange: (partner: string, market: string, identifier: string | Array<string>, allowed: boolean) => void,
};

export default function AccountsEdit(props: Props) {
  const {
    accountsData,
    onAllowedChange,
  } = props;

  const marketsWithContent = Object.keys(accountsData)
    .filter((market) =>
      Object.keys(accountsData[market]).some((partner) => accountsData[market][partner].length > 0));

  return marketsWithContent.map((market) => (
    <React.Fragment key={market}>
      <Separator className={'my-2'}>{market.toUpperCase()} — {alpha2ToFullGerman(market)}</Separator>
      <MarketRow
        key={market}
        market={market}
        accountsData={accountsData}
        onAllowedChange={onAllowedChange}
      />
    </React.Fragment>
  ));
}
