// @flow
import GenericViewTrafficStatus from "../../Merchants/List/GenericViewTrafficStatus";
import {
  updateYieldAdsMerchantViewTrafficStatus
} from "../../../Helper/Api/Merchants/updateYieldAdsMerchantViewTrafficStatus";

type Props = {
  initialStatus: boolean,
  updatedDate: string,
  domain: string,
  yieldAdsMerchantId: string,
};

export default function YieldAdsMerchantViewTrafficStatus(props: Props) {
  const {
    initialStatus,
    updatedDate,
    domain,
    yieldAdsMerchantId,
  } = props;

  return (
    <GenericViewTrafficStatus
      initialStatus={initialStatus}
      updatedDate={updatedDate}
      domain={domain}
      merchantId={yieldAdsMerchantId}
      updateApi={updateYieldAdsMerchantViewTrafficStatus}
    />
  );
}
