// @flow
import YieldAdsMerchantListRow from "./YieldAdsMerchantListRow";
import type {Account} from "../../../Hooks/Api/useAccountList";
import type {YieldAdsMerchant, YieldAdsMerchantList} from "../../../Hooks/Api/useYieldAdsMerchantList";
import {useState} from "react";
import NestedPartnerMerchantsTable from "./NestedPartnerMerchantsTable";

type Props = {
  merchantList: YieldAdsMerchantList,
  accountMap: Map<Account>,
};

export default function LoadedYieldAdsMerchantsListRows(props: Props) {
  const {
    merchantList,
    accountMap,
  } = props;

  const [expandedMerchantId, setExpandedMerchantId] = useState<?number>(null);
  const [expandedPriorityReload, setExpandedPriorityReload] = useState<?number>(null);

  let rows = [];
  let index = 0;
  for (const merchant: YieldAdsMerchant of merchantList.merchants) {
    const {yieldadsMerchantId} = merchant;
    index++;

    const stripeClass = index % 2 === 0 ? 'striped-even' : 'striped-odd';
    const isExpanded = expandedMerchantId !== null && yieldadsMerchantId === expandedMerchantId;

    rows.push(
      <YieldAdsMerchantListRow
        key={yieldadsMerchantId}
        merchant={merchant}
        stripeClass={stripeClass}
        expanded={isExpanded}
        expand={setExpandedMerchantId}
        onPriorityChange={(newPriority) => {
          if (isExpanded) {
            setExpandedPriorityReload(newPriority);
          }
        }}
      />
    );

    if (isExpanded) {
      rows.push(
        <tr key={yieldadsMerchantId + '_expanded_' + expandedPriorityReload} className={stripeClass + ' fade-in-fast'}>
          <td colSpan={7}>
            <div className={'ms-5 me-2 mt-1 mb-4'} style={{boxShadow: '0 0 5px 2px rgb(0 0 0 / 0.1)'}}>
              <NestedPartnerMerchantsTable accountMap={accountMap} yieldadsMerchantId={yieldadsMerchantId}/>
            </div>
          </td>
        </tr>
      );
    }
  }

  return rows;
}
