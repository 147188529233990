// @flow
import {useState} from "react";
import type {Errors} from "../../Routes/Publisher/PublisherPostbackUrlEdit/PostbackFormTypes";
import type {FormData} from "../../Routes/Publisher/PublisherPostbackUrlEdit/PostbackFormTypes"

export const useFormData = () => {
  const [formData, setFormData] = useState<FormData>({
    BASE_URL: '',
    EVENT_ID: '',
    ADVERTISER_ID: '',
    COMMISSION_ID: '',
    COMMISSION: '',
    MODIFIED_DATE: '',
    SUB_ID: '',
    SUB_ID2: '',
    EVENT_TYPE: '',
    STATE: '',
    CLICK_DATE: '',
    CURRENCY: '',
    MARKET: '',
    MERCHANT_NAME: '',
  });

  const [errors, setErrors] = useState<Errors>({});

  const handleChange = (name: string, value: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateField = (name: string, value: string) => {
    let error = null;

    switch (name) {
      case 'BASE_URL':
        if (!value) {
          error = 'Base URL darf nicht leer bleiben';
        }
        break;
      default:
        break;
    }

    if (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error,
      }));
    }

    return error === null;
  };

  const validateForm = () => {
    let formValid = true;
    setErrors({})

    Object.entries(formData).forEach(([key, value]) => {
      formValid = validateField(key, value) && formValid;
    });

    return formValid;
  };

  return [formData, setFormData, handleChange, validateForm, errors];
};
