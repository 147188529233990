// @flow
import {Col, Form} from "react-bootstrap";
import ViewTrafficStatusOptions from "./ViewTrafficStatusOptions";

type Props = {
  value: ?number,
  onChange: Function,
};

export default function ViewTrafficStatusSelect(props: Props) {
  const {
    value,
    onChange,
  } = props;

  return (
    <Col md={"auto"} className={'mb-2'}>
      <Form.Select
        name={'viewTrafficStatus'}
        size={'sm'}
        value={value || 'any'}
        onChange={(event) => onChange(event.target.value)}
      >
        <ViewTrafficStatusOptions/>
      </Form.Select>
    </Col>
  );
}
