// @flow

import {Button, Col} from "react-bootstrap";
import {fetchApiPost} from "../../../Helper/Api/fetchApi";
import {usePageNotificationSystem} from "../../../Context/PageNotification";
import {useApiKey} from "../../../Context/ApiKey";
import NotificationToast from "../../../GenericComponents/NotificationToast";
import {useRef, useState} from "react";

type Props = {
  refresh: Function,
};

export default function UploadCsvButton(props: Props) {
  const {
    refresh,
  } = props;

  const [file, setFile] = useState<null|File>(null);
  const uploadRef = useRef();
  const {pushNotification} = usePageNotificationSystem();
  const apiKey = useApiKey().value;

  async function onUpload() {
    pushNotification(
      <NotificationToast
        id={'whitelist-csv-upload-summary'}
        title={'Csv upload...'}
      >
        <div>
          Uploading csv...
        </div>
      </NotificationToast>
    );

    uploadRef.current.value = null;
    setFile(null);

    const text = await new Promise((resolve) => {
      const file2 = file;
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result)
      reader.readAsText(file2);
    })

    let response;
    try {
      response = await fetchApiPost('/upload-csv-whitelist', apiKey, {
        csv: text,
      }, null, null, pushNotification, null);
    } catch {
      pushNotification(
        <NotificationToast
          id={'whitelist-csv-upload-summary'}
          title={'Csv upload fehlgeschlagen'}
        >
          <div>
            Upload fehlgeschlagen, schaue in der konsole nach um mehr zu erfahren.
            Falls es einen Timeout gab, werden alle Domains im Hintergrund noch gewhitelisted
          </div>
        </NotificationToast>
      );
    }

    pushNotification(
      <NotificationToast
        id={'whitelist-csv-upload-summary'}
        title={'Csv upload komplett'}
      >
        <>
          <div>
            {response.parsedBody.success} von {response.parsedBody.total} domains whitelisted.
          </div>
          {response.parsedBody.failed.length > 0 ?
            <div>
              Fehlgeschlagen:
              <ul>
                {response.parsedBody.failed.map((failedDomain) => <li key={failedDomain}>{failedDomain}</li>)}
              </ul>
            </div>
          : null}
        </>
      </NotificationToast>
    );
    refresh();
  }

  return (
    <Col className={'mb-4'}>
      <input
        className="form-control-sm"
        type="file"
        id="csvFileInput"
        accept=".csv"
        ref={uploadRef}
        onChange={(evt) => setFile(evt.currentTarget.files[0] || null)}
      />
      <Button disabled={!file} onClick={onUpload} size={"sm"}>Upload CSV</Button>
    </Col>
  );
}
