// @flow

import {Button, Col} from "react-bootstrap";
import {fetchApiPost} from "../../../Helper/Api/fetchApi";
import {usePageNotificationSystem} from "../../../Context/PageNotification";
import {useApiKey} from "../../../Context/ApiKey";

type Props = {
  selectedMerchantId: string,
  selectedMarket: string,
  selectedAccountId: string,
  selectedPublisherId: string,
  setReloadMerchantList: Function,
  addError: Function,
  removeError: Function,
};

export default function AddToBlacklistButton(props: Props) {
  const {
    selectedMerchantId,
    selectedMarket,
    selectedAccountId,
    selectedPublisherId,
    setReloadMerchantList,
    addError,
    removeError,
  } = props;

  const {pushNotification} = usePageNotificationSystem();
  const apiKey = useApiKey().value;

  async function addToBlacklist() {
    let skipRequest = false;

    if (selectedMerchantId === 'all') {
      addError('Merchant', 'Bitte wählen Sie einen Merchant aus');
      skipRequest = true;
    } else {
      removeError('Merchant');
    }

    if (!skipRequest) {
      const response = await fetchApiPost('/merchant-blacklist', apiKey, {
        yieldadsMerchantId: selectedMerchantId,
        market: selectedMarket,
        identifier: selectedAccountId,
        publisherId: selectedPublisherId,
      }, null, new AbortController(), pushNotification, null);

      if (response.parsedBody.errors && Array.isArray(response.parsedBody.errors)) {
        // Error Handling, wenn Fehler in die Box soll, oder besonders behandelt
      }
    }

    setReloadMerchantList((old: number) => old + 1);
  }

  return (
    <Col className={'mb-4'}>
      <Button onClick={addToBlacklist} size={"lg"}>hinzufügen</Button>
    </Col>
  );
}
