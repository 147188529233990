// @flow
import type {Project, ReducedFrontendAccount} from "../../../Hooks/Api/useProjectList";
import {TiShoppingCart} from "react-icons/ti";
import {Link} from "react-router-dom";
import './AccountsListStyle.css';

type Props = {
  project: Project,
};

export default function ProjectsListRow(props: Props) {
  const {project} = props;
  const {projectId, name, redirectUrl, accounts} = project;

  return (
    <tr>
      <td>{name}</td>
      <td><code>{projectId}</code></td>
      <td><Accounts accounts={accounts}/></td>
      <td className={'scroll-thin project-inline-url'}><code>{redirectUrl}</code></td>
      <td>
        &nbsp;
      </td>
    </tr>
  );
}

type AccountsProps = {
  accounts: Array<ReducedFrontendAccount>,
};

function Accounts(props: AccountsProps) {
  let accounts = props.accounts;

  return (
    <ul className={'projects-accounts-list'}>
      {accounts.map((account) => (
        <li key={account.identifier} className={'projects-accounts-list'}>
          <span className={'account-name'}>{account.name}</span>
          <br/>
          <span className={'account-identifier'}>(<code>{account.identifier}</code>)</span>
          <Link to={`/partner-merchants/list?account=${account.identifier}`}>
            <TiShoppingCart className={'color-purple'} title={'Zeige alle Merchants zu diesem Account'}/>
          </Link>
        </li>
      ))}
    </ul>
  );
}
