// @flow
import {Spinner} from "react-bootstrap";
import MerchantListTable from "../../Merchants/List/MerchantListTable";
import LoadedMerchantsListRows from "../../Merchants/List/LoadedMerchantsListRows";
import type {Account} from "../../../Hooks/Api/useAccountList";
import {useMerchantList} from "../../../Hooks/Api/useMerchantList";
import {useMemo, useState} from "react";
import {ResponseStatus} from "../../../Helper/Response";
import sortMerchantList from "../../../Helper/MerchantHelper";
import type {SortingType} from "../../../Helper/Sorting";

type Props = {
  accountMap: Map<Account>,
  yieldadsMerchantId: string,
};

export default function NestedPartnerMerchantsTable(props: Props) {
  const {
    accountMap,
    yieldadsMerchantId,
  } = props;

  const [sorting, setSorting] = useState<SortingType>(() => ({
    field: null,
    direction: 'asc',
  }: SortingType));

  const merchantList = useMerchantList(
    1,
    1000,
    'identifier',
    'asc',
    null,
    null,
    null,
    null,
    yieldadsMerchantId,
    null,
  );

  const rows = useMemo(() => {
    switch (merchantList.status) {
      case ResponseStatus.pending:
        return (
          <tr className={'striped-odd-secondary'}>
            <td rowSpan={2} colSpan={3} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
            <td rowSpan={2} colSpan={3} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
            <td rowSpan={2} colSpan={2} className={'text-center'}><Spinner size={'sm'} animation={'grow'}/></td>
          </tr>
        );
      case ResponseStatus.ready:
        if (merchantList.response.statusCode !== 200) {
          return null;
        }

        return <LoadedMerchantsListRows
          merchantList={sortMerchantList(merchantList.response.parsedBody, sorting)}
          accountMap={accountMap}
          stripingClasses={['striped-odd-secondary', 'striped-even-secondary']}
        />
      default:
        return null;
    }
  }, [merchantList, accountMap, sorting]);

  return (
    <MerchantListTable
      sorting={sorting}
      setSorting={setSorting}
      striped={false}
      hover={false}
      trClassName={'striped-even-secondary'}
    >
      {rows}
    </MerchantListTable>
  );
}
