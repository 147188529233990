// @flow
import MarketAbbreviation from "../../GenericComponents/MarketAbbreviation";
import ModalTableContent from "./ModalTableContent";
import type {AllowedAccounts} from "../../Hooks/Api/usePublisherAllowedAccountsList";

type Props = {
  accountsData: AllowedAccounts,
  partners: Array<string>
};

export default function MarketRow(props: Props) {
    const {
      accountsData,
      partners
    } = props;

    const filteredMarkets = Object.keys(accountsData)
      .filter(market =>
        partners.some(partner =>
          accountsData[market][partner] &&
          accountsData[market][partner].some(publisher => publisher.allowed)
        )
      );

    return filteredMarkets.map(market => (
      <tr key={market}>
        <td>
            <MarketAbbreviation market={market.toUpperCase()}/>
        </td>
        {partners.map(partner => (
          <td key={partner}>
            <ModalTableContent accountsData={accountsData} market={market} partner={partner}/>
          </td>
        ))}
      </tr>
    ));
}
