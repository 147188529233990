// @flow
import {Toast} from "react-bootstrap";
import {Variant} from "react-bootstrap/types";
import {usePageNotificationSystem} from "../Context/PageNotification";
import {FaRegWindowMaximize, FaRegWindowMinimize} from "react-icons/fa";
import {useCallback, useEffect, useState} from "react";

type Props = {
  id: string,
  title: string,
  children: React.ReactNode,
  variant?: Variant,
  onClose?: Function,
  duration?: ?number,
};

export default function NotificationToast(props: Props) {
  const {
    id,
    title,
    children,
    variant = null,
    onClose = null,
    duration = null,
  } = props;

  const [minimized, setMinimized] = useState<boolean>(false);

  const notificationSystem = usePageNotificationSystem();

  useEffect(() => {
    if (duration !== null) {
      const timeoutId = setTimeout(() => {
        notificationSystem.clear(id);
        onClose !== null && onClose();
      }, duration);

      return () => clearTimeout(timeoutId);
    }
  }, [duration, id, onClose, notificationSystem]);

  const minimizeHandler = useCallback(() => {
    setMinimized((old) => !old);
  }, []);

  return (
    <Toast
      bg={variant}
      onClose={() => {
        notificationSystem.clear(id);
        onClose !== null && onClose();
      }}
    >
      <Toast.Header>
        <span className={'me-auto'}>
          {title}
        </span>
        <span className={'px-2 cursor-pointer'} title={minimized ? 'Anzeigen' : 'Zusammenklappen'} onClick={minimizeHandler}>
          {minimized ? <FaRegWindowMaximize/> : <FaRegWindowMinimize/>}
        </span>
      </Toast.Header>
      {!minimized &&
        <Toast.Body>
          {children}
        </Toast.Body>
      }
    </Toast>
  );
}
