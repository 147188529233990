// @flow
import {ButtonGroup, Card, Col, Row, ToggleButton} from "react-bootstrap";
import {useProjectList} from "../../../Hooks/Api/useProjectList";
import {UseApiResponseType} from "../../../Helper/Response";
import ErrorBox from "../../../GenericComponents/ErrorBox";
import {useEffect, useState} from "react";
import ProjectsListTable from "./ProjectsListTable";
import type {ProjectList} from "../../../Hooks/Api/useProjectList";
import {useTabTitle} from "../../../Context/TabTitle";
import ProjectsListCards from "./ProjectsListCards";
import {readProjectListLayout, storeProjectListLayout} from "../../../Helper/localStorageHelper";
import type {ApiErrors} from "../../../Helper/Api/fetchApi";

type ProjectsLayoutType = 'table' | 'cards';

type Props = {};
type ProjectsLayoutProps = {
  layout: ProjectsLayoutType,
  setLayout: Function,
  projectList: UseApiResponseType<ProjectList>,
}

function ProjectsLayout(props: ProjectsLayoutProps) {
  const {
    layout,
    setLayout,
    projectList,
  } = props;

  useEffect(() => {
    switch (layout) {
      case 'table':
        break;
      case 'cards':
        break;
      default:
        setLayout('table');
    }
  }, [layout, setLayout]);

  switch (layout) {
    case 'table':
      return (
        <ProjectsListTable
          projectList={projectList}
        />
      );
    case 'cards':
      return (
        <ProjectsListCards
          projectList={projectList}
        />
      );
    default:
      return null;
  }
}

export default function ProjectsList(props: Props) {
  useTabTitle('Projekte');

  const [error, setError] = useState<?string>(null);
  const [layout, setLayout] = useState<ProjectsLayoutType>(readProjectListLayout('table'));

  // noinspection JSUnusedLocalSymbols
  const projectList = useProjectList((reason: string, parsedBody: ApiErrors, statusCode: ?number) => {
    setError(reason);
  });

  const updateLayout = (newLayout) => {
    storeProjectListLayout(newLayout);
    setLayout(newLayout);
  };

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <h4 className={'mb-4'}>Projekt Liste</h4>
          </Col>
          <Col className={'text-end'}>
            <ButtonGroup>
              <ToggleButton
                size={'sm'}
                variant={layout === 'table' ? 'dark' : 'outline-secondary'}
                type={'radio'}
                value={'table'}
                onClick={() => updateLayout('table')}>Tabelle</ToggleButton
              >
              <ToggleButton
                size={'sm'}
                variant={layout === 'cards' ? 'dark' : 'outline-secondary'}
                type={'radio'}
                value={'cards'}
                onClick={() => updateLayout('cards')}>Cards</ToggleButton
              >
            </ButtonGroup>
          </Col>
        </Row>

        {error !== null && (
          <ErrorBox title={'Error during API fetch'}>
            {error}
          </ErrorBox>
        )}

        <div className={'fade-in-fast-delayed-short'}>
          <ProjectsLayout
            layout={layout}
            setLayout={setLayout}
            projectList={projectList}
          />
        </div>
      </Card.Body>
    </Card>
  );
}
