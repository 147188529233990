// @flow
import NavigationLink from "./NavigationLink";
import {TiHomeOutline, TiThListOutline, TiShoppingCart, TiCancelOutline, TiCreditCard} from "react-icons/ti";
import {TbError404} from "react-icons/tb";
import {AppEnv, useAppEnv} from "../Context/AppEnvContext";
import {AiOutlineBug} from "react-icons/ai";
import {BiListPlus} from "react-icons/bi";

type Props = {};

export default function NavigationLinks(props: Props) {
  const {value: appEnv} = useAppEnv();

  return (
    <div className={'navigation-list'}>

      <NavigationLink to={'/dashboard'}      iconColor={'color-blue'} icon={TiHomeOutline}>Dashboard</NavigationLink>
      <NavigationLink to={'/accounts/list'}  iconColor={'color-teal'} icon={TiThListOutline}>Accounts</NavigationLink>
      <NavigationLink to={'/projects/list'}  iconColor={'color-teal'} icon={TiThListOutline}>Projekte</NavigationLink>
      <NavigationLink to={'/publisher/list'} iconColor={'color-teal'} icon={TiThListOutline}>Publisher</NavigationLink>
      <NavigationLink to={'/publisher/payments/list'} iconColor={'color-teal'} icon={TiCreditCard}>Publisher Payments</NavigationLink>

      <hr className={'m-1'}/>

      <NavigationLink to={'/partner-merchants/list'}  iconColor={'color-purple'} icon={TiShoppingCart}>Partner Merchants</NavigationLink>
      <NavigationLink to={'/ignored-partner-merchants/list'}  iconColor={'color-purple'} icon={TiCancelOutline}>Ignored Partner Merchants</NavigationLink>
      <NavigationLink to={'/yieldads-merchants/list'} iconColor={'color-ocher'}  icon={TiShoppingCart}>Yieldads Merchants</NavigationLink>
      <NavigationLink to={'/merchant-blacklist/list'} iconColor={'color-red'}    icon={TiCancelOutline}>Merchant Blacklist</NavigationLink>
      <NavigationLink to={'/merchant-whitelist/list'} iconColor={'color-teal'}   icon={BiListPlus}>Merchant Whitelist</NavigationLink>

      {appEnv === AppEnv.testing &&
        <>
          <hr className={'m-1'}/>

          <NavigationLink to={'/debug/404'}        iconColor={'color-brown'} icon={TbError404}>404 (Debug)</NavigationLink>
          <NavigationLink to={'/debug/components'} iconColor={'color-brown'} icon={AiOutlineBug}>Debug</NavigationLink>
        </>
      }
    </div>
  );
}
