// @flow
import './OptionSeparator.css';
import {Variant} from "react-bootstrap/types";

type Props = {
  variant?: Variant,
  size?: 'sm' | 'md' | 'lg' | 'xl' | string,
  children?: React.ReactNode,
};

export default function OptionSeparator(props: Props) {
  const {
    variant,
    size = 'md',
    children = '\xA0', // nbsp
  } = props;

  return (
    <option disabled className={`option-separator ${size} ${variant}`}>{children}</option>
  );
}
